import GTLoader from "#/components-ng/loader.js";
import { CardLayout } from "../components/index.js";
import { Text } from "@mantine/core";
import { ItemSku, Item } from "server";

interface InformationCartProps {
  isLoading: boolean;
  data: ItemSku & {
    item: Item & {
      category: {
        name: string;
      };
      department: {
        name: string;
      };
      season?: {
        name: string;
      } | null;
    };
  };
}

export const InformationCard = ({ isLoading, data }: InformationCartProps) => {
  return (
    <CardLayout title="Information">
      {isLoading ? (
        <GTLoader width={100} height={100} />
      ) : (
        <>
          <InformationCartItem
            title="SKU"
            value={data?.sku ?? ""}
            withDivider={false}
          />
          <InformationCartItem
            title="Title"
            value={data?.title ?? ""}
            withDivider={true}
          />
          <InformationCartItem
            title="Description"
            value={data?.item?.description ?? ""}
            withDivider={true}
          />
          <InformationCartItem
            title="Price"
            value={`$${data?.price?.toNumber().toFixed(2) ?? 0.0}`}
            withDivider={true}
          />
          <InformationCartItem
            title="Unit price"
            value={`$${data?.unitPrice?.toNumber().toFixed(2) ?? 0.0}`}
            withDivider={true}
          />
          <InformationCartItem
            title="Availability"
            value={data?.available ? "Available" : "Not available"}
            withDivider={true}
          />
          <InformationCartItem
            title="Size"
            value={`${data?.size ?? ""}`}
            withDivider={true}
          />
          <InformationCartItem
            title="Condition"
            value={data?.item.condition ?? ""}
            withDivider={true}
          />
          <InformationCartItem
            title="Taxable"
            value={data?.item.taxable ? "Yes" : "No"}
            withDivider={true}
          />
          <InformationCartItem
            title="Department"
            value={data?.item.department.name ?? ""}
            withDivider={true}
          />
          <InformationCartItem
            title="Category"
            value={data?.item.category.name ?? ""}
            withDivider={true}
          />
          <InformationCartItem
            title="Season"
            value={data?.item?.season?.name ?? ""}
            withDivider={true}
          />
          <InformationCartItem
            title="Presentation"
            value={`${data?.presentationValue ?? ""} ${data?.presentationType ?? "PCS"}`}
            withDivider={true}
          />
          <InformationCartItem
            title="Material"
            value={data?.item.material ?? ""}
            withDivider={true}
          />
          <InformationCartItem 
            title="Weight"
            value={`${data?.weight ?? ""} g`}
            withDivider={true}
          />
        </>
      )}
    </CardLayout>
  );
};

interface InformationCartItemProps {
  title: string;
  value: string | number;
  withDivider: boolean;
}

const InformationCartItem = ({
  title,
  value,
  withDivider,
}: InformationCartItemProps) => {
  return (
    <>
      {withDivider && <div className="w-full border-t border-[#E0E0E0]"></div>}
      <div className="flex items-start justify-between self-stretch">
        <Text className="text-sm">{title}</Text>
        <Text className="text-sm">{value}</Text>
      </div>
    </>
  );
};
