import { type RouterOutputs, trpc } from "#/trpc.js";
import { reportUserError, reportUserSuccess } from "#/util/index.js";
import { Preview } from "./Preview.js";
import { DatePickerInput } from "@mantine/dates";
import { openConfirmModal } from "@mantine/modals";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import EditIcon from "~icons/ion/create-outline";
import PreviewIcon from "~icons/ion/eye-outline";
import DeleteIcon from "~icons/ion/trash-outline";
import { Button, Dropdown } from "@gt/ui";
import { css } from "#/css/css";
import { HStack, styled } from "#/css/jsx";
import { Badge, DropdownMenu, IconButton, Tooltip } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import { SearchButton } from "#/components@v2_5/search-button.js";
import {
	MantineReactTable,
	type MRT_ColumnDef,
	type MRT_SortingState,
	useMantineReactTable,
} from "mantine-react-table";
import MenuIcon from "~icons/ion/ellipsis-horizontal-outline";
import { MdOutlineDownload } from "react-icons/md";
import { sortBy } from "lodash";
import type { ItemSkuLabel } from "#/scenes/Sales/components/print-all-labels.js";
import { Provider } from "jotai";
import { PrintLabelsModal } from "../ItemList/PrintLabelsModal.js";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";

export const InventoryTransfer = () => {
	const [browserPrint] = useState(() => new ZebraBrowserPrintWrapper());
	const [isLoadingPrinters, setIsLoadingPrinters] = useState<boolean>(false);

	useEffect(() => {
		async function setDefaultPrinter() {
			setIsLoadingPrinters(true);
			const availablePrinters = await browserPrint.getAvailablePrinters();
			browserPrint.setPrinter(availablePrinters[0]);
			setIsLoadingPrinters(false);
		}

		setDefaultPrinter();
	}, [browserPrint]);

	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().startOf("month").toDate(),
		dayjs().endOf("month").toDate(),
	]);
	const [search, setSearch] = useState<string | null>(null);

	// pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	});

	// sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([]);
	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			};
		}
		return null;
	}, [sorting]);

	const { data, isLoading } = trpc.v2_5.transferInventory.getByPage.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			search: search,
			filters: {
				dateRange: {
					start: dateRange[0]!,
					end: dateRange[1]!,
				},
			},
		},
		{
			enabled: !!dateRange[0] && !!dateRange[1],
			cacheTime: 0,
			keepPreviousData: true,
			onError(error) {
				reportUserError({
					title: "Failed to get inventory transfers",
					message: error.message,
				});
			},
		},
	);

	const { mutate: exportCsv, isLoading: isLoadingExport } =
		trpc.v2_5.transferInventory.exportTableToCsv.useMutation({
			onSuccess: () => {
				reportUserSuccess({
					title: "The csv file has been sent to email",
				});
			},
		});

	const handleExportCsv = () => {
		exportCsv({
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			search: search,
			filters: {
				dateRange: {
					start: dateRange[0]!,
					end: dateRange[1]!,
				},
			},
		});
	};

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns: columns({ browserPrint, isLoadingPrinters }),
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
		renderBottomToolbarCustomActions: () => (
			<styled.div display="flex" alignItems="center" gap="2">
				<Tooltip content="Export to CSV">
					<IconButton
						color="gray"
						variant="soft"
						loading={isLoadingExport}
						onClick={handleExportCsv}
					>
						<MdOutlineDownload />
					</IconButton>
				</Tooltip>
			</styled.div>
		),
	});

	return (
		<>
			<HStack justify="space-between" gap={3}>
				<HStack width="100%" gap={3}>
					<DrowndownButton />
					<DatePickerInput
						type="range"
						value={dateRange}
						onChange={setDateRange}
						numberOfColumns={2}
						popoverProps={{
							zIndex: 9999,
							withinPortal: true,
						}}
						clearable={false}
					/>
				</HStack>
				<SearchButton setQuery={setSearch} />
			</HStack>
			<styled.div marginTop={5}>
				<MantineReactTable table={table} />
			</styled.div>
		</>
	);
};

type TransferInventory =
	RouterOutputs["v2_5"]["transferInventory"]["getByPage"]["entries"][number];

const columns = ({
	browserPrint,
	isLoadingPrinters,
}: {
	browserPrint: ZebraBrowserPrintWrapper;
	isLoadingPrinters: boolean;
}): MRT_ColumnDef<TransferInventory>[] => [
	{
		header: "Actions",
		id: "actions",
		enableSorting: false,
		size: 100,
		Cell: (table) => {
			const original = table.row.original;
			const [enabled, setEnabled] = useState<boolean>(false);
			const [open, setOpen] = useState<boolean>(false);
			const [type, setType] = useState<"PRINT" | "PREVIEW" | "LABEL" | null>(
				null,
			);
			const [printLabels, setPrintLabels] = useState<boolean>(false);
			const [productsToPrint, setProductsToPrint] = useState<ItemSkuLabel[]>(
				[],
			);
			const [openModalToPrintLabel, setOpenModalToPrintLabel] = useState(false);

			const ctx = trpc.useContext();

			const { mutate: deleteInventoryTransfer } =
				trpc.inventoryTransfer.delete.useMutation({
					onSuccess() {
						ctx.inventoryTransfer.invalidate();
						ctx.v2_5.transferInventory.invalidate();
						reportUserSuccess({
							title: "Inventory transfer deleted",
						});
					},
				});

			trpc.inventoryTransfer.getById.useQuery(
				{
					id: original.id,
				},
				{
					enabled: !!enabled && (type === "PRINT" || type === "LABEL"),
					refetchOnWindowFocus: false,
					onError(error) {
						reportUserError({
							title: "Failed to get inventory transfer",
							message: error.message,
						});
					},
					onSuccess(data) {
						if (type === "PRINT") {
							localStorage.setItem(
								"transferInventory",
								JSON.stringify({
									guide: original.id ?? null,
									filialFrom: original.filialFrom.name,
									filialTo: original.filialTo.name,
									reason: original.reason,
									note: original.note,
									itemSkus: data.inventoryTransferItemSkus?.map((i) => ({
										title: i.itemSku.title,
										sku: i.itemSku.sku,
										quantity: i.quantity,
										previousQuantityFrom: i.previousQuantityFrom,
										previousQuantityTo: i.previousQuantityTo,
									})),
									status: original.status,
									createdBy: `${original?.user?.firstName ?? ""} ${original?.user?.lastName ?? ""}`,
								}),
							);

							window.open("/inventory/inventory-transfer/print", "_blank");
							setEnabled(false);
							setType(null);
						}

						if (type === "LABEL" && printLabels) {
							const products_ =
								data?.inventoryTransferItemSkus
									?.map((c) => ({ ...c.itemSku, qty: c.quantity }))
									?.map((item) => ({
										...item,
										printQty: 2,
										storeLocation:
											item?.itemSkuStock?.find((is) => is.filialId === 2)
												?.storeLocation ??
											item?.itemSkuStock?.[0]?.storeLocation,
									})) ?? [];

							const products = sortBy(products_, ["storeLocation"]);

							setProductsToPrint(products);
							setOpenModalToPrintLabel(true);
							setPrintLabels(false);
							setEnabled(false);
							setType(null);
						}
					},
				},
			);

			const openConfirmDeleteModal = () =>
				openConfirmModal({
					title: "Are you sure you want to delete this inventory?",
					labels: {
						confirm: "Delete",
						cancel: "Cancel",
					},
					confirmProps: { color: "red" },
					onConfirm: () =>
						deleteInventoryTransfer({
							id: original.id,
						}),
				});

			return (
				<>
					<Dropdown.Root>
						<Dropdown.Trigger>
							<Button variant="ghost" size="icon">
								<MenuIcon />
							</Button>
						</Dropdown.Trigger>
						<Dropdown.Content>
							{original.status === "DRAFT" && (
								<Dropdown.Item asChild>
									<Link to={`edit/${original.id}`}>
										<styled.div
											display="flex"
											justifyContent="center"
											alignItems="center"
											gap={2}
										>
											<EditIcon />
											Edit
										</styled.div>
									</Link>
								</Dropdown.Item>
							)}
							<Dropdown.Item onClick={openConfirmDeleteModal}>
								<styled.div
									display="flex"
									justifyContent="center"
									alignItems="center"
									gap={2}
								>
									<DeleteIcon />
									Delete
								</styled.div>
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									setOpen(true);
									setType("PREVIEW");
								}}
							>
								<styled.div
									display="flex"
									justifyContent="center"
									alignItems="center"
									gap={2}
								>
									<PreviewIcon />
									Preview
								</styled.div>
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									setEnabled(true);
									setType("PRINT");
								}}
							>
								<styled.div
									display="flex"
									justifyContent="center"
									alignItems="center"
									gap={2}
								>
									<MdOutlineDownload />
									Print Pdf
								</styled.div>
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => {
									setEnabled(true);
									setPrintLabels(true);
									setType("LABEL");
								}}
							>
								Print labels
							</Dropdown.Item>
						</Dropdown.Content>
					</Dropdown.Root>
					{open && (
						<Preview
							id={Number(original.id)}
							opened={open}
							onClose={() => setOpen(false)}
						/>
					)}
					<Provider>
						<PrintLabelsModal
							printer={browserPrint}
							isLoadingPrinters={isLoadingPrinters}
							defaultProduct={null}
							setSelectedItemSkuToPrint={() => {}}
							opened={openModalToPrintLabel}
							setOpened={setOpenModalToPrintLabel}
							defaultProducts={productsToPrint as any[]}
							setDefaultProducts={setProductsToPrint}
						/>
					</Provider>
				</>
			);
		},
	},
	{
		header: "date",
		accessorKey: "createdAt",
		size: 100,
		Cell: (table) => {
			const createdAt = table.row.original.createdAt;
			if (!createdAt) return "";
			return dayjs(createdAt).format("MM/DD/YYYY").toString();
		},
	},
	{
		header: "Reason",
		accessorKey: "reason",
	},
	{
		header: "Guide",
		accessorKey: "id",
		size: 100,
	},
	{
		header: "Products QTY",
		enableSorting: false,
		Cell: (table) => {
			return table.row.original._count.inventoryTransferItemSkus;
		},
	},
	{
		header: "From Filial Name",
		accessorKey: "filialFromId",
		enableSorting: false,
		Cell: (table) => {
			const filialFromName = table.row.original.filialFrom?.name ?? "";
			return filialFromName;
		},
	},
	{
		header: "To Filial Name",
		accessorKey: "filialToId",
		enableSorting: false,
		Cell: (table) => {
			const filialToName = table.row.original.filialTo?.name ?? "";
			return filialToName;
		},
	},
	{
		header: "Status",
		accessorKey: "status",
		enableSorting: false,
		Cell: (table) => {
			const status = table.row.original.status;

			return (
				<Badge color={status === "DRAFT" ? "orange" : "green"} radius="full">
					{status}
				</Badge>
			);
		},
	},
	{
		header: "Performed by",
		accessorKey: "userId",
		enableSorting: false,
		Cell: (table) => {
			const userFullName = `${table.row.original.user?.firstName ?? ""} ${table.row.original.user?.lastName ?? ""}`;
			return userFullName;
		},
	},
];

const DrowndownButton = () => {
	return (
		<Dropdown.Root>
			<Dropdown.Trigger>
				<Button
					className={css({
						gap: 4,
					})}
				>
					I want to
					<DropdownMenu.TriggerIcon />
				</Button>
			</Dropdown.Trigger>
			<Dropdown.Content>
				<Dropdown.Item>
					<Link to="create">Transfer inventory</Link>
				</Dropdown.Item>
			</Dropdown.Content>
		</Dropdown.Root>
	);
};
