import { atom } from "jotai";

type Product = {
  id: number;
  sku: number;
  title: string;
  cost: number;
  availQty: number;
  newQty: number;
  diffQty: number;
  department: string;
  category: string;
  storeLocation?: string | null;
  warehouseLocation?: string | null;
};

export const stateAtom = atom({
  products: [] as Product[],
});

export const productsAtom = atom(
  (get) => {
    return get(stateAtom).products;
  },
  (get, set, newProducts: Product[]) => {
    set(stateAtom, {
      ...get(stateAtom),
      products: newProducts,
    });
  },
);

export const addProductAtom = atom(null, (get, set, product: Product) => {
  const products = get(productsAtom);
  let found = false;
  const newProducts = products.map((p) => {
    if (p.id === product.id) {
      found = true;
      return {
        ...p,
        newQty: p.newQty + 1,
      };
    } else {
      return p;
    }
  });
  if (!found) {
    newProducts.push(product);
  }
  set(productsAtom, newProducts);
});

export const addProductsAtom = atom(null, (get, set, products: Product[]) => {
  const currentProducts = get(productsAtom);
  const newProducts = products
    .map((product) => {
      const found = currentProducts.find((p) => p.id === product.id);
      if (found) {
        return {
          ...found,
          newQty: found.newQty + product.newQty,
        };
      } else {
        return product;
      }
    })
    .concat(
      currentProducts.filter((product) => {
        const found = products.find((p) => p.id === product.id);
        return !found;
      }),
    );

  set(productsAtom, newProducts);
});

export const removeProductAtom = atom(null, (get, set, index: number) => {
  const products = get(productsAtom);
  set(
    productsAtom,
    products.filter((_, i) => i !== index),
  );
});

type SetProductNewQtyPayload = {
  index: number;
  qty: number;
};
export const setProductNewQtyAtom = atom(
  null,
  (get, set, payload: SetProductNewQtyPayload) => {
    const products = get(productsAtom);
    set(productsAtom, [
      ...products.slice(0, payload.index),
      {
        ...products[payload.index],
        newQty: payload.qty,
        diffQty: payload.qty - products[payload.index].availQty,
      },
      ...products.slice(payload.index + 1),
    ]);
  },
);

export const oldQtyAtom = atom((get) => {
  const products = get(productsAtom);
  return products.reduce((old, product) => old + product.availQty, 0);
});

export const newQtyAtom = atom((get) => {
  const products = get(productsAtom);
  return products.reduce((old, product) => old + product.newQty, 0);
});

export const diffQtyAtom = atom((get) => {
  const oldQty = get(oldQtyAtom);
  const newQty = get(newQtyAtom);
  return newQty - oldQty;
});

export const diffCostAtom = atom((get) => {
  const products = get(productsAtom);
  return products.reduce(
    (old, product) =>
      old + product.cost * product.newQty - product.cost * product.availQty,
    0,
  );
});

export const searchAtom = atom("");

export const filteredProductsAtom = atom((get) => {
  const products = get(productsAtom);
  const search = get(searchAtom);

  return search
    ? products.filter(
        (product) =>
          product.sku?.toString().includes(search) ||
          product.department.toLowerCase().includes(search) ||
          product.category.toLowerCase().includes(search) ||
          product?.storeLocation?.toLowerCase().includes(search) ||
          product?.warehouseLocation?.toLowerCase().includes(search),
      )
    : products;
});
