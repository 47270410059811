import { ChevronDownOutline } from "#/components-ng"
import { S, Stack } from "#/s"
import { Button, Menu } from "@mantine/core"
import { Link } from "react-router-dom"
import { FormTable } from "./table"

export function FormPage() {
	return (
		<Stack alignItems="start" gap="6">
			<Menu>
				<Menu.Target>
					<Button color="brand" rightIcon={<ChevronDownOutline />}>
						I want to
					</Button>
				</Menu.Target>
				<Menu.Dropdown>
					<Link to="create">
						<Menu.Item>Create new form</Menu.Item>
					</Link>
				</Menu.Dropdown>
			</Menu>
			<S.div w="full">
				<FormTable />
			</S.div>
		</Stack>
	)
}
