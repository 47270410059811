import { atom } from "jotai";

interface WaitingList {
  id: number;
  productName: string;
  product: number;
  customer: number;
  email: string;
  qty: number;
  note: string;
  status: string;
}

const waitingListsBaseAtom = atom<WaitingList[]>([]);
export const waitingListsAtom = atom<WaitingList[]>((get) =>
  get(waitingListsBaseAtom)
);

export const waitingListsCreateAtom = atom(
  null,
  (get, set, waitingList: WaitingList) => {
    set(waitingListsBaseAtom, [...get(waitingListsBaseAtom), waitingList]);
  }
);

export const waitingListsDeleteAtom = atom(null, (get, set, index: number) => {
  set(
    waitingListsBaseAtom,
    get(waitingListsBaseAtom).filter((_, i) => i !== index)
  );
});

export const waitingListsUpdateAtom = atom(
  null,
  (get, set, payload: { index: number; waitingList: WaitingList }) => {
    set(
      waitingListsBaseAtom,
      get(waitingListsBaseAtom).map((v, i) =>
        i === payload.index ? payload.waitingList : v
      )
    );
  }
);

export const waitingListsClearAtom = atom(null, (get, set, _?: undefined) => {
  set(waitingListsBaseAtom, []);
});
