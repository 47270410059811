import * as M from "@mantine/core"
import { trpc } from "#/trpc"
import { useEffect, useState } from "react"
import { customerAtom } from "../state/customer"
import { useAtomValue } from "jotai/react"

type AnswerSurveyModalProps = {
	formUuid: string
} & M.ModalProps

// @ts-ignore
const BASE_URL = import.meta.env.SECRET_SHOP_BASE_URL

export function AnswerSurveyModal({ formUuid, ...modalProps }: AnswerSurveyModalProps) {
	const [iframeUrl, setIframeUrl] = useState<string>()
	const customer = useAtomValue(customerAtom)

	const { mutate: createToken } = trpc.v2_5.form.createToken.useMutation({
		onSuccess: (token) => {
			const url = new URL(`${BASE_URL}/survey/${formUuid}/standalone`)
			url.searchParams.set("token", token.token)
			setIframeUrl(url.toString())
		},
	})

	useEffect(() => {
		if (customer?.id != null) {
			createToken({
				formUuid,
				userId: customer.id,
			})
		}
	}, [customer?.id, createToken, formUuid])

	return (
		<M.Modal
			size="xl"
			centered
			withCloseButton={false}
			{...modalProps}
		>
			{iframeUrl && (
				<iframe
					src={iframeUrl}
					style={{
						width: "100%",
						height: "600px",
						border: "none",
					}}
				/>
			)}
		</M.Modal>
	)
}
