import { ChevronDownOutline, PrintAndEmailIcon } from '#/components-ng/icons.js'
import { useAuth } from '#/context/AuthContext.js'
import { printTicketFromOrderV2 } from '#/modules/ticket/print.js'
import { RouterOutputs, trpc } from '#/trpc.js'
import { reportUserError, reportUserSuccess } from '#/util/index.js'
import { closeModal, openConfirmModal, openModal } from '@mantine/modals'
import { applyDiscountV2 } from '../util/index.js'
import { cartAtom, saveCartAtom } from './state/cart.js'
import {
	amountDueAtom,
	cashPaidOutAtom,
	taxAtom,
	orderTotalAtom,
	subtotalAtom,
	subtotalTaxAtom,
	shippingCostAtom,
} from './state/cost-breakdown.js'
import { idempotencyKeyAtom } from './state/idempotency-key.js'
import {
	cashierAtom,
	customerAtom,
	helperAtom,
	salesAssociateAtom,
	submittedAtom,
	receiptNameAtom,
	seasonalDiscountAtom,
	peopleValidationAtom,
  couponAtom,
} from './state/index.js'
import {
	cardPaymentMethodsAtom,
	cashPaymentMethodAtom,
	giftPaymentMethodAtom,
	paymentMethodsErrorMessagesAtom,
	payWithTerminalMethodAtom,
	userCreditsPaymentMethodAtom,
} from './state/payment-methods.js'
import { reportAtom } from './state/reporting.js'
import * as M from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import Decimal from 'decimal.js'
import { useAtomValue, useSetAtom } from 'jotai'
import React from 'react'
import { useNavigate } from 'react-router'
import MailIconOutline from '~icons/ion/ios-email-outline'
import PrintIconOutline from '~icons/ion/print-outline'
import { getDiscountLabel } from '#/util/discounts.js'
import { token } from '#/css/tokens'
import { uniqBy } from 'lodash'
import { P, match } from 'ts-pattern'
import { filialAtom } from './state/filial.js'
import { nanoid } from 'nanoid'
import { useTerminal } from '#/state/terminal.hook.js'
import { Err, err, ok, Result, ResultAsync } from 'neverthrow'
import { Box, HStack, Stack, VStack } from '#/css/jsx'
import { css } from '#/css/css'
import { S } from '#/s.js'
import { SurveyListModal } from './answer-survey/survey-list-modal'

export function CheckoutAndPrint() {
	const [printReceipt, setPrintReceipt] = useState<boolean>(false)
	const [showSurveyList, setShowSurveyList] = useState(false)
	const report = useAtomValue(reportAtom)
	const saveCart = useSetAtom(saveCartAtom)
	const customer = useAtomValue(customerAtom)
	const cart = useAtomValue(cartAtom)
	const cashPaymentMethod = useAtomValue(cashPaymentMethodAtom)
	const cardPaymentMethods = useAtomValue(cardPaymentMethodsAtom)
	const userCreditsPaymentMethod = useAtomValue(userCreditsPaymentMethodAtom)
	const terminalPaymentMethod = useAtomValue(payWithTerminalMethodAtom)
	const tax = useAtomValue(taxAtom)
	const amountDue = useAtomValue(amountDueAtom)
	const cashPaidOut = useAtomValue(cashPaidOutAtom)
	const taxTotal = useAtomValue(subtotalTaxAtom)
	const subTotal = useAtomValue(subtotalAtom)
	const total = useAtomValue(orderTotalAtom)
	const setSubmitted = useSetAtom(submittedAtom)
	const [loading, setLoading] = React.useState(false)
	const navigate = useNavigate()
	const salesAssociate = useAtomValue(salesAssociateAtom)
	const cashier = useAtomValue(cashierAtom)
	const helper = useAtomValue(helperAtom)
	const receiptName = useAtomValue(receiptNameAtom)
	const [{ auth }] = useAuth()
	const globalDiscountApplied = useAtomValue(giftPaymentMethodAtom)
	const ctx = trpc.useContext()
	const idempotencyKey = useAtomValue(idempotencyKeyAtom)
	const shippingCost = useAtomValue(shippingCostAtom)
	const seasonalDiscount = useAtomValue(seasonalDiscountAtom)
	const filial = useAtomValue(filialAtom)
	const errors = useAtomValue(paymentMethodsErrorMessagesAtom)
	const terminal = useTerminal()
	const checkoutWithTerminal = useTerminalCheckout()
  const coupon = useAtomValue(couponAtom)

	const createOrderMutation = trpc.order.create.useMutation({
		onSuccess(order) {
			setLoading(false)
			reportUserSuccess({
				title: 'Order created successfully',
				message: `Order #${order?.receiptNumber ?? 1} created successfully`,
			})
			if (printReceipt) {
				printTicketFromOrderV2({
					order: order,
					withLocation: false,
				})
			}
			ctx.order.invalidate()
			ctx.report.invalidate()
			ctx.v2_5.order.invalidate()
			navigate('/sales')
		},
		onError(error) {
			setLoading(false)
			reportUserError({
				title: 'Failed to create order',
				message: error.message,
			})
		},
	})

	const { mutateAsync: verifyCartEntriesStock } =
		trpc.cart.verifyStock.useMutation({
			onError(error) {
				setLoading(false)
				reportUserError({
					title: 'Failed to create order',
					message: error.message,
				})
			},
		})

	const { mutateAsync: autoUpdateSeasonalDiscounts } =
		trpc.v2_5.discounts.autoUpdateSeasonalDiscounts.useMutation()

	const { mutateAsync: invalidateUserSeasonalDiscount } =
		trpc.v2_5.discounts.invalidateUserSeasonalDiscount.useMutation()

	const peopleValidation = useAtomValue(peopleValidationAtom)
	const { data: surveys } = trpc.v2_5.form.getManyBy.useQuery(
		{
			submitted: false,
			userId: customer?.id!,
		},
		{
			enabled: customer != null,
		},
	)

	const handleCheckoutAndPrint = React.useCallback(
		(sendEmail: boolean) => {
			setSubmitted(true)
			setLoading(true)
			const doCheckout = async function () {
				try {
					let stopCheckout = false

					for (const validation of peopleValidation) {
						match(validation)
							.with(
								{ code: 'SAME_CUSTOMER_AND_ASSOCIATE' },
								() => {
									report.error({
										id: 'make-a-sale__same_customer_and_associate',
										title: 'Failed to create order',
										description:
											'Customer and associated cannot be the samee',
									})
									stopCheckout = true
								},
							)
							.exhaustive()
					}

					if (stopCheckout) {
						setLoading(false)
						return
					}

					if (!cart.customerId || !customer) {
						report.error({
							id: 'make-a-sale__create-order__no-customer',
							title: 'Failed to create order',
							description: 'You must add a customer',
						})
						setLoading(false)
						return
					}

					if (cart.products.length === 0) {
						report.error({
							id: 'make-a-sale__create-order__no-items',
							title: 'Failed to create order',
							description: 'You must add at least one item',
						})
						setLoading(false)
						return
					}

					if (
						cashPaymentMethod.eq(0) &&
						cardPaymentMethods.length === 0 &&
						userCreditsPaymentMethod.eq(0) &&
						!terminalPaymentMethod.selected
					) {
						report.error({
							id: 'make-a-sale__create-order__no-payment-methods',
							title: 'Failed to create order',
							description:
								'You must add at least a payment method',
						})
						setLoading(false)

						return
					}

					if (amountDue.gt(0) && !terminalPaymentMethod.selected) {
						report.error({
							id: 'make-a-sale__create-order__amount-due',
							title: 'Failed to create order',
							description:
								'Customer still has an amount due for payment',
						})
						setLoading(false)

						return
					}

					if (
						terminalPaymentMethod.selected &&
						(terminal?.id == null || terminal?.status !== 'PAIRED')
					) {
						report.error({
							id: 'make-a-sale__create-order__terminal-not-paired',
							title: 'Failed to create order',
							description: 'You must pair with a terminal',
						})

						return
					}

					if (errors.length > 0) {
						report.error({
							id: 'make-a-sale__create-order__payment-methods-error',
							title: 'Failed to create order',
							description: 'You have payment errors',
						})
						setLoading(false)

						return
					}

					if (!auth?.user?.filialId) {
						report.error({
							id: 'make-a-sale__create-order__no-filial',
							title: 'Failed to create order',
							description:
								'You must be a filial id associated with your account',
						})
						setLoading(false)

						return
					}

					const verifyStock = await verifyCartEntriesStock({
						filialId: auth?.user?.filialId,
						cartItemsSkus: cart.products.map((entry) => ({
							itemSkuId: entry.itemWithVariant.itemSku.id,
							quantity: entry.quantity,
						})),
					})

					if (!verifyStock) {
						setLoading(false)
						return
					}

					let checkoutId: string | null = null
					if (terminalPaymentMethod.selected) {
						const checkoutRes = await checkoutWithTerminal({
							total,
						})
						if (checkoutRes.isErr()) {
							setLoading(false)
							return
						}

						if (checkoutRes.value.status === 'CANCELED') {
							setLoading(false)
							return
						}

						checkoutId = checkoutRes.value.checkoutId
					}

					// await saveCart("OPEN")
					await saveCart('CLOSED')

					createOrderMutation.mutate({
						sendEmail: sendEmail,
						input: {
							idempotencyKey: idempotencyKey,
							orderStatus: 'COMPLETE',
							orderType:
								filial?.type === 'WAREHOUSE'
									? 'WAREHOUSE'
									: 'IN_STORE',
							subTotal,
							taxRate: tax.value,
							taxable: customer.taxable,
							taxTotal: customer.taxable
								? taxTotal
								: new Decimal(0),
							//TODO(v2): Backend should calculate this
							total,
							filial: {
								connect: {
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									id: auth!.user!.filialId!,
								},
							},
							customer: {
								connect: {
									id: customer.id,
								},
							},
							associated: {
								connect: {
									id: salesAssociate?.id,
								},
							},
							cashier: {
								connect: {
									id: cashier?.id,
								},
							},
							helper: {
								connect: {
									id: helper?.id,
								},
							},
							orderItemSku: {
								createMany: {
									data: cart.products.map((entry) => ({
										itemSkuId:
											entry.itemWithVariant.itemSku.id,
										quantity: entry.quantity,
										price:
											filial?.type === 'WAREHOUSE'
												? entry.itemWithVariant.itemSku
														.cost
												: entry.itemWithVariant.itemSku
														.price,
										discountReference:
											entry.discount?.reference,
										discountAmount: entry.discount?.amount,
										discountType: entry.discount?.type,
										discountMode:
											entry.discount?.discountMode,
										//TODO(v2): Backend should calculate this
										total: applyDiscountV2(
											filial?.type === 'WAREHOUSE'
												? entry.itemWithVariant.itemSku.cost.times(
														entry.quantity,
													)
												: entry.itemWithVariant.itemSku.price.times(
														entry.quantity,
													),
											entry.discount,
										),
									})),
								},
							},
							orderPayment: {
								createMany: {
									data: cardPaymentMethods
										.concat({
											type: 'CASH',
											paidIn: cashPaymentMethod,
											paidOut: cashPaidOut,
										})
										.concat(
											userCreditsPaymentMethod.gt(0)
												? {
														type: 'USER_CREDITS',
														paidIn: userCreditsPaymentMethod,
														paidOut: new Decimal(0),
													}
												: [],
										)
										.map((pm) => ({
											filialId: auth!.user!.filialId!,
											paidIn: pm.paidIn,
											paidOut: pm.paidOut,
											paymentAmount: pm.paidIn.sub(
												pm.paidOut,
											),
											paymentType: pm.type,
											squareTerminalCheckoutId: null as
												| string
												| null,
										}))
										.concat(
											checkoutId
												? {
														filialId:
															auth.user.filialId,
														paidIn: total,
														paidOut: new Decimal(0),
														paymentAmount: total,
														paymentType:
															'SQUARE_TERMINAL',
														squareTerminalCheckoutId:
															checkoutId,
													}
												: [],
										),
								},
							},
							receiptName:
								receiptName === 'companyName'
									? customer?.company ??
										`${customer?.firstName} ${customer?.lastName ?? ''}`
									: `${customer?.firstName} ${customer?.lastName ?? ''}`,
							discountAmount: !globalDiscountApplied.amount.eq(0)
								? globalDiscountApplied.amount
								: null,
							discountType: !globalDiscountApplied.amount.eq(0)
								? globalDiscountApplied.type === 'Fixed'
									? 'AMOUNT'
									: 'PERCENTAGE'
								: null,
							orderShipping: shippingCost.gt(0)
								? {
										create: {
											customer: {
												connect: {
													id: customer.id,
												},
											},
											addressLine1: '',
											city: '',
											state: '',
											country: '',
											zipCode: '',
											shippingCompany: '',
											shippingNumber: '',
											shippingCost: shippingCost,
										},
									}
								: undefined,
              coupon: coupon != null 
                ? {
                    connect: {
                      id: coupon.id,
                    },
                  } 
                : undefined,
              seasonalDiscount: seasonalDiscount != null 
                ? {
                    connect: {
                      id: seasonalDiscount.id,
                    },
                  } 
                : undefined,
						},
					})

					await autoUpdateSeasonalDiscounts({
						customerId: customer.id,
						orderTotal: total,
					})

					if (seasonalDiscount != null) {
						invalidateUserSeasonalDiscount({
							userDiscountId: seasonalDiscount.id,
						})
					}
				} catch (e) {
					return showNotification({
						title: 'Failed to create order',
						message: e?.message,
						color: 'red',
					})
				} finally {
					// setLoading(false);
				}
			}

			const preprocessCheckout = async () => {
				if (customer?.id == null) {
					await doCheckout()
					return
				}

				const eligibility = await checkSeasonalDiscountsEligibility({
					tctx: ctx,
					orderTotal: total,
					customerId: customer.id,
				})

				match(eligibility.status)
					.with(P.union('ELIGIBLE', 'NO_DISCOUNTS'), () =>
						doCheckout(),
					)
					.with('NOT_ELIGIBLE', () => {
						openConfirmModal({
							onConfirm: () => {
								doCheckout()
							},
							onCancel: () => {
								setLoading(false)
							},
							onClose: () => {
								setLoading(false)
							},
							title: (
								<M.Text
									c={token('colors.slate.800')}
									fw={token('fontWeights.medium')}
								>
									Seasonal discounts eligibility
								</M.Text>
							),
							children: (
								<M.Text c={token('colors.slate.800')}>
									Customer has not met the minimum order
									amount for the following seasonal discounts:
									<M.Table>
										<thead>
											<tr>
												<th>Discount</th>
												<th>Min. purchase</th>
												<th>Goal</th>
											</tr>
										</thead>
										<tbody>
											{eligibility.nonEligibleDiscounts.map(
												(discount) => (
													<tr key={discount.id}>
														<td>
															{discount.reference}{' '}
															{getDiscountLabel(
																discount,
															)}
														</td>
														<td>
															$
															{discount.minPurchaseForEligibility?.toFixed(
																2,
															)}
														</td>
														<td>
															+$
															{discount
																.minPurchaseForEligibility!.minus(
																	total,
																)
																.toFixed(2)}
														</td>
													</tr>
												),
											)}
										</tbody>
									</M.Table>
								</M.Text>
							),
							labels: {
								confirm: 'Checkout anyways',
								cancel: 'Continue shopping',
							},
						})
					})
					.exhaustive()
			}

			if (surveys != null && surveys.length > 0) {
				openConfirmModal({
					title: <S.p fw="medium">Survey available</S.p>,
					children:
						'There is a survey available. Do you want to fill it out?',
					cancelProps: {
						children: 'Fill out survey',
					},
					confirmProps: {
						children: 'Continue with checkout',
					},
					onConfirm: () => {
						preprocessCheckout()
					},
					onCancel: () => {
						setLoading(false)
						setShowSurveyList(true)
					},
				})
				return
			}

			preprocessCheckout()
		},
		[
			errors.length,
			terminal?.id,
			terminal?.status,
			terminalPaymentMethod.selected,
			peopleValidation,
			seasonalDiscount,
			invalidateUserSeasonalDiscount,
			autoUpdateSeasonalDiscounts,
			ctx,
			setSubmitted,
			cart.customerId,
			cart.products,
			customer,
			cashPaymentMethod,
			cardPaymentMethods,
			userCreditsPaymentMethod,
			amountDue,
			auth,
			verifyCartEntriesStock,
			saveCart,
			createOrderMutation,
			idempotencyKey,
			subTotal,
			tax.value,
			taxTotal,
			total,
			salesAssociate?.id,
			cashier?.id,
			helper?.id,
			cashPaidOut,
			receiptName,
			globalDiscountApplied.amount,
			globalDiscountApplied.type,
			shippingCost,
			report,
			filial?.type,
			checkoutWithTerminal,
			surveys,
		],
	)

	return (
		<>
			<M.Menu disabled={loading}>
				<M.Menu.Target>
					<M.Button
						loading={loading}
						sx={{ gridColumn: 'span 2' }}
						className="col-span-3"
						rightIcon={<ChevronDownOutline />}
						loaderPosition="right"
					>
						Checkout
					</M.Button>
				</M.Menu.Target>
				<M.Menu.Dropdown>
					<M.Menu.Item
						onClick={() => {
							setPrintReceipt(true)
							handleCheckoutAndPrint(false)
						}}
						icon={<PrintIconOutline fontSize={20} />}
						disabled={loading}
					>
						Checkout and print receipt
					</M.Menu.Item>
					<M.Menu.Item
						onClick={() => {
							setPrintReceipt(false)
							handleCheckoutAndPrint(true)
						}}
						icon={<MailIconOutline fontSize={20} />}
						disabled={loading}
					>
						Checkout and send receipt by email
					</M.Menu.Item>
					<M.Menu.Item
						onClick={() => {
							setPrintReceipt(true)
							handleCheckoutAndPrint(true)
						}}
						icon={<PrintAndEmailIcon width={25} height={25} />}
						disabled={loading}
					>
						Checkout, print & send receipt by email
					</M.Menu.Item>
				</M.Menu.Dropdown>
			</M.Menu>

			<SurveyListModal
				opened={showSurveyList}
				onClose={() => setShowSurveyList(false)}
			/>
		</>
	)
}

interface CheckSeasonalDiscountsEligibilityOpts {
	tctx: ReturnType<typeof trpc.useContext>
	orderTotal: Decimal
	customerId: number
}

interface DiscountEligibility {
	status: 'ELIGIBLE' | 'NOT_ELIGIBLE' | 'NO_DISCOUNTS'
	nonEligibleDiscounts: Array<
		RouterOutputs['v2_5']['discounts']['getSeasonalDiscounts'][0]
	>
}

async function checkSeasonalDiscountsEligibility({
	tctx,
	orderTotal,
	customerId,
}: CheckSeasonalDiscountsEligibilityOpts): Promise<DiscountEligibility> {
	let seasonalDiscounts =
		await tctx.v2_5.discounts.getSeasonalDiscounts.fetch({
			filter: {
				onlyEligible: {
					customerId,
				},
			},
		})

	seasonalDiscounts = seasonalDiscounts.sort(
		(a, b) =>
			a.minPurchaseForEligibility
				?.minus(b.minPurchaseForEligibility ?? 0)
				.toNumber() ?? 0,
	)

	seasonalDiscounts = uniqBy(seasonalDiscounts, (disc) => disc.reference)

	if (seasonalDiscounts.length === 0) {
		return {
			status: 'NO_DISCOUNTS',
			nonEligibleDiscounts: [],
		}
	}

	const nonEligibleDiscounts = seasonalDiscounts.filter(
		(discount) =>
			discount.minPurchaseForEligibility?.gt(orderTotal) ?? false,
	)

	if (nonEligibleDiscounts.length > 0) {
		return {
			status: 'NOT_ELIGIBLE',
			nonEligibleDiscounts: nonEligibleDiscounts,
		}
	}

	return {
		status: 'ELIGIBLE',
		nonEligibleDiscounts: [],
	}
}

function useTerminalCheckout() {
	const tctx = trpc.useContext()
	const terminal = useTerminal()
	const report = useAtomValue(reportAtom)
	const { mutateAsync: createTerminalCheckout } =
		trpc.v2_5.square.createTerminalCheckout.useMutation()

	const checkout = React.useCallback(
		async ({
			total,
		}: { total: Decimal }): Promise<
			Result<
				| { checkoutId: string; status: 'COMPLETED' }
				| { status: 'CANCELED' },
				Error
			>
		> => {
			if (terminal?.id == null) {
				report.error({
					title: 'Failed to checkout with terminal',
					description: 'Terminal not paired',
				})

				return err(new Error('Terminal not paired'))
			}

			const checkoutRes = await createTerminalCheckout({
				deviceId: terminal.id,
				idempotencyKey: nanoid(),
				total,
			})

			if (checkoutRes.isErr()) {
				console.error(
					'Failed to create terminal checkout',
					checkoutRes.error,
				)
				report.error({
					title: 'Failed to create terminal checkout',
					description: checkoutRes.error.body.toString(),
				})

				return err(
					new Error('Failed to create terminal checkout', {
						cause: checkoutRes.error,
					}),
				)
			}

			const checkoutId = checkoutRes.value.id

			let isCanceled = false
			openModal({
				modalId: 'terminal-checkout-modal',
				withCloseButton: false,
				closeOnClickOutside: false,
				closeOnEscape: false,
				children: (
					<TerminalCheckoutInProgressModalContent
						id={checkoutId}
						onClose={(status) => {
							closeModal('terminal-checkout-modal')
							if (status === 'CANCELED') {
								isCanceled = true
							}
						}}
					/>
				),
			})

			let tries = 0
			while (true) {
				if (isCanceled) {
					return ok({
						status: 'CANCELED' as const,
					})
				}

				const checkoutStatusRes =
					await tctx.v2_5.square.getTerminalCheckout.fetch({
						id: checkoutId,
					})

				if (checkoutStatusRes.isErr()) {
					report.error({
						title: 'Failed to get terminal checkout status',
						description: checkoutStatusRes.error.body.toString(),
					})

					tries += 1
					if (tries >= 3) {
						closeModal('terminal-checkout-modal')
						return err(
							new Error(
								'Failed to get terminal checkout status',
								{
									cause: checkoutStatusRes.error,
								},
							),
						)
					}
					continue
				}

				const checkoutStatus = checkoutStatusRes.value.status

				if (checkoutStatus === 'COMPLETED') {
					closeModal('terminal-checkout-modal')

					break
				} else if (
					checkoutStatus === 'CANCELED' ||
					checkoutStatus === 'CANCELED_REQUESTED'
				) {
					report.warning({
						title: 'Terminal checkout canceled',
						description: 'Terminal checkout canceled',
					})

					closeModal('terminal-checkout-modal')
					return ok({
						status: 'CANCELED' as const,
					})
				}

				await new Promise((resolve) => setTimeout(resolve, 2500))
			}

			return ok({
				checkoutId: checkoutId,
				status: 'COMPLETED' as const,
			})
		},
		[terminal, report, createTerminalCheckout, tctx],
	)

	const safeCheckout = React.useCallback(
		async ({ total }: { total: Decimal }) => {
			try {
				return checkout({ total })
			} catch (e) {
				report.error({
					title: 'Failed to checkout with terminal',
					description: e.message,
				})
				return err(e)
			}
		},
		[checkout, report],
	)

	return safeCheckout
}

function TerminalCheckoutInProgressModalContent(props: {
	id: string
	onClose: (status: 'CANCELED') => void
}) {
	const { mutateAsync, isLoading } =
		trpc.v2_5.square.cancelTerminalCheckout.useMutation()
	const [tries, setTries] = useState(0)

	async function cancelCheckout() {
		try {
			await mutateAsync({
				id: props.id,
			})
			props.onClose('CANCELED')
		} catch (e) {
			reportUserError({
				title: 'Failed to cancel terminal checkout',
				message: e.message,
			})

			setTries((prev) => prev + 1)
		}
	}

	async function forceCancelCheckout() {
		try {
			await mutateAsync({
				id: props.id,
			})
		} finally {
			props.onClose('CANCELED')
		}
	}

	return (
		<VStack gap="2">
			<HStack mb="4">
				<M.Loader size="xs" />
				<Box fs="lg" fw="medium">
					Waiting for payment
				</Box>
			</HStack>
			<M.Button
				color="gray.1"
				c="gray.8"
				loading={isLoading}
				onClick={cancelCheckout}
				className={css({
					alignSelf: 'stretch',
				})}
			>
				Cancel
			</M.Button>
			<M.Button
				color="red.4"
				onClick={forceCancelCheckout}
				className={css({
					display: tries >= 3 ? 'block' : 'none',
					alignSelf: 'stretch',
				})}
			>
				Force cancel
			</M.Button>
		</VStack>
	)
}
