import { PrimitiveAtom } from "jotai/vanilla"

export interface MarkerState {
	id: string
	radius: number
	x: number
	y: number
  image?: string | null
}

export const itemSkuIdAtom = atom<number | null>(null)
export const imageSrcAtom = atom<string | null>(null)
export const imageRefAtom = atom<{ref: HTMLImageElement} | null>(null)
export const markersAtom = atom<Array<PrimitiveAtom<MarkerState>>>([])

export const transformsAtom = atom((get) => {
	const imageRef = get(imageRefAtom)

	function vpToRatio(point: { x: number; y: number }) {
		const bb = imageRef?.ref.getBoundingClientRect()

		if (!bb) {
			return {
				x: 1,
				y: 1,
			}
		}

		return {
			x: (point.x - bb.left) / bb.width,
			y: (point.y - bb.top) / bb.height,
		}
	}

	return {
		vpToRatio,
	}
})
