import * as M from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useAtom } from "jotai"
import {
	Field,
	FieldAtom,
	fieldAtom,
	formAtom,
	FormValues,
	useField,
	useFieldInitialValue,
	useForm,
	useFormActions,
} from "form-atoms"
import { AnswerType, I18NString } from "./types"
import { css, HStack, S, Stack } from "#/s"
import { PlaceholderButton } from "./placeholder-button"
import { CloseOutline } from "#/components-ng"
import { ModeMolecule } from "./state"
import { Provider } from "jotai/react"
import { useMolecule } from "bunshi/react"
import { useHydrateAtoms } from "jotai/utils"

const questionFormAtom = formAtom({
	title: fieldAtom({ value: { en: "", es: "" } }),
	answerType: fieldAtom({ value: "TEXT" as AnswerType }),
	multiOptions: [] as Array<FieldAtom<I18NString>>,
})

export interface QuestionFormInitialValues {
	title: I18NString
	answerType: AnswerType
	multiOptions: Array<I18NString>
}

export interface QuestionFormSchema {
	title: I18NString
	answerType: AnswerType
	multiOptions: Array<I18NString>
}

function _QuestionFormDrawer(
	props: Omit<M.DrawerProps, "onSubmit"> & {
		initialValues: QuestionFormInitialValues
		onSubmit: (values: QuestionFormSchema) => void
		mode: "create" | "edit"
	},
) {
	const { onSubmit, initialValues, ...restProps } = props
	const { submit, fieldAtoms } = useForm(questionFormAtom)
	const { updateFields } = useFormActions(questionFormAtom)
	const modeAtom = useMolecule(ModeMolecule)
	useHydrateAtoms([[modeAtom, props.mode]])
	const mode = useAtomValue(modeAtom)

	useEffect(() => {
		updateFields((current) => {
			return {
				...current,
				multiOptions: initialValues.multiOptions.map((opt) =>
					fieldAtom({ value: opt }),
				),
			}
		})
	}, [initialValues.multiOptions])

	function handleSubmit(values: QuestionFormSchema) {
		props.onSubmit(values)
		props.onClose()
	}

	return (
		<M.Drawer title="Add Question" position="right" {...restProps}>
			<form
				onSubmit={(e) => {
					e.stopPropagation()
					submit(handleSubmit)(e)
				}}
			>
				<M.Stack spacing="md">
					<Field
						atom={fieldAtoms.title}
						initialValue={props.initialValues.title}
						render={(state, actions) => (
							<M.TextInput
								label="Title"
								value={state.value.en}
								readOnly={mode === "edit"}
								onChange={(e) =>
									actions.setValue({
										en: e.currentTarget.value,
										es: state.value.es,
									})
								}
							/>
						)}
					/>

					<Field
						atom={fieldAtoms.answerType}
						initialValue={props.initialValues.answerType}
						render={(state, actions) => (
							<M.Select
								label="Answer type"
								value={state.value}
								readOnly={mode === "edit"}
								onChange={(value) => {
									if (value == null) {
										return
									}
									actions.setValue(value as AnswerType)
								}}
								data={Object.entries(AnswerType).map(([label, value]) => ({
									label,
									value,
								}))}
							/>
						)}
					/>
					<QuestionOptionsWrapper />
					{mode === "create" && <M.Button type="submit">Save</M.Button>}
				</M.Stack>
			</form>
		</M.Drawer>
	)
}

export function QuestionFormDrawer(
	props: Omit<M.DrawerProps, "onSubmit"> & {
		initialValues: QuestionFormInitialValues
		onSubmit: (values: QuestionFormSchema) => void
		mode: "create" | "edit"
	},
) {
	return (
		<Provider>
			<_QuestionFormDrawer {...props} />
		</Provider>
	)
}

function QuestionOptionsWrapper() {
	const { fieldAtoms } = useForm(questionFormAtom)
	const answerType = useField(fieldAtoms.answerType)
	if (answerType.state.value !== "MULTI_OPTION") {
		return null
	}
	return <QuestionOptions />
}

function QuestionOptions() {
	const { fieldAtoms } = useForm(questionFormAtom)
	const { updateFields } = useFormActions(questionFormAtom)
	const modeAtom = useMolecule(ModeMolecule)
	const mode = useAtomValue(modeAtom)

	function handleAdd() {
		updateFields((current) => {
			return {
				...current,
				multiOptions: [
					...current.multiOptions,
					fieldAtom({
						value: { en: "", es: "" },
					}),
				],
			}
		})
	}

	function handleRemove(index: number) {
		updateFields((current) => {
			return {
				...current,
				multiOptions: current.multiOptions.filter((_, i) => i !== index),
			}
		})
	}

	return (
		<S.div>
			<S.p c="slate.600" fs="sm" fw="medium" mb={1}>
				Options
			</S.p>
			<Stack>
				{fieldAtoms.multiOptions.map((optionAtom, i) => (
					<HStack key={i}>
						<Field
							atom={optionAtom}
							render={(state, actions) => (
								<M.TextInput
									readOnly={mode === "edit"}
									value={state.value.en}
									className={css({
										flex: 1,
									})}
									onChange={(e) =>
										actions.setValue({
											en: e.currentTarget.value,
											es: state.value.es,
										})
									}
								/>
							)}
						/>
						{mode === "create" && (
							<S.button
								type="button"
								c="slate.400"
								bg={{ _hover: "slate.100" }}
								onClick={() => handleRemove(i)}
							>
								<CloseOutline />
							</S.button>
						)}
					</HStack>
				))}
				{mode === "create" && (
					<PlaceholderButton
						onClick={handleAdd}
						css={{
							w: "full",
							py: "1.5",
						}}
					>
						Add option
					</PlaceholderButton>
				)}
			</Stack>
		</S.div>
	)
}
