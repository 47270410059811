import { useAuth } from "#/context/AuthContext";
import { useNavigate, useParams } from "react-router";
import { useState, useMemo } from "react";
import { type RouterOutputs, trpc } from "#/trpc";
import { reportUserError } from "#/util";
import { styled } from "#/css/jsx";
import * as M from "@mantine/core";
import { FilterOutlineIcon } from "#/components-ng";
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
	type MRT_SortingState,
} from "mantine-react-table";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

type FilialFilterProps = {
	title: string;
	filialId: number;
	state: boolean;
};

export const ItemSkuPreviewHistory = () => {
	const [{ auth }] = useAuth();
	const params = useParams() as any;
	const itemSkuId = Number.parseInt(params.id);

	const [filialFilter, setFilialFilter] = useState<FilialFilterProps[]>([]);

	trpc.filial.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			if (data) {
				setFilialFilter(
					data.map((f) => ({ title: f.name, state: true, filialId: f.id })),
				);
			}
		},

		onError: (err) => {
			reportUserError({
				title: "Failed to load filials",
				message: err.message,
			});
		},
		enabled: !auth.user?.filialId,
	});

	const handleFilterChange = (filialPosition: string) => {
		setFilialFilter({
			...filialFilter,
			[filialPosition]: {
				...filialFilter[filialPosition],
				state: !filialFilter[filialPosition].state,
			},
		});
	};

	return (
		<styled.div
			display="flex"
			mt="1rem"
			gap="1.5rem"
			flexDir="column"
			alignItems="flex-start"
		>
			<p className="mb-3 text-lg font-semibold text-[#212121]">History</p>
			{!auth.user?.filialId && (
				<M.Popover
					width={200}
					position="bottom"
					withArrow
					shadow="md"
					zIndex={1600}
					radius="sm"
				>
					<M.Popover.Target>
						<M.Button leftIcon={<FilterOutlineIcon />} color="white" c="dark.6">
							Filter by filial
						</M.Button>
					</M.Popover.Target>
					<M.Popover.Dropdown>
						<M.Stack>
							{Object.keys(filialFilter ?? {}).map((id) => (
								<div key={filialFilter[id].filialId}>
									<M.Checkbox
										label={filialFilter[id].title}
										checked={filialFilter[id].state}
										onChange={() => handleFilterChange(id)}
									/>
								</div>
							))}
						</M.Stack>
					</M.Popover.Dropdown>
				</M.Popover>
			)}
			<Table
				filialFilter={
					auth?.user?.filialId
						? [auth?.user?.filialId]
						: Object.values(filialFilter)
								.filter((filial) => filial.state)
								.map((filial) => filial.filialId)
				}
				itemSkuId={itemSkuId}
			/>
		</styled.div>
	);
};

type TableProps = {
	filialFilter: number[];
	itemSkuId: number;
};

const Table = (props: TableProps) => {
	// pagination
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 25,
	});

	// sorting
	const [sorting, setSorting] = useState<MRT_SortingState>([]);
	const querySorting = useMemo(() => {
		if (sorting[0]) {
			return {
				desc: sorting[0].desc,
				key: sorting[0].id as any,
			};
		}
		return null;
	}, [sorting]);

	const { data, isLoading } = trpc.v2_5.itemLog.getStockHistoryByPage.useQuery(
		{
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
			sorting: querySorting,
			search: null,
			filters: {
				itemSkuId: props.itemSkuId,
				filialIds: props.filialFilter,
			},
		},
		{
			onError: (err) => {
				reportUserError({
					title: "Failed to load promotions",
					message: err.message,
				});
			},
		},
	);

	const table = useMantineReactTable({
		data: data?.entries ?? [],
		columns,
		manualPagination: true,
		enableTopToolbar: false,
		rowCount: data?.totalEntries ?? 0,
		enableFilters: false,
		state: {
			pagination,
			sorting,
			isLoading,
		},
		enableStickyHeader: true,
		manualSorting: true,
		onSortingChange: setSorting,
		onPaginationChange: setPagination,
	});

	return <MantineReactTable table={table} />;
};

type ItemLog =
	RouterOutputs["v2_5"]["itemLog"]["getStockHistoryByPage"]["entries"][number];

const columns: MRT_ColumnDef<ItemLog>[] = [
	{
		id: "filialId",
		header: "Filial",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (props) => {
			return props.row.original.filial.name;
		},
	},
	{
		id: "user",
		header: "User",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (props) => {
			const original = props.row.original;

			return (
				<styled.p color="#3D3D3D">
					{original.userFullName}
					{!original.user && original.logType === "ORDER" && (
						<styled.span color="red.300"> (Guest)</styled.span>
					)}
				</styled.p>
			);
		},
	},
	{
		id: "createdAt",
		header: "Date",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (props) => {
			return dayjs(props.row.original.createdAt).format("DD/MM/YYYY");
		},
	},
	{
		id: "logType",
		header: "Log",
		enableSorting: false,
		enableColumnFilter: false,
		Cell: (props) => {
      const navigate = useNavigate()
			const original = props.row.original;

      const [enable, setEnable] = useState<boolean>(false);

      trpc.order.getById.useQuery({
        id: original.referenceId
      }, {
        enabled: enable,
        onSuccess: (data) => {
          if (data) {
            if(data.orderType === "ONLINE" || data.orderType === "PICKUP") {
              navigate(`/sales/ecommerce-orders/${original.referenceId}`)
              setEnable(false)
              return
            }
            navigate(`/sales/sales-history/preview/${original.referenceId}`)
            setEnable(false)
          }
        }
      })

			if (original.logType === "ORDER") {
				return (
          <styled.p
            color="sky.500"
            cursor="pointer"
            textDecoration="underline"
            onClick={() => {
              setEnable(true)
            }}
          >
            {original.logType} - {original?.receiptNumber ?? ""}
          </styled.p>
				);
			}

			return <styled.p>{original.logType}</styled.p>;
		},
	},
	{
		id: "stock",
		header: "Stock",
		enableSorting: false,
		enableColumnFilter: false,
		accessorKey: "stock",
		size: 120,
	},
	{
		id: "trx",
		header: "Trx",
		enableSorting: false,
		enableColumnFilter: false,
		accessorKey: "trx",
		size: 120,
	},
	{
		id: "result",
		header: "Result",
		enableSorting: false,
		enableColumnFilter: false,
		accessorKey: "result",
		size: 120,
	},
];
