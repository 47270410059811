import * as M from '@mantine/core'
import { trpc } from '#/trpc'
import { useState } from 'react'
import { AnswerSurveyModal } from './answer-survey-modal'
import { css, S } from '#/s'
import { customerAtom } from '../state'

type SurveyListModalProps = M.ModalProps

type I18NTitle = {
	en: string
	es: string
}

export function SurveyListModal(props: SurveyListModalProps) {
	const customer = useAtomValue(customerAtom)
	const { data: surveys } = trpc.v2_5.form.getManyBy.useQuery(
		{
			submitted: false,
			userId: customer?.id!,
		},
		{
			enabled: customer != null,
		},
	)
	const [selectedFormUuid, setSelectedFormUuid] = useState<string>()

	function handleSelectForm(formUuid: string) {
		setSelectedFormUuid(formUuid)
		props.onClose?.()
	}

	function handleClose() {
		setSelectedFormUuid(undefined)
		props.onClose?.()
	}

	return (
		<>
			<M.Modal
				title="Available Surveys"
				centered
				{...props}
				onClose={handleClose}
				classNames={{
					title: css({
						fontWeight: 'medium',
					}),
				}}
			>
				<S.div d="grid" gap="4">
					{surveys?.map((survey) => {
						const title = survey.title as I18NTitle
						return (
							<M.Paper
								component="button"
								key={survey.uuid}
								p="md"
								withBorder
								onClick={() => handleSelectForm(survey.uuid)}
								className={css({
									cursor: 'pointer',
									'&:hover': {
										backgroundColor: 'gray.50',
									},
								})}
							>
								<S.p fw="medium">
									{title?.en ?? 'Untitled Survey'}
								</S.p>
							</M.Paper>
						)
					})}
				</S.div>
			</M.Modal>

			{selectedFormUuid && (
				<AnswerSurveyModal
					formUuid={selectedFormUuid}
					opened={selectedFormUuid != null}
					onClose={() => setSelectedFormUuid(undefined)}
				/>
			)}
		</>
	)
}
