import {
  BannerIcon,
  ListBoxIcon,
  NotePadIcon,
  QuizIcon,
  RouletteIcon,
  TimerOutlineIcon,
} from "#/components-ng/icons.js";
import Card from "#/components/Card";
import CategoryGridLayout from "#/components/CategoryGridLayout";
import * as M from "@mantine/core";

export const CrmScene = () => {
  return (
    <M.Stack>
      <CategoryGridLayout category={"CRM"}>
        <Card
          icon={<NotePadIcon className="size-8" />}
          text="Blog"
          path="blog"
          module="crm.blog"
        />
        <Card
          icon={<BannerIcon />}
          text="Home page banner"
          path="banner"
          module="crm.banner"
        />
        <Card
          icon={<QuizIcon className="size-8" />}
          text="Quiz"
          path="quiz"
          module="crm.quiz"
        />
        <Card
          icon={<RouletteIcon />}
          text="Roulette"
          path="roulette"
          module="crm.roulette"
        />
        <Card
          icon={<ListBoxIcon fontSize={36} />}
          text="Forms"
          path="forms"
          module="crm.form"
        />
        <Card
          icon={<TimerOutlineIcon fontSize={36} />}
          text="Timer"
          path="timer"
          module="crm.timer"
        />
      </CategoryGridLayout>
    </M.Stack>
  );
};
