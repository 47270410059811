import {
  discountsAtom,
  globalDiscountsAtom,
  promotionsAtom,
} from "./discounts.js";
import { filialAtom } from "./filial.js";
import { CartEntry, Discount, ItemWithVariant } from "./types.js";
import { atom, Getter, Setter } from "jotai/vanilla";
import { maxBy, uniqBy } from "lodash";
import { DiscountWithDepartments } from "server";

interface CartEntriesAddAction {
  type: "add";
  payload: ItemWithVariant;
}

interface CartEntriesRemoveAction {
  type: "remove";
  payload: number;
}

interface CartEntriesSetQtyAction {
  type: "setQty";
  payload: {
    index: number;
    qty: number;
  };
}

interface CartEntriesReloadDiscountsAction {
  type: "reloadDiscounts" | "reloadDiscountsWithOverride";
}

interface CartEntriesSetDiscountAction {
  type: "setDiscount";
  payload: {
    index: number;
    discount: DiscountWithDepartments | null;
  };
}

type ProductsAction =
  | CartEntriesAddAction
  | CartEntriesRemoveAction
  | CartEntriesSetQtyAction
  | CartEntriesReloadDiscountsAction
  | CartEntriesSetDiscountAction;

export const cartEntriesBaseAtom = atom<CartEntry[]>([]);
export const cartEntriesAtom = atom(
  (get) => get(cartEntriesBaseAtom),
  (get, set, update: ProductsAction) => {
    set(
      cartEntriesBaseAtom,
      cartEntriesReducer(get, set, get(cartEntriesBaseAtom), update),
    );
  },
);

function cartEntriesReducer(
  get: Getter,
  set: Setter,
  cartEntries: CartEntry[],
  action?: ProductsAction,
): CartEntry[] {
  const filial = get(filialAtom);
  const checkIsStoreFilial =
    filial && filial.name?.toLocaleLowerCase()?.includes("store");

  function canApplyDiscount(
    discount: Discount,
    predicate: { departmentId: number; itemSkuId: number },
  ) {
    if (
      discount.filterMode == null ||
      discount.filterMode === "BY_DEPARTMENT"
    ) {
      return discount?.departmentDiscounts
        .map((d) => d.departmentId)
        .includes(predicate.departmentId);
    } else if (discount.filterMode === "BY_ITEM") {
      return discount.itemSkuDiscounts
        .map((d) => d.itemSkuId)
        .includes(predicate.itemSkuId);
    }
  }

  if (action?.type === "add") {
    const foundIndex = cartEntries.findIndex(
      (entry) => entry.itemWithVariant.itemSku.id === action.payload.itemSku.id,
    );
    // Increment qty if product is already an entry
    if (foundIndex >= 0) {
      set(lastCartEntryAddedIndexAtom, { index: foundIndex });
      return [
        ...cartEntries.slice(0, foundIndex),
        {
          ...cartEntries[foundIndex],
          quantity: checkIsStoreFilial
            ? cartEntries[foundIndex].quantity + 1
            : Math.min(
                cartEntries[
                  foundIndex
                ].itemWithVariant.itemSku.itemSkuStock?.find(
                  (stock) => stock.filialId === filial?.id,
                )?.quantity ?? 0,
                cartEntries[foundIndex].quantity + 1,
              ),
        },
        ...cartEntries.slice(foundIndex + 1),
      ];
    } else {
      set(lastCartEntryAddedIndexAtom, { index: cartEntries.length });

      const discounts = get(discountsAtom);
      const availableDiscounts = discounts.filter((discount) =>
        canApplyDiscount(discount, {
          departmentId: action.payload.item.departmentId,
          itemSkuId: action.payload.itemSku.id,
        }),
      );
      const availableGlobalDiscounts = get(globalDiscountsAtom).filter(
        (discount) =>
          canApplyDiscount(discount, {
            departmentId: action.payload.item.departmentId,
            itemSkuId: action.payload.itemSku.id,
          }),
      );

      const availablePromotions = get(promotionsAtom).filter((p) => {
        return (
          (p.discount.filterMode === "BY_DEPARTMENT" &&
            p.discount.departmentDiscounts
              ?.map((p) => p.departmentId)
              .includes(action.payload.item.departmentId)) ||
          (p.discount.filterMode === "BY_ITEM" &&
            p.discount.itemSkuDiscounts
              ?.map((p) => p.itemSkuId)
              .includes(action.payload.itemSku.id))
        );
      });

      const availableDefaultDiscountsByPercentage = uniqBy(
        [
          ...availableDiscounts,
          ...availablePromotions.map((p) => p.discount),
        ].filter((d) => d.type === "PERCENTAGE"),
        (d) => d.id,
      );

      const bestDiscount =
        maxBy(availableDefaultDiscountsByPercentage, (d) => Number(d.amount)) ??
        null;

      const itemSkuStock = action.payload.itemSku.itemSkuStock?.find(
        (stock) => stock.filialId === filial?.id,
      );

      return [
        ...cartEntries,
        {
          itemWithVariant: { ...action.payload },
          quantity:
            itemSkuStock && itemSkuStock?.quantity > 0
              ? 1
              : checkIsStoreFilial &&
                  itemSkuStock &&
                  itemSkuStock?.quantity <= 0
                ? 1
                : 0,
          //NOTE: We can only apply discounts to products with a price greater than a dollar.
          discount: action.payload.itemSku.price.gt(1)
            ? filial?.type === "SHOP"
              ? bestDiscount
              : null
            : null,
          availableDiscounts: uniqBy(
            [...availableDiscounts, ...availableGlobalDiscounts],
            (d) => d.id,
          ),
          availablePromotions,
        },
      ];
    }
  }

  if (action?.type === "remove") {
    set(lastCartEntryAddedIndexAtom, { index: -1 });
    return cartEntries.filter((_, i) => action.payload !== i);
  }

  if (action?.type === "setQty") {
    set(lastCartEntryAddedIndexAtom, { index: action.payload.index });
    return [
      ...cartEntries.slice(0, action.payload.index),
      {
        ...cartEntries[action.payload.index],
        quantity: action.payload.qty,
      },
      ...cartEntries.slice(action.payload.index + 1),
    ];
  }

  if (
    action?.type === "reloadDiscounts" ||
    action?.type === "reloadDiscountsWithOverride"
  ) {
    const discounts = get(discountsAtom);

    return cartEntries.map((entry) => {
      if (entry.itemWithVariant.itemSku.price.lte(1)) {
        return entry;
      }
      const availableDiscounts = discounts.filter((discount) =>
        canApplyDiscount(discount, {
          departmentId: entry.itemWithVariant.item.departmentId,
          itemSkuId: entry.itemWithVariant.itemSku.id,
        }),
      );
      const availableGlobalDiscounts = get(globalDiscountsAtom).filter(
        (discount) =>
          canApplyDiscount(discount, {
            departmentId: entry.itemWithVariant.item.departmentId,
            itemSkuId: entry.itemWithVariant.itemSku.id,
          }),
      );
      const availablePromotions = get(promotionsAtom).filter((p) => {
        return (
          (p.discount.filterMode === "BY_DEPARTMENT" &&
            p.discount.departmentDiscounts
              ?.map((p) => p.departmentId)
              .includes(entry.itemWithVariant.item.departmentId)) ||
          (p.discount.filterMode === "BY_ITEM" &&
            p.discount.itemSkuDiscounts
              ?.map((p) => p.itemSkuId)
              .includes(entry.itemWithVariant.itemSku.id))
        );
      });

      const availableDefaultDiscountsByPercentage = [
        ...availableDiscounts,
        ...availablePromotions.map((p) => p.discount),
      ].filter((d) => d.type === "PERCENTAGE");

      const bestDiscount =
        maxBy(availableDefaultDiscountsByPercentage, (d) => Number(d.amount)) ??
        null;

      return {
        ...entry,
        discount:
          filial?.type === "WAREHOUSE"
            ? null
            : action?.type === "reloadDiscountsWithOverride"
              ? bestDiscount
              : entry.discount
                ? entry.discount
                : bestDiscount,
        availableDiscounts: uniqBy(
          [...availableDiscounts, ...availableGlobalDiscounts],
          (d) => d.id,
        ),
        availablePromotions,
      };
    });
  }

  if (action?.type === "setDiscount") {
    return cartEntries.map((entry, i) => {
      if (i === action.payload.index) {
        return { ...entry, discount: action.payload.discount };
      }
      return entry;
    });
  }

  throw new Error("invalid action");
}

export const lastCartEntryAddedIndexAtom = atom({ index: -1 });

type ReturnedProduct = CartEntry & {
  orderRef: string;
  reason: "returned" | "damaged";
};
export const cartEntriesReturnedBaseAtom = atom<ReturnedProduct[]>([]);

export const cartEntriesReturnedAtom = atom((get) =>
  get(cartEntriesReturnedBaseAtom),
);

export const cartEntriesReturnedAddAtom = atom(
  null,
  (get, set, entries: ReturnedProduct[]) => {
    set(cartEntriesReturnedBaseAtom, [
      ...get(cartEntriesReturnedBaseAtom),
      ...entries,
    ]);
  },
);

export const cartEntriesReturnedRemoveAtom = atom(
  null,
  (get, set, index: number) => {
    set(cartEntriesReturnedBaseAtom, [
      ...get(cartEntriesReturnedBaseAtom).slice(0, index),
      ...get(cartEntriesReturnedBaseAtom).slice(index + 1),
    ]);
  },
);
