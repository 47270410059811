import { trpc } from "#/trpc"
import { imageSrcAtom, itemSkuIdAtom, markersAtom } from "./state"
import { useStore, useAtomValue } from "jotai/react"
import { Button } from "@mantine/core"
import { modals } from "@mantine/modals"

export function SaveButton() {
	const { mutate } = trpc.v2_5.itemSkuSlot.create.useMutation({
		onSuccess() {
			modals.close("slots-form-modal")
		},
	})
	const store = useStore()
	const markersAtoms = useAtomValue(markersAtom)
	const imageSrc = useAtomValue(imageSrcAtom)

	let itemSkuId = useAtomValue(itemSkuIdAtom)

	if (itemSkuId == null || !Number.isInteger(itemSkuId)) {
		itemSkuId = -1
	}

	function handleSave() {
		mutate({
			itemSkuId: itemSkuId!,
			image: imageSrc,
			slots: markersAtoms.map((markerAtom) => {
				const marker = store.get(markerAtom)

				return {
					x: marker.x,
					y: marker.y,
					radius: marker.radius,
				}
			}),
		})
	}

	return <Button onClick={handleSave}>Save</Button>
}
