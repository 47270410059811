import GTLoader from "#/components-ng/loader.js"
import { DisplayTable, RouterPrompt } from "#/components/index.js"
import { useAuth } from "#/context/AuthContext.js"
import { trpc } from "#/trpc.js"
import {
	HydrateAtoms,
	reportUserError,
	reportUserSuccess,
} from "#/util/index.js"
import { useZebraPrinter } from "#/util/label.js"
import { createInventoryAdjustmentColumns } from "../helper"
import { Footer, GeneralInformation, Table } from "./components/index.js"
import { productsAtom, stateAtom } from "./state.js"
import * as M from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { Provider, useAtomValue } from "jotai"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { MdDelete } from "react-icons/md/index.js"
import { useNavigate, useParams } from "react-router"
import { AdjustmentStatus } from "server"

export const AdjustInventoryEditForm = () => {
	const { id } = useParams()
	const [auth] = useAuth()

	const { data, isLoading } = trpc.adjustment.getById.useQuery({
		id: Number(id),
	})

	const products = React.useMemo(
		() =>
			data?.adjustmentItemSku?.map((p) => ({
				id: p.itemSku.id,
				sku: p.itemSku.sku,
				title: p.itemSku.title,
				cost: p.itemSku?.cost?.toNumber() ?? 0,
				availQty:
					p.itemSku.itemSkuStock.find(
						(s) => s.filialId === auth?.auth?.user?.filialId,
					)?.quantity ?? 0,
				newQty: p?.newStock ?? 0,
				diffQty:
					(p?.newStock ?? 0) -
					(p.itemSku.itemSkuStock.find(
						(s) => s.filialId === auth?.auth?.user?.filialId,
					)?.quantity ?? 0),
				department: p.itemSku.item.department.name,
				productSeason: p.itemSku.item?.season?.name ?? "",
				category: p.itemSku.item.category.name,
			})) ?? [],
		[auth?.auth?.user?.filialId, data?.adjustmentItemSku],
	)

	if (isLoading) {
		return (
			<M.LoadingOverlay
				visible={isLoading}
				loader={<GTLoader width={100} height={100} />}
			/>
		)
	}

	return (
		<Provider>
			<HydrateAtoms initialValues={[[stateAtom, { products }]]}>
				<AdjustInventoryFormInner
					defaultValues={{
						tagName: data?.tagName,
						reason: data?.adjustmentType,
						filial: data?.filialId?.toString(),
					}}
				/>
			</HydrateAtoms>
		</Provider>
	)
}

export const AdjustInventoryForm = () => {
	return (
		<Provider>
			<AdjustInventoryFormInner defaultValues={undefined} />
		</Provider>
	)
}

const AdjustInventoryFormInner = ({ defaultValues }) => {
	const { id } = useParams()
	const navigate = useNavigate()

	const [{ auth }] = useAuth()!
	const user = auth?.user

	const products = useAtomValue(productsAtom)
	const form = useForm({
		defaultValues,
	})

	const { mutate: createAdjustInventory, isLoading: isLoadingCreate } =
		trpc.adjustment.create.useMutation({
			onError(error) {
				reportUserError({
					title: "Failed to create adjustment",
					message: error.message,
				})
			},
			onSuccess() {
				reportUserSuccess({
					title: "Adjustment created successfully",
				})
				navigate("/inventory/adjust")
			},
		})

	const { mutate: updateAdjustInventory, isLoading: isLoadingUpdate } =
		trpc.adjustment.update.useMutation({
			onError(error) {
				reportUserError({
					title: "Failed to update adjustment",
					message: error.message,
				})
			},
			onSuccess() {
				reportUserSuccess({
					title: "Adjustment updated successfully",
				})
				navigate("/inventory/adjust")
			},
		})

	const { printer, loading: loadingPrinter } = useZebraPrinter()

	const [
		inventoryAdjustmentsOnHoldModalOpened,
		setInventoryAdjustmentsOnHoldModalOpened,
	] = React.useState(false)

	const { data: adjustInventoriesOnHoldData, refetch } =
		trpc.adjustment.getOnDraft.useQuery(undefined, {
			onError(error) {
				reportUserError({
					title: "Failed to get adjustments on hold",
					message: error.message,
				})
			},
		})

	const [submitted, setSubmitted] = React.useState(false)
	const handleSubmit = (status: AdjustmentStatus) => async (formValues) => {
		if (formValues.filial && user) {
			if (id) {
				setSubmitted(true)
				updateAdjustInventory({
					id: Number(id),
					input: {
						userId: user?.id,
						tagName: formValues.tagName,
						note: formValues.note ?? "",
						adjustmentType: formValues.reason,
						adjustmentStatus: status,
						adjustmentItemSku:
							products?.map((p) => ({
								itemSkuId: Number(p.id),
								oldStock: p?.availQty ?? 0,
								newStock: p?.newQty ?? 0,
								oldPrice: p?.cost ?? null,
							})) ?? [],
						filialId: Number(formValues?.filial),
					},
				})
			} else {
				if (
					status === "DRAFT" &&
					(adjustInventoriesOnHoldData?.length ?? 0) >= 3
				) {
					showNotification({
						title:
							"You can have a maximum of 3 inventory adjustments on hold. To continue, you must complete or delete an inventory adjustment.",
						message: (
							<M.Button
								color="darkGray.0"
								sx={(t) => ({ color: t.colors.gray[8] })}
								onClick={() => setInventoryAdjustmentsOnHoldModalOpened(true)}
							>
								Delete
							</M.Button>
						),
						color: "red",
						autoClose: 15000,
					})
					return
				}
				createAdjustInventory({
					userId: user?.id as number,
					tagName: formValues?.tagName,
					note: formValues?.note ?? "",
					adjustmentType: formValues?.reason,
					filialId: Number(formValues?.filial),
					adjustmentStatus: status,
					adjustmentItemSku:
						products?.map((p) => ({
							itemSkuId: Number(p.id),
							oldStock: p?.availQty ?? 0,
							newStock: p?.newQty ?? 0,
							oldPrice: p?.cost ?? null,
						})) ?? [],
				})
				setSubmitted(true)
			}
		}
	}

	const columns = createInventoryAdjustmentColumnsWithDelete({
		printer,
		loadingPrinter,
		refetchInventoryAdjustments: () => refetch(),
	})

	return (
		<M.Container size="xl">
			<form>
				<RouterPrompt
					when={!submitted}
					extraButtons={({ onConfirm, onCancel }) => (
						<M.Button
							color="darkGray.0"
							sx={(t) => ({ color: t.colors.gray[8] })}
							onClick={() => {
								form.handleSubmit(handleSubmit("DRAFT"))()
								// timeout of 10ms so that the function executes after `submitted` is updated
								setTimeout(() => {
									if (submitted) {
										onConfirm()
									} else {
										onCancel()
									}
								}, 10)
							}}
						>
							Put on hold
						</M.Button>
					)}
				/>
				<FormProvider {...form}>
					<M.Stack>
						<GeneralInformation />
						<M.Stack className="bg-white p-10">
							<Table />
							<Footer
								onSave={form.handleSubmit(handleSubmit("COMPLETE"))}
								onHold={form.handleSubmit(handleSubmit("DRAFT"))}
								loading={isLoadingCreate || isLoadingUpdate}
							/>
						</M.Stack>
					</M.Stack>
				</FormProvider>
			</form>
			<M.Modal
				size={1350}
				opened={inventoryAdjustmentsOnHoldModalOpened}
				onClose={() => setInventoryAdjustmentsOnHoldModalOpened(false)}
				title={<M.Title order={3}>Inventory Adjustment</M.Title>}
				zIndex={1200}
			>
				<DisplayTable
					columns={columns}
					data={adjustInventoriesOnHoldData ?? []}
				/>
			</M.Modal>
		</M.Container>
	)
}

const createInventoryAdjustmentColumnsWithDelete = ({
	printer,
	loadingPrinter,
	refetchInventoryAdjustments,
}) => [
	...createInventoryAdjustmentColumns({ printer, loadingPrinter }),
	{
		Header: "",
		id: "delete",
		Cell: ({ row: { original } }) => {
			const { mutate: deleteInventoryAdjustment } =
				trpc.adjustment.delete.useMutation({
					onError: (error) => {
						reportUserError({
							title: "Failed to delete inventory adjustment",
							message: error.message,
						})
					},
					onSuccess: () => {
						showNotification({
							title: "Inventory adjustment deleted",
							message: `Inventory adjustment "${original.tagName}" has been deleted successfully.`,
						})
					},
				})

			return (
				<M.ActionIcon
					onClick={async () => {
						try {
							await deleteInventoryAdjustment({
								id: Number(original.id),
							})
							refetchInventoryAdjustments()
						} catch (e) {
							showNotification({
								title: "There was an error",
								message: e.message ?? "",
								color: "red",
							})
						}
					}}
				>
					<MdDelete />
				</M.ActionIcon>
			)
		},
	},
]
