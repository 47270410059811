import {
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from "mantine-react-table"
import { Form } from "./types"
import { trpc } from "#/trpc"
import * as M from "@mantine/core"
import {
	CopyOutlineIcon,
	EditIcon,
	FileTrayFullOutlineIcon,
} from '#/components-ng'
import { Link } from "react-router-dom"
import { S, HStack } from "#/s"
import { MenuIcon } from '#/components'
import { reportUserSuccess } from '#/util'

export function FormTable() {
	const { data } = trpc.v2_5.form.getAll.useQuery({})

	const table = useMantineReactTable({
		columns: columns,
		data: data ?? [],
		enableTopToolbar: false,
		enablePagination: false,
		enableSorting: false,
		enableFilters: false,
	})

	return (
		<div>
			<MantineReactTable table={table} />
		</div>
	)
}

function ActiveSwitch({ uuid, active }: { uuid: string; active: boolean }) {
	const tctx = trpc.useContext()

	const { mutate, isLoading } = trpc.v2_5.form.updateOne.useMutation({
		onSuccess: () => {
			tctx.v2_5.form.invalidate()
		},
	})

	return (
		<M.Switch
			checked={active}
			readOnly={isLoading}
			onChange={() => mutate({ uuid, active: !active })}
		/>
	)
}

const columns: MRT_ColumnDef<Form>[] = [
	{
		id: "actions",
		header: "Actions",
		size: 10,
		Cell: ({ row }) => {
			const uuid = row.original.uuid
			const active = row.original.active

			return (
				<HStack>
					<M.Menu withinPortal>
						<M.Menu.Target>
							<M.ActionIcon>
								<MenuIcon />
							</M.ActionIcon>
						</M.Menu.Target>
						<M.Menu.Dropdown>
							<M.Menu.Item
								component={Link}
								to={`edit/${uuid}`}
								icon={<EditIcon />}
							>
								Edit
							</M.Menu.Item>
							<M.Menu.Item
								component={Link}
								to={`submissions/${uuid}`}
								icon={<FileTrayFullOutlineIcon />}
							>
								Submissions
							</M.Menu.Item>
							<M.Menu.Item
								icon={<CopyOutlineIcon />}
								onClick={() => {
									const url = `${import.meta.env.SECRET_SHOP_BASE_URL}/survey/${uuid}`
									navigator.clipboard.writeText(url)
									reportUserSuccess({
										title: 'Link copied!',
									})
								}}
							>
								Copy public link
							</M.Menu.Item>
						</M.Menu.Dropdown>
					</M.Menu>

					<ActiveSwitch uuid={uuid} active={active} />
				</HStack>
			)
		},
	},
	{
		header: "Title",
		accessorKey: "title.en",
		Cell: ({ cell }) => {
			const isActive = cell.row.original.active

			return (
				<S.p c={isActive ? "slate.800" : "slate.500"}>
					{cell.getValue() as string}
				</S.p>
			)
		},
	},
]
