import { AppsFilledIcon } from "#/components-ng"
import Card from "#/components/Card/index.js"
import CategoryGridLayout from "#/components/CategoryGridLayout"
import { CustomersIcon } from "#/components/icons"
import * as M from "@mantine/core"

export const DashboardModule = () => {
	return (
		<M.Stack spacing={20}>
			<CategoryGridLayout category="Global">
				<M.Group spacing={50}>
					<Card
						icon={<AppsFilledIcon fontSize={24} />}
						text="General Dashboard"
						path="general-dashboard"
						module="dashboard.generalDashboard"
					/>
					<Card
						icon={<CustomersIcon />}
						text="Dashboard By Employee"
						path="dashboard-by-employee"
						module="dashboard.dashboardByEmployee"
					/>
				</M.Group>
			</CategoryGridLayout>
		</M.Stack>
	)
}
