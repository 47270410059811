import { Button, SpinnerIcon } from "#/components-ng";
import { RouterOutputs, trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { ImageCard } from "./Image";
import { ImageUpload } from "./UploadSection";
import { VideoCard } from "./Video";
import {
  UseS3UploadError,
  UseS3UploadObject,
  UseS3UploadResult,
} from "./types";
import { openConfirmModal } from "@mantine/modals";
import { v4 as uuidv4 } from "uuid";

type VariantGalleryProps = RouterOutputs["item"]["getItemSkus"][number];

export function VariantGallery(props: VariantGalleryProps) {
  const imagesName = props.images;
  const defaultVideo = props.defaultVideo;
  const sku = props.sku;
  const ctx = trpc.useContext();

  const [defaultImage, setDefaultImage] = useState<string | null>(
    props.defaultImage ?? null,
  );

  const [defaultSlotImage, setDefaultSlotImage] = useState<string | null>(
    props?.defaultSlotImage ?? null,
  )

  const [images, setImages] = useState<
    { url: string; uuid: string; isLoading: boolean }[]
  >(
    () =>
      imagesName?.map((url) => ({ url, uuid: uuidv4(), isLoading: false })) ??
      [],
  );

  const [video, setVideo] = useState<
    { url: string; uuid: string; isLoading: boolean } | undefined
  >(() =>
    defaultVideo
      ? {
          url: defaultVideo,
          uuid: uuidv4(),
          isLoading: false,
        }
      : undefined,
  );

  const handleS3UploadSuccess = (result: UseS3UploadResult) => {
    // verify that the upload was a video type
    if (result.fileName.endsWith(".mp4") || result.fileName.endsWith(".mov")) {
      setVideo({ url: result.url, uuid: result.uuid, isLoading: false });
      return;
    } else {
      setImages((prev) =>
        prev.map((v) => {
          if (v.uuid === result.uuid) {
            return { ...v, url: result.url, isLoading: false };
          }
          return v;
        }),
      );
    }
  };

  const handleS3UploadError = (res: UseS3UploadError) => {
    setImages((prev) => prev.filter((i) => i.uuid !== res.uuid));
    reportUserError({
      title: "Failed to upload image",
      message: res.error.message,
    });
  };

  const handleS3UploadingStart = (upload: UseS3UploadObject) => {
    if (upload.fileName.endsWith(".mp4") || upload.fileName.endsWith(".mov")) {
      setVideo({
        url: upload.url!,
        uuid: upload.uuid,
        isLoading: true,
      });
    } else {
      setImages((prev) => [
        ...prev,
        { url: upload.url!, uuid: upload.uuid, isLoading: true },
      ]);
    }
  };

  const handleRemoveImage = (uuid: string, url: string) => {
    openConfirmModal({
      title: "Are you sure to delete this image?",
      labels: {
        cancel: "Cancel",
        confirm: "Delete",
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        setImages((prev) => prev.filter((v) => v.uuid !== uuid));
        if (url === defaultImage) {
          setDefaultImage(null);
        }
        if (url === defaultSlotImage) {
          setDefaultSlotImage(null);
        }
      },
    });
  };

  const handleRemoveVideo = () => {
    openConfirmModal({
      title: "Are you sure to delete this video?",
      labels: {
        cancel: "Cancel",
        confirm: "Delete",
      },
      confirmProps: { color: "red" },
      onConfirm: () => {
        setVideo(undefined);
      },
    });
  };

  const handleSetDefaultImage = (uuid: string) => {
    setDefaultImage(
      images.find((image) => image.uuid === uuid)?.url ?? defaultImage,
    );
  };

  const handleSetDefaultSlotImage = (uuid: string) => {
    setDefaultSlotImage(
      images.find((image) => image.uuid === uuid)?.url ?? defaultSlotImage,
    );
  }

  const { mutate } = trpc.itemSku.pos.updateGallery.useMutation({
    onError(err) {
      reportUserError({
        title: "Failed to update variant",
        message: err.message,
      });
    },
    onSuccess() {
      reportUserSuccess({
        title: `Gallery for SKU ${sku} updated successfully`,
      });
      ctx.itemSku.invalidate();
      ctx.item.invalidate();
    },
  });

  const handleSubmit = () => {
    mutate({
      id: props.id,
      input: {
        images: images.map((image) => image.url),
        defaultImage: defaultImage ?? "",
        defaultVideo: video?.url ?? "",
        defaultSlotImage: defaultSlotImage ?? null,
      },
    });
  };

  return (
    <section>
      <h3 className="mb-2">SKU {sku}</h3>
      <div className="flex flex-col gap-y-4">
        <ImageUpload
          onS3UploadSuccess={handleS3UploadSuccess}
          onS3UploadingStart={handleS3UploadingStart}
          onS3UploadError={handleS3UploadError}
          hiddenVisually={true}
        />
        <div className="grid grid-cols-3 gap-4">
          {images?.map((image) => {
            const isDefault = image.url === defaultImage ? true : false;
            const isDefaultSlotImage = image.url === defaultSlotImage ? true : false;

            return (
              <ImageCard
                key={image.uuid}
                src={image.url!}
                isLoading={image.isLoading}
                deleteImage={() => handleRemoveImage(image.uuid, image.url)}
                setDefaultImage={() => handleSetDefaultImage(image.uuid)}
                isDefault={isDefault}
                setDefaultImageSlot={() => handleSetDefaultSlotImage(image.uuid)}
                isDefaultSlot={isDefaultSlotImage}
              />
            );
          })}
        </div>
        <div className="grid grid-cols-3 gap-4">
          {video && (
            <VideoCard
              src={video.url}
              isLoading={video.isLoading}
              deleteVideo={handleRemoveVideo}
            />
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <SubmitButton
          onSubmit={handleSubmit}
          defaultImage={defaultImage}
          images={images}
          video={video}
          sku={sku}
        />
      </div>
    </section>
  );
}

interface SubmitButtonProps {
  onSubmit: () => void;
  images: { url: string; uuid: string; isLoading: boolean }[];
  video: { url: string; uuid: string; isLoading: boolean } | null | undefined;
  defaultImage: string | null;
  sku: number;
}
function SubmitButton({
  onSubmit,
  images,
  video,
  defaultImage,
}: SubmitButtonProps) {
  let areImagesLoading = false;
  let isVideoLoading = false;

  for (const image of images) {
    if (image.url.startsWith("blob:")) {
      areImagesLoading = true;
      break;
    }
  }

  if (video?.url.startsWith("blob:")) {
    isVideoLoading = true;
  }

  if (defaultImage?.startsWith("blob:")) {
    areImagesLoading = true;
  }

  return (
    <Button
      variant="primary"
      size="lg"
      onClick={onSubmit}
      className="mt-8 px-12"
      disabled={areImagesLoading || isVideoLoading}
    >
      Save gallery {areImagesLoading && <SpinnerIcon />}
    </Button>
  );
}
