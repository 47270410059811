import { trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import { useNavigate } from "react-router"
import { FormForm } from "../components/form"
import { Schema } from "../components/form/types"

export function FormCreateFormPage() {
	const navigate = useNavigate()

	const { mutate } = trpc.v2_5.form.create.useMutation({
		onSuccess() {
			navigate("/ecommerce-crm/forms")
			reportUserSuccess({
				title: "Form created successfully,",
			})
		},
		onError(data) {
			reportUserError({
				title: "Failed to create form",
				message: data.message,
			})
		},
	})

	function handleSubmit(values: Schema) {
		mutate({
			...values,
			discountId: values.discount?.id ?? null,
		})
	}

	return <FormForm mode="create" onSubmit={handleSubmit} />
}
