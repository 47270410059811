import { PrintLabelsModal } from "#/scenes/Inventory/ItemList/PrintLabelsModal";
import { Button } from "@gt/ui";
import { Provider } from "jotai";
import Print from "~icons/ion/print-outline";
import { useEffect, useState } from "react";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import type { Order } from "../ecommerce-orders/use-order";
import { sortBy } from "lodash";

export type ItemSkuLabel = (Order["orderItemSku"][number]["itemSku"] & {
	qty?: number | null;
	printQty?: number | null
});

type PrintAllLabelsProps = {
	itemSkus: ItemSkuLabel[];
	type: "ecommerce-order" | "pending-cart" | "order" | "inventory-tranfer";
};

export const PrintAllLabels = ({ itemSkus, type }: PrintAllLabelsProps) => {
	const [browserPrint] = useState(() => new ZebraBrowserPrintWrapper());
	const [isLoadingPrinters, setIsLoadingPrinters] = useState<boolean>(false);
	const [productsToPrint, setProductsToPrint] = useState<ItemSkuLabel[]>([]);
	const [openModalToPrintLabel, setOpenModalToPrintLabel] = useState(false);

	useEffect(() => {
		async function setDefaultPrinter() {
			setIsLoadingPrinters(true);
			const availablePrinters = await browserPrint.getAvailablePrinters();
			browserPrint.setPrinter(availablePrinters[0]);
			setIsLoadingPrinters(false);
		}

		setDefaultPrinter();
	}, [browserPrint]);

	const handlePrintAllLabels = () => {
		const products_ =
			itemSkus
				?.filter((itemSku) => {
					if(type === "inventory-tranfer") {
						return true;
					}

					if (
						(["PCS", "PC"].includes(itemSku?.presentationType) &&
							itemSku?.presentationValue === 6) ||
						itemSku?.presentationType === "GR"
					) {
						return false;
					}

					return true;
				})
				?.map((item) => ({
					...item,
					printQty: item?.printQty ?? 1,
					storeLocation:
						item?.itemSkuStock?.find((is) => is.filialId === 2)
							?.storeLocation ?? item?.itemSkuStock?.[0]?.storeLocation,
				})) ?? [];

		const products = sortBy(products_, ["storeLocation"]);

		setProductsToPrint(products);
		setOpenModalToPrintLabel(true);
	};

	return (
		<>
			<Provider>
				<PrintLabelsModal
					printer={browserPrint}
					isLoadingPrinters={isLoadingPrinters}
					defaultProduct={null}
					setSelectedItemSkuToPrint={() => {}}
					opened={openModalToPrintLabel}
					setOpened={setOpenModalToPrintLabel}
					defaultProducts={productsToPrint as any[]}
					setDefaultProducts={setProductsToPrint}
				/>
			</Provider>
			<Button variant="secondary" onClick={handlePrintAllLabels}>
				<Print />
				Print labels
			</Button>
		</>
	);
};
