import GTLoader from "#/components-ng/loader.js";
import { trpc } from "#/trpc.js";
import { CardLayout, CardTable } from "../components/index.js";
import Decimal from "decimal.js";

interface VendorCardProps {
  vendorId?: number | null;
  vendorAlu?: string | null;
  vendorCost?: Decimal | null;
  cost?: Decimal | null;
}

export const VendorCard = ({
  vendorAlu,
  vendorCost,
  vendorId,
  cost,
}: VendorCardProps) => {
  const { data, isLoading } = trpc.vendor.getById.useQuery(
    {
      id: vendorId ?? 0,
    },
    {
      cacheTime: 0,
      enabled: !!vendorId,
      select: (data) => {
        return { company: data!.company };
      },
    }
  );

  return (
    <CardLayout title="Vendors">
      {isLoading ? (
        <GTLoader width={100} height={100} />
      ) : (
        <CardTable
          headers={["Company", "Vendor ALU", "Vendor price", "Cost"]}
          rows={[
            [
              data?.company ?? "",
              vendorAlu ?? "",
              vendorCost?.toNumber()?.toFixed(2) ?? "",
              cost?.toNumber()?.toFixed(2) ?? "",
            ],
          ]}
        />
      )}
    </CardLayout>
  );
};
