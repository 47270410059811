import { styled as s } from "#/css/jsx"
import { useAtom } from "jotai/react"
import { markersAtom, MarkerState } from "./state"
import { PrimitiveAtom } from "jotai/vanilla"

export interface MarkerIndexProps {
	atom: PrimitiveAtom<MarkerState>
	index: number
	readOnly?: boolean | null
}

export function MarkerIndex(props: MarkerIndexProps) {
	const [markers, setMarkers] = useAtom(markersAtom)

	function moveToIndex(newIndex: number) {
		const marker = markers[props.index]
		const newMarkers = [...markers]
		newMarkers.splice(props.index, 1)
		newMarkers.splice(newIndex, 0, marker)
		setMarkers(newMarkers)
	}

	function handleDecrement() {
		moveToIndex(props.index - 1)
	}

	function handleIncrement() {
		moveToIndex(props.index + 1)
	}

	return (
		<s.div
			display="flex"
			pos="absolute"
			left="50%"
			top="-35px"
			translateX="-50%"
			translate="auto"
			zIndex={1}
			cursor="pointer"
		>
			{props?.readOnly && (
				<s.button onClick={handleDecrement} bg="slate.500/10" px={2}>
					-
				</s.button>
			)}
			<s.div bg="slate.500/10" px={3}>
				{props.index + 1}
			</s.div>
			{props?.readOnly && (
				<s.button onClick={handleIncrement} bg="slate.500/10" px={2}>
					+
				</s.button>
			)}
		</s.div>
	)
}
