import { PrimitiveAtom } from "jotai/vanilla"
import { imageRefAtom, MarkerState } from "./state"
import { useAtom, useAtomValue } from "jotai/react"
import { styled as s } from "#/css/jsx"

export function MarkerResizer(props: { atom: PrimitiveAtom<MarkerState> }) {
	const [state, setState] = useAtom(props.atom)
	const imageRef = useAtomValue(imageRefAtom)
	const imageWidth = imageRef?.ref.width ?? 1
	const imageHeight = imageRef?.ref.height ?? 1
	const radiusPx = imageWidth * state.radius
	const [isPressed, setIsPressed] = useState(false)
	const centerPos = {
		x: (imageRef?.ref.getBoundingClientRect().left ?? 1) + imageWidth * state.x,
		y: (imageRef?.ref.getBoundingClientRect().top ?? 1) + imageHeight * state.y,
	}

	useEffect(() => {
		function handler(e: MouseEvent) {
			if (!isPressed) return
			if (e.button !== 0) return
			setIsPressed(false)
		}

		window.addEventListener("mouseup", handler)
		return () => window.removeEventListener("mouseup", handler)
	}, [isPressed])

	useEffect(() => {
		function handler(e: MouseEvent) {
			if (!isPressed) return

			setState((prev) => ({
				...prev,
				radius:
					Math.sqrt(
						(centerPos.x - e.clientX) ** 2 + (centerPos.y - e.clientY) ** 2,
					) / imageWidth,
			}))
		}
		window.addEventListener("mousemove", handler)
		return () => window.removeEventListener("mousemove", handler)
	}, [isPressed, radiusPx, centerPos.x, centerPos.y])

	function handleMouseDown(e: React.MouseEvent) {
		if (e.button !== 0) return
		e.preventDefault()
		setIsPressed(true)
	}

	return (
		<s.div
			rounded="full"
			pos="absolute"
			cursor="nwse-resize"
			style={{
				width: "calc(100% + 12px)",
				height: "calc(100% + 12px)",
				left: "-6px",
				top: "-6px",
			}}
			onMouseDown={handleMouseDown}
		>
			<s.div />
		</s.div>
	)
}
