import { ImageMarkerEditor } from "./image-marker-editor"
import { Button } from "@mantine/core"
import { modals } from "@mantine/modals"
import { useParams } from "react-router"

export function SlotsForm(props: { itemSkuId: number }) {
	return <ImageMarkerEditor itemSkuId={props.itemSkuId} />
}

export function OpenSlotsFormButton() {
	const params = useParams()

	function openModal() {
		modals.open({
			modalId: "slots-form-modal",
			children: <SlotsForm itemSkuId={Number(params.itemSkuId)} />,
		})
	}

	if (params.itemSkuId == null) {
		return null
	}

	return (
		<Button color="gray.1" c="gray.8" onClick={openModal}>
			Edit slots
		</Button>
	)
}
