import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { FilialStockCard, OnHoldCard } from "./FilialStock/index.js";
import { HistoryCard, PricingHistoryCard, StockCard } from "./History/index.js";
import {
  InformationCard,
  NoteCard,
  TagsCard,
  VariantsCard,
  VendorCard,
} from "./ItemInformation/index.js";
import * as M from "@mantine/core";
import Decimal from "decimal.js";
import { useMemo } from "react";

interface DetailsDrawerProps {
  opened: boolean;
  onClose: () => void;
  itemSkuId: number;
  sku: string;
  title: string;
}

export const DetailsDrawer = ({
  itemSkuId,
  onClose,
  opened,
  sku,
  title,
}: DetailsDrawerProps) => {
  const [{ auth }] = useAuth();

  const { data, isLoading } = trpc.itemSku.getById.useQuery(
    {
      id: itemSkuId,
    },
    {
      cacheTime: 0,
      enabled: !!itemSkuId,
    }
  );

  const { data: filial } = trpc.filial.getById.useQuery(
    {
      id: auth?.user?.filialId ?? 0,
    },
    {
      enabled: !!auth?.user?.filialId,
      cacheTime: 0,
    }
  );

  const locations = useMemo(() => {
    const itemSkuStock = data?.itemSkuStock.find(
      (stock) => stock.filialId === auth?.user?.filialId
    );
    return itemSkuStock ? itemSkuStock : null;
  }, [auth?.user?.filialId, data?.itemSkuStock]);

  return (
    <M.Drawer
      opened={opened}
      onClose={onClose}
      size="xl"
      position="right"
      padding={"30px"}
      closeOnEscape={false}
    >
      <M.Title order={5} align="left" mb="15px" className="font-medium">
        SKU {sku} - {title}
      </M.Title>
      {auth?.user?.filialId && (
        <div className="flex flex-col gap-1">
          <M.Divider mb="5px" />
          <M.Text size="sm" align="left" className="font-medium">
            {filial?.name}
          </M.Text>
          <M.Text size="sm" align="left" className="font-medium">
            Store: {locations?.storeLocation ?? ""}
          </M.Text>
          <M.Text size="sm" align="left" className="font-medium">
            Warehouse: {locations?.warehouseLocation ?? ""}
          </M.Text>
          <M.Divider mb="5px" />
        </div>
      )}

      <M.Tabs defaultValue="stock">
        <M.Tabs.List grow position="center">
          <M.Tabs.Tab value="stock">Filial Stock</M.Tabs.Tab>
          <M.Tabs.Tab value="history">History</M.Tabs.Tab>
          <M.Tabs.Tab value="itemInformation">Item Information</M.Tabs.Tab>
        </M.Tabs.List>

        <M.Tabs.Panel value="itemInformation">
          <InformationCard isLoading={isLoading} data={data as any} />
          <TagsCard itemId={data?.itemId ?? 0} />
          <VariantsCard itemId={data?.itemId ?? 0} itemSkuId={itemSkuId} />
          <VendorCard
            vendorId={data?.vendorId ?? 0}
            vendorAlu={data?.vendorAlu ?? ""}
            vendorCost={data?.vendorCost ?? new Decimal(0)}
            cost={data?.cost ?? new Decimal(0)}
          />
          <NoteCard note={data?.note ?? ""} isLoading={isLoading} />
        </M.Tabs.Panel>

        <M.Tabs.Panel value="history" mt="xl">
          <StockCard itemSkuId={itemSkuId} />
          <HistoryCard itemSkuId={itemSkuId} />
          <PricingHistoryCard itemSkuId={itemSkuId} />
        </M.Tabs.Panel>

        <M.Tabs.Panel value="stock" mt="xl">
          <StockCard itemSkuId={itemSkuId} />
          <OnHoldCard itemSkuId={itemSkuId} />
          <FilialStockCard itemSkuId={itemSkuId} />
        </M.Tabs.Panel>
      </M.Tabs>
    </M.Drawer>
  );
};
