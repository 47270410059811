import { styled } from "#/css/jsx"
import { css } from "#/css/css"
import { trpc } from "#/trpc"
import { useMemo, useState } from "react"
import { C, type FormValues, generateCustomersReportSchema } from "./types"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useFormContext } from "react-hook-form"
import { LoadingOverlay, Select } from "@mantine/core"
import { Button } from "@gt/ui"
import { LinearScaleChart, MultiSelect } from "#/components-ng"
import GTLoader from "#/components-ng/loader"
import { generateRandomHexColor } from "#/util/color"
import { reportUserError } from "#/util"

type OptionMultiselect = {
	id: number | string
	name: string
}

export const CustomersReport = () => {
	const form = C.useForm({
		resolver: zodResolver(generateCustomersReportSchema),
		shouldUnregister: false,
		defaultValues: {
			filter: {
				by: "all",
				origins: [],
			},
			years: [new Date().getFullYear()],
		},
	})

	const {
		data: report,
		mutate: generateReport,
		isLoading,
	} = trpc.v2_5.report.getCustomersReport.useMutation()

	const originsOptions = useMemo(
		() => [
			{ id: "POS", name: "Store" },
			{ id: "ECOMMERCE", name: "Ecommerce" },
		],
		[],
	)

	const data = useMemo(() => {
		if (report) {
			return (
				report?.map((d) => {
					const color = generateRandomHexColor()

					const origin = originsOptions?.find((f) => f.id === d.origin)?.name

					return {
						label: `${d.year.toString()}${origin ? ` - ${origin}` : ""}`,
						backgroundColor: color,
						borderColor: color,
						data: d.months.map((m) => m.totalCustomers ?? 0),
					}
				}) ?? []
			)
		}
		return []
	}, [report])

	const handleSubmit = (values: FormValues) => {
		if (values.filter.by === "origin" && values.filter.origins.length === 0) {
			return reportUserError({
				title: "Error",
				message: "Please select at least one origin.",
			})
		}

		generateReport({
			filter: {
				by: values.filter.by,
				origins: values.filter.origins,
			},
			years: values.years,
		})
	}

	const filterBy = form.watch("filter.by")

	return (
		<styled.div gap="1rem" display="flex" flexDirection="column">
			<styled.h2>General New Customers Report</styled.h2>

			<FormProvider {...form}>
				<form
					onSubmit={(e) => {
						e.stopPropagation()
						form.handleSubmit(handleSubmit)(e)
					}}
				>
					<styled.div display="flex" gap="1rem">
						<C.M
							as={Select}
							label="From"
							name="filter.by"
							data={[
								{ label: "All", value: "all" },
								{ label: "Origin", value: "origin" },
							]}
							required
						/>
						{filterBy === "origin" && (
							<OriginMultiselect
								label="Select Origins"
								options={originsOptions}
								placeholder="Select origins..."
							/>
						)}

						<YearsMultiselect />

						<Button
							type="submit"
							isLoading={isLoading}
							className={css({
								marginTop: "1.25rem",
								paddingX: "3rem",
							})}
						>
							Generate
						</Button>
					</styled.div>
				</form>
			</FormProvider>
			{isLoading && (
				<LoadingOverlay
					visible={isLoading}
					loader={<GTLoader width={100} height={100} />}
				/>
			)}
			{data && !isLoading && (
				<>
					<styled.hr />
					<LinearScaleChart
						datasets={data}
						labels={months}
						includeTitle={false}
						includeLegend={true}
					/>
				</>
			)}
		</styled.div>
	)
}

const YearsMultiselect = () => {
	const form = useFormContext()
	const years = form.watch("years")

	const [selectedYears, setSelectedYears] = useState<OptionMultiselect[]>(
		years.map((year) => ({
			id: year,
			name: year.toString(),
		})) ?? [],
	)

	const yearOptions = useMemo(() => {
		const currentYear = new Date().getFullYear()
		const years: OptionMultiselect[] = []
		for (let i = 2022; i <= currentYear; i++) {
			years.push({
				id: i,
				name: i.toString(),
			})
		}
		return years
	}, [])

	const handleYearsChange = (years: OptionMultiselect[]) => {
		form.setValue(
			"years",
			years.map((year) => year.id),
		)
		setSelectedYears(years)
	}

	return (
		<MultiSelect
			label="Years"
			data={yearOptions}
			entryId={(e) => e.id.toString()}
			entryLabel={(e) => e.name}
			onChange={handleYearsChange}
			placeholder="Select years..."
			value={selectedYears}
		/>
	)
}

interface OriginMultiselectProps {
	label: string
	options: OptionMultiselect[]
	placeholder?: string
}

const OriginMultiselect = (props: OriginMultiselectProps) => {
	const form = useFormContext()
	const origins = form.watch("filter.origins")
	const filterBy = form.watch("filter.by")

	const [selectedOrigins, setSelectedOrigins] = useState<OptionMultiselect[]>(
		origins.map((origin) => ({ id: origin, name: origin })) ?? [],
	)

	useEffect(() => {
		if (filterBy === "all") {
			form.setValue("filter.origins", [])
			setSelectedOrigins([])
		}
	}, [filterBy])

	const handleEntityFilialChange = (origins: OptionMultiselect[]) => {
		form.setValue(
			"filter.origins",
			origins.map((id) => id.id),
		)
		setSelectedOrigins(origins)
	}

	return (
		<MultiSelect
			label={props.label}
			data={props.options}
			entryId={(e) => e.id.toString()}
			entryLabel={(e) => e.name}
			onChange={handleEntityFilialChange}
			placeholder={props.placeholder}
			value={selectedOrigins}
		/>
	)
}

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]
