import { SVGProps } from "react";

export { default as AlertCircleOutline } from "~icons/ion/alert-circle-outline";
export { default as ChevronBackOutline } from "~icons/ion/chevron-back-outline";
export { default as ChevronForwardOutline } from "~icons/ion/chevron-forward-outline";
export { default as ChevronDownOutline } from "~icons/ion/chevron-down-outline";
export { default as CloseOutline } from "~icons/ion/close-outline";
export { default as CheckmarkOutline } from "~icons/ion/checkmark-outline";
export { default as CloudUploadOutline } from "~icons/ion/cloud-upload-outline";
export { default as CreateOutline } from "~icons/ion/create-outline";
export { default as EllipsisHorizontal } from "~icons/ion/ellipsis-horizontal";
export { default as EllipsisHorizontalOutline } from "~icons/ion/ellipsis-horizontal-outline";
export { default as Eye } from "~icons/ion/eye";
export { default as EyeOutline } from "~icons/ion/eye-outline";
export { default as FilterOutline } from "~icons/ion/filter-outline";
export { default as SearchOutline } from "~icons/ion/search-outline";
export { default as MailOutline } from "~icons/ion/mail-outline";
export { default as QuizIcon } from "~icons/ion/list-outline";
export { default as CallOutline } from "~icons/ion/call-outline";
export { default as CardOutlineIcon } from "~icons/ion/card-outline";
export { default as TimerOutlineIcon } from "~icons/ion/timer-outline";
export { default as CopyOutlineIcon } from '~icons/ion/copy-outline'

export { default as PaypalCustomIcon } from "~icons/custom/paypal";
export { default as ZelleCustomIcon } from "~icons/custom/zelle";
export { default as BankTransferCustomIcon } from "~icons/custom/bank-transfer";
export { default as CashPaymentMethodCustomIcon } from "~icons/custom/cash-payment-method";
export { default as CreditDebitPaymentMethodCustomIcon } from "~icons/custom/credit-debit-payment-method";
export { default as DiscountPaymentMethodCustomIcon } from "~icons/custom/discount-payment-method";
export { default as OthersPaymentMethodCustomIcon } from "~icons/custom/others-payment-method";
export { default as UserCreditsPaymentMethodCustomIcon } from "~icons/custom/user-credits-payment-method";
export { default as NoteBlackCustomIcon } from "~icons/custom/noteBlank";
export { default as ReceiptCustomIcon } from "~icons/custom/receipt";
export { default as CatalogCustomIcon } from "~icons/custom/catalog";
export { default as TagCustomIcon } from "~icons/custom/tagIcon";
export { default as InventoryTransferCustomIcon } from "~icons/custom/arrows-clockwise";
export { default as CustomAttributeCustomIcon } from "~icons/custom/pencilCircle";
export { default as TagPromotionCustomIcon } from "~icons/custom/tag";
export { default as CheckCircleIcon } from "~icons/custom/checkCircle";
export { default as XCircleIcon } from "~icons/custom/xCircle";
export { default as PrintAndEmailIcon } from "~icons/custom/print-email";
export { default as PencilCustomIcon } from "~icons/custom/pencil";
export { default as CircleWavyWarningIcon } from "~icons/custom/circleWavyWarning";
export { default as EditIcon } from "~icons/ion/edit";
export { default as FilterOutlineIcon } from "~icons/ion/filter-outline";
export { default as FolderOpenOutlineIcon } from "~icons/ion/folder-open-outline";
export { default as TrashFilledIcon } from "~icons/ion/trash";
export { default as StarFilledIcon } from "~icons/ion/star";
export { default as PersonOutlineIcon } from "~icons/ion/person-outline";
export { default as MenuOutlineIcon } from "~icons/ion/menu-outline";
export { default as MinusFilledIcon } from "~icons/ion/minus";
export { default as PlusFilledIcon } from "~icons/ion/plus";
export { default as CalendarFilledIcon } from "~icons/ion/calendar";
export { default as HomeFilledIcon } from "~icons/ion/home";
export { default as HomeOutlineIcon } from "~icons/ion/home-outline";
export { default as DollarFilledIcon } from "~icons/material-symbols/attach-money";
export { default as AppsFilledIcon } from "~icons/material-symbols/apps";
export { default as LineChartFilledIcon } from "~icons/material-symbols/show-chart";
export { default as SettingsFilledIcon } from "~icons/material-symbols/settings";
export { default as LogoutFilledIcon } from "~icons/material-symbols/logout";
export { default as PhoneOutlineIcon } from "~icons/ion/call-outline";
export { default as HomeWorkOutlineIcon } from "~icons/material-symbols/home-work-outline";
export { default as ActionsMenuIcon } from "~icons/ion/ellipsis-horizontal";
export { default as CartIcon } from "~icons/ion/cart-outline";
export { default as TicketOutlineIcon } from "~icons/ion/ticket-outline";
export { default as DocumentTextOutlineIcon } from "~icons/ion/document-text-outline";
export { default as HelpOutlineIcon } from "~icons/ion/help-outline";
export { default as ListBoxIcon } from "~icons/ion/ios-list-box";
export { default as FileTrayFullOutlineIcon } from "~icons/ion/file-tray-full-outline";

export { default as EcommerceOrdersCard } from "~icons/custom/ecommerce-orders-card";
export { default as PickupIcon } from "~icons/custom/pickup";
export { default as DeliveryUsIcon } from "~icons/custom/delivery-us";
export { default as InternationalDeliveryIcon } from "~icons/custom/intl-delivery";
export { default as PrinterIcon } from "~icons/custom/printer";
export { default as MoneyIcon } from "~icons/custom/money";
export { default as FileIcon } from "~icons/custom/file";
export { default as TruckIcon } from "~icons/custom/truck";
export { default as CurrencyCircleDollarIcon } from "~icons/custom/currency-circle-dollar";
export { default as ArrowCircleIcon } from "~icons/custom/arrow";
export { default as BannerIcon } from "~icons/custom/banner";
export { default as ShoppingCartIcon } from "~icons/custom/shoppingCart";
export { default as RouletteIcon } from "~icons/custom/roulette";
export { default as NotePadIcon } from "~icons/custom/note-pad";
export { default as TruckThinIcon } from "~icons/custom/truck-thin";

export function SpinnerIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" viewBox="0 0 24 24" height="2em" width="2em" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 19a7 7 0 100-14 7 7 0 000 14zm0 3c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        clipRule="evenodd"
        opacity={0.2}
      />
      <path
        fill="currentColor"
        d="M2 12C2 6.477 6.477 2 12 2v3a7 7 0 00-7 7H2z"
      />
    </svg>
  );
}
