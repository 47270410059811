import { Controller } from "react-hook-form"
import { Select } from "#/components-ng"
import { trpc } from "#/trpc"

export type DiscountFieldProps = {
	disabled: boolean
	mode: "COUPON" | "LOYALTY"
}

export const DiscountField = (props: DiscountFieldProps) => {
	const { data } = trpc.v2_5.discounts.getReferences.useQuery({
		mode: props.mode,
	})

	return (
		<Controller
			name="discountReference"
			render={(c) => (
				<Select<string | null>
					data={data ?? []}
					label="Discount"
					entryId={(d) => d}
					entryLabel={(d) => d}
					searchable
					className="justify-self-start"
					disabled={props.disabled}
					{...c.field}
				/>
			)}
		/>
	)
}
