import { Select } from "#/components-ng"
import { createFormContext } from "#/components-ng/form-context"
import { DiscountField } from "#/components@v2_5/form/discount-field"
import { RouterOutputs, trpc } from "#/trpc"
import { getDiscountLabel } from "#/util/discounts"
import { zodResolver } from "@hookform/resolvers/zod"
import * as M from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { openModal } from "@mantine/modals"
import dayjs from "dayjs"
import { Fragment } from "react"
import {
	FormProvider,
	UseFormReturn,
	useFieldArray,
	useWatch,
} from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"

const FormSchema = z.object({
	discountReference: z.string(),
	activeDateRange: z.tuple([z.date(), z.date()]),
	couponType: z.enum(["GLOBAL", "REWARD"]),
	// map from discount id to object
	rates: z.array(
		z.object({
			couponId: z.number().nullable(),
			// ^ Only null when `mode` is `create`
			discountId: z.number(),
			label: z.string(),
			couponCode: z.string(),
		}),
	),
})

export type FormSchema = z.infer<typeof FormSchema>

type FormInProgressSchema = {
	discountReference: string | null
	couponType: "GLOBAL" | "REWARD"
	activeDateRange: [Date | null, Date | null]
	rates: {
		discountId: number
		label: string
		couponCode: string
	}[]
}

const { C, Controller, useForm, useFormContext } =
	createFormContext<FormInProgressSchema>()

interface CouponsFormProps {
	mode: "create" | "update"
	defaultValues?: FormInProgressSchema
	onSubmit: (values: FormSchema) => void
}

export const CouponsForm = (props: CouponsFormProps) => {
	const form = useForm({
		defaultValues: props.defaultValues ?? {
			couponType: "GLOBAL",
			discountReference: null,
			activeDateRange: [null, null],
			rates: [],
		},
		resolver: zodResolver(FormSchema),
	})

	const handleSubmit = form.handleSubmit(props.onSubmit)

	useDefaultDiscount({ form })

	return (
		<div>
			<FormProvider {...form}>
				<form className="grid gap-y-6" onSubmit={handleSubmit}>
					<DiscountField mode="COUPON" disabled={props.mode === "update"} />
					<CouponTypeField />
					<DateRangeField />
					<Rates mode={props.mode} />
					<M.Button type="submit" className="w-28 justify-self-start">
						Save
					</M.Button>
				</form>
			</FormProvider>
		</div>
	)
}

interface UseDefaultDiscountProps {
	form: UseFormReturn<FormInProgressSchema>
}

function useDefaultDiscount({ form }: UseDefaultDiscountProps) {
	const [searchParams] = useSearchParams()
	const discountReference = searchParams.get("discount")

	const formSetValue = form.setValue

	useEffect(() => {
		if (discountReference != null) {
			formSetValue("discountReference", discountReference)
		}
	}, [discountReference, formSetValue])
}

function CouponTypeField() {
	return (
		<Controller
			name="couponType"
			render={(c) => {
				return (
					<Select
						label="Type"
						required
						data={["GLOBAL", "REWARD"]}
						entryId={(d) => d}
						entryLabel={(d) =>
							`${d.substring(0, 1).toUpperCase()}${d.substring(1).toLowerCase()}`
						}
						className="justify-self-start"
						{...c.field}
					/>
				)
			}}
		/>
	)
}

export const DateRangeField: React.FC = () => {
	return (
		<C
			$as={DatePickerInput}
			type="range"
			name="activeDateRange"
			label="Active date range"
			className="justify-self-start"
			numberOfColumns={2}
		/>
	)
}

type RatesProps = {
	mode: "create" | "update"
}

export const Rates = (props: RatesProps) => {
	const form = useFormContext()
	const discountReference = useWatch({
		name: "discountReference",
		control: form.control,
	})

	const rates = useFieldArray({
		name: "rates",
		control: form.control,
	})

	const ratesReplace = rates.replace

	const { data } = trpc.discount.getDiscountsByReference.useQuery(
		{
			reference: discountReference!,
			//         ^ query is disabled if null
			//           so we know it's not null here
		},
		{
			enabled: props.mode === "create" && discountReference != null,
			refetchOnWindowFocus: false,
			refetchInterval: false,
		},
	)

	const { setValue: formSetValue } = useForm()

	// Update rates when discountReference changes
	useEffect(() => {
		if (data != null) {
			const newRates: FormSchema["rates"] = []
			for (const discount of data) {
				const label = getDiscountLabel(discount)

				newRates.push({
					couponId: null,
					discountId: discount.id,
					label,
					couponCode: "",
				})
			}

			ratesReplace(newRates)
		}
	}, [data, formSetValue, ratesReplace])

	return (
		<div className="grid gap-y-2">
			<h3 className={cx(rates.fields.length === 0 && "hidden")}>
				Coupon codes
			</h3>
			{rates.fields.map((rate, i) => (
				<div key={rate.id} className="flex gap-x-4">
					<M.TextInput
						value={rate.label}
						label="Rate"
						readOnly
						classNames={{ input: "bg-slate-50" }}
					/>
					<Controller
						name={`rates.${i}.couponCode`}
						render={(ctrl) => (
							<M.TextInput
								label="Coupon code"
								{...ctrl.field}
								error={ctrl.fieldState.error?.message}
								onChange={(e) => {
									ctrl.field.onChange(e.currentTarget.value.toUpperCase())
								}}
							/>
						)}
					/>
				</div>
			))}
		</div>
	)
}

export function openConflictingCouponsModal(
	props: ConflictingCouponsModalContentProps,
) {
	openModal({
		children: <ConflictingCouponsModalContent {...props} />,
		title: <h3>Conflicting coupons</h3>,
	})
}

type ConflictingCoupon = ReturnType<
	RouterOutputs["v2_5"]["coupons"]["create"]["_unsafeUnwrapErr"]
>[0]

interface ConflictingCouponsModalContentProps {
	conflictingCoupons: Array<ConflictingCoupon>
}

export function ConflictingCouponsModalContent(
	props: ConflictingCouponsModalContentProps,
) {
	return (
		<div>
			<p className="text-sm text-slate-500">
				Deactivate all conflicting coupons or change your coupons to not
				conflict with existing coupons by changing the coupon code or active
				date range.
			</p>
			<hr className="my-5" />
			<div className="grid grid-cols-[auto_auto]">
				{props.conflictingCoupons.map((conflictingCoupon, i) => (
					<Fragment key={conflictingCoupon.existingCouponId}>
						<ConflictingCouponEntry conflictingCoupon={conflictingCoupon} />
						<hr
							className={tx(
								"col-span-full my-4",
								i === props.conflictingCoupons.length - 1 && "hidden",
							)}
						/>
					</Fragment>
				))}
			</div>
		</div>
	)
}

interface ConflictingCouponEntryProps {
	conflictingCoupon: ConflictingCoupon
}

function ConflictingCouponEntry(props: ConflictingCouponEntryProps) {
	const tctx = trpc.useContext()
	const { data, mutate, isLoading } =
		trpc.v2_5.coupons.toggleActive.useMutation({
			onSuccess() {
				tctx.v2_5.coupons.invalidate()
			},
		})

	const isInactive = data != null && !data.active

	function toggleActive() {
		mutate({
			filter: {
				by: "id",
				id: props.conflictingCoupon.existingCouponId,
			},
		})
	}

	return (
		<>
			<div className="grid gap-y-2">
				<span className="font-medium">
					{props.conflictingCoupon.couponCode}
				</span>
				<span className="text-sm text-slate-500">
					Existing:{" "}
					{dayjs(props.conflictingCoupon.existingCouponDateRange[0]).format(
						"MM/DD/YYYY",
					)}{" "}
					-{" "}
					{dayjs(props.conflictingCoupon.existingCouponDateRange[1]).format(
						"MM/DD/YYYY",
					)}
				</span>
				<span className="text-sm text-slate-500">
					New:{" "}
					{dayjs(props.conflictingCoupon.existingCouponDateRange[0]).format(
						"MM/DD/YYYY",
					)}{" "}
					-{" "}
					{dayjs(props.conflictingCoupon.existingCouponDateRange[1]).format(
						"MM/DD/YYYY",
					)}
				</span>
			</div>
			<M.Button
				color={isInactive ? "gray.1" : "red.4"}
				c={isInactive ? "gray.7" : undefined}
				onClick={toggleActive}
				loading={isLoading}
				loaderPosition="center"
			>
				{isInactive ? "Activate" : "Deactivate"}
			</M.Button>
		</>
	)
}
