import GTLoader from "#/components-ng/loader.js";
import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { AtomsDevtools, useWindowFocus } from "#/util/index.js";
import { CartTable } from "./CartTable.js";
import { CheckoutAndPrint } from "./CheckoutAndPrint.js";
import { CostBreakdown } from "./CostBreakdown.js";
import { CustomerPanel } from "./CustomerPanel.js";
import { PaymentMethods } from "./PaymentMethods.js";
import { PutOnHoldButton } from "./PutOnHold.js";
import { SearchHeader } from "./SearchHeader.js";
import { WaitingList } from "./WaitingList.js";
import { AnswerSurveyButton } from "./answer-survey/answer-survey-button.js";
import { cartIdAtom } from "./state/cart.js";
import { filialTaxAtom } from "./state/cost-breakdown.js";
import { filialAtom } from "./state/filial.js";
import { idempotencyKeyAtom } from "./state/idempotency-key.js";
import {
	cartEntriesAtom,
	cartEntriesBaseAtom,
	cartEntriesReturnedBaseAtom,
	cashierAtom,
	customerAtom,
	helperAtom,
	receiptNameAtom,
	salesAssociateAtom,
	associatedAtom,
	seasonalDiscountAtom,
} from "./state/index.js"
import { noteAtom } from "./state/note.js";
import { reportAtom } from "./state/reporting.js";
import { trpcAtom } from "./state/trpc.js";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import { hideNotification, showNotification } from "@mantine/notifications";
import Decimal from "decimal.js";
import { Provider, useAtom, useSetAtom } from "jotai";
import { nanoid } from "nanoid";
import React from "react";
import BarcodeReader from "react-barcode-reader";
import { useNavigate, useParams } from "react-router";

export function MakeASale() {
  return (
    <Provider>
      <AtomsDevtools name="Make a sale">
        <MakeASaleInner />
      </AtomsDevtools>
    </Provider>
  );
}

const WithInitialValues = ({
  children,
  customer,
  cartEntries,
  cartEntriesReturned,
  ...rest
}) => {
  const setCartId = useSetAtom(cartIdAtom);
  const setIdempotencyKey = useSetAtom(idempotencyKeyAtom);
  const setCustomer = useSetAtom(customerAtom);
  const setCartEntries = useSetAtom(cartEntriesBaseAtom);
  const setCartEntriesReducer = useSetAtom(cartEntriesAtom);
  const setCartEntriesReturned = useSetAtom(cartEntriesReturnedBaseAtom);
  const setNote = useSetAtom(noteAtom);
  const setReceiptName = useSetAtom(receiptNameAtom);
  const setFilialTax = useSetAtom(filialTaxAtom);
  const setSeasonalDiscount = useSetAtom(seasonalDiscountAtom)

  React.useEffect(() => {
    setIdempotencyKey(rest.cartId == null ? nanoid() : null);
    setCartId(rest.cartId);
    setCustomer(customer);
    setCartEntries(cartEntries);
    setCartEntriesReturned(cartEntriesReturned);
    setCartEntriesReducer({ type: "reloadDiscounts" });
    setNote(rest.note ?? "");
    setReceiptName(customer?.company ? "companyName" : "customerName");
    setFilialTax(rest.tax);
    setSeasonalDiscount(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export const MakeASaleWithOrder = () => {
  const params = useParams() as any;
  const id = Number(params.id);
  const orderQuery = trpc.order.getById.useQuery(
    {
      id,
    },
    {
      cacheTime: 0,
    },
  );

  if (!orderQuery.data) {
    return (
      <M.Center py={32}>
        <M.Loader size="lg" />
      </M.Center>
    );
  }

  return (
    <Provider>
      <WithInitialValues
        customer={orderQuery.data.customer}
        cartEntries={orderQuery.data.orderItemSku.map((c) => ({
          itemWithVariant: {
            item: c.itemSku.item,
            itemSku: c.itemSku,
          },
          availableDiscounts: [],
          availablePromotions: [],
          quantity: c.quantity,
        }))}
        cartEntriesReturned={[]}
        note={""}
        tax={orderQuery.data.filial.taxRate}
      >
        <MakeASaleInner />
      </WithInitialValues>
    </Provider>
  );
};

export const MakeASaleWithCart = () => {
  const params = useParams();
  const id = Number(params.id);

  const cartQuery = trpc.cart.getById.useQuery(
    {
      id,
    },
    {
      cacheTime: 0,
    },
  );

  if (!cartQuery.data || !cartQuery.isFetchedAfterMount) {
    return (
      <M.LoadingOverlay
        visible={true}
        loader={<GTLoader width={100} height={100} />}
      />
    );
  }

  return (
    <Provider>
      <WithInitialValues
        cartId={cartQuery.data.id}
        customer={cartQuery.data.customer}
        cartEntries={cartQuery.data.cartItemSku.map((c) => ({
          itemWithVariant: {
            item: c.itemSku.item,
            itemSku: c.itemSku,
          },
          availableDiscounts: [],
          availablePromotions: [],
          quantity: c.quantity,
          cartItemSkuId: c.id,
          discount: c.discount,
        }))}
        cartEntriesReturned={[]}
        note={cartQuery.data.note ?? ""}
        tax={cartQuery.data.filial.taxRate}
      >
        <MakeASaleInner />
      </WithInitialValues>
    </Provider>
  );
};

function MakeASaleInner() {
  const windowsFocused = useWindowFocus();
  const [{ auth }] = useAuth();
  const user = auth?.user;
  const filialId = user?.filialId;
  const trpcClient = trpc.useContext().client;
  const [trpcClientAtomValue, setTrpClientAtomValue] = useAtom(trpcAtom);
  if (trpcClientAtomValue == null) {
    setTrpClientAtomValue({ client: trpcClient });
  }
  const navigate = useNavigate();
  const setAssociated = useSetAtom(associatedAtom);
  const cartEntriesDispatch = useSetAtom(cartEntriesAtom);
  const [report, setErrorReportingFunc] = useAtom(reportAtom);
  const setFilial = useSetAtom(filialAtom);
  const setFilialTax = useSetAtom(filialTaxAtom);

  const setSalesAssociate = useSetAtom(salesAssociateAtom);
  const setCashier = useSetAtom(cashierAtom);
  const setHelper = useSetAtom(helperAtom);
  const [idempotencyKey, setIdempotencyKey] = useAtom(idempotencyKeyAtom);

  if (idempotencyKey == null) {
    setIdempotencyKey(nanoid());
  }

  trpc.filial.getById.useQuery(
    {
      id: auth?.user?.filialId ?? 0,
    },
    {
      enabled: !!auth?.user?.filialId,
      cacheTime: 0,
      onSuccess(data) {
        if (data) {
          setFilial({
            id: data.id,
            name: data.name,
            type: data.type,
          });
          setFilialTax(new Decimal(data.taxRate.toNumber()));
        }
      },
    },
  );

  trpc.user.getOneById.useQuery(
    {
      id: auth?.user.id ?? 0,
    },
    {
      cacheTime: 0,
      onSuccess(data) {
        if (data) {
          setSalesAssociate(data);
          setCashier(data);
          setHelper(data);
        }
      },
    },
  );

  React.useEffect(() => {
    setErrorReportingFunc({
      error: (msg) =>
        showNotification({
          id: msg.id,
          title: msg.title ?? "",
          message: msg.description ?? "",
          color: "red",
        }),
      warning: (msg) =>
        showNotification({
          id: msg.id,
          title: msg.title ?? "",
          message: msg.description ?? "",
          color: "yellow",
        }),
      success: (msg) =>
        showNotification({
          id: msg.id,
          title: msg.title ?? "",
          message: msg.description ?? "",
          color: "green",
        }),
      errorConfirmation: (data) => {
        const id = nanoid();
        showNotification({
          id,
          title: data.title,
          message: (
            <M.Group>
              <M.Text>{data.description}</M.Text>
              <M.Button
                color="gray.0"
                sx={(t) => ({ color: t.colors.gray[8] })}
                onClick={() => hideNotification(id)}
              >
                Ok
              </M.Button>
            </M.Group>
          ),
          color: "red",
          autoClose: false,
          withCloseButton: false,
        });
      },
    });
  }, [setErrorReportingFunc]);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setAssociated({ ...user!, filialId: filialId! });
  }, [user, setAssociated, filialId]);

  const handleScanProduct = React.useCallback(
    (dataPreprocessed) => {
      const dataSkuNumber = parseInt(dataPreprocessed);
      if (!dataSkuNumber) {
        return;
      }
      (async () => {
        try {
          const foundProduct = await trpcClient.itemSku.findFirstBySku.query({
            sku: dataSkuNumber,
          });
          if (foundProduct) {
            cartEntriesDispatch({
              type: "add",
              payload: {
                item: foundProduct.item,
                itemSku: foundProduct,
              },
            });
          }
        } catch (e) {
          report.error({
            title: "Failed to scan product",
            description: e.message,
          });
        }
      })();
    },
    [cartEntriesDispatch, report, trpcClient.itemSku.findFirstBySku],
  );

  return (
    <>
      <BarcodeReader
        onScan={handleScanProduct}
        minLength={4}
        avgTimeByChar={25}
      />
      <CustomerPanel />
      <div className="mx-auto mt-2 w-auto max-w-[1300px] rounded-md bg-white px-5 py-4">
        <SearchHeader />
        <CartTable />
        <C.HStack
          spacing={8}
          w="100%"
          align="start"
          mt={6}
          divider={<C.StackDivider />}
        >
          <C.VStack spacing={6} maxW="50%">
            <PaymentMethods />
            <WaitingList />
            <C.Divider />
            <C.SimpleGrid
              columns={3}
              columnGap={4}
              rowGap={2}
              className="w-full"
            >
              <PutOnHoldButton />
              <M.Button variant="light" color="darkGray" disabled>
                Pick up
              </M.Button>
              <M.Button
                variant="light"
                color="darkGray"
                onClick={() => navigate("/sales")}
              >
                Cancel
              </M.Button>
              <CheckoutAndPrint />
              <AnswerSurveyButton />
            </C.SimpleGrid>
          </C.VStack>
          <CostBreakdown flex={1} />
        </C.HStack>
        {!windowsFocused && (
          <M.Dialog
            opened={true}
            sx={(t) => ({ borderTop: `solid 3px ${t.colors.yellow[4]}` })}
          >
            <M.Text>Window must be focused for the scanner to work.</M.Text>
          </M.Dialog>
        )}
      </div>
    </>
  );
}
