import { RouterInputs } from "#/trpc"

export type DiscountType = NonNullable<
	RouterInputs["v2_5"]["discounts"]["getByPage"]["filters"]
>["type"][number]

export const DiscountTypeFilter: Record<DiscountType, string> = {
	PERCENTAGE: "Percentage",
	AMOUNT: "Amount",
	ORDER_SUBTOTAL_AMOUNT: "Order subtotal amount",
	ORDER_SUBTOTAL_PERCENTAGE: "Order subtotal percentage",
	SHIPPING_PERCENTAGE: "Shipping percentage",
} as const

export type DiscountTypeFilterKey = keyof typeof DiscountTypeFilter
export type DiscountTypeFilter =
	(typeof DiscountTypeFilter)[DiscountTypeFilterKey]

export type DiscountMode = NonNullable<
	RouterInputs["v2_5"]["discounts"]["getByPage"]["filters"]
>["discountMode"][number]

export const DiscountModeFilter: Record<DiscountMode, string> = {
	LOYALTY: "Loyalty",
	PROMOTION: "Promotion",
	COUPON: "Coupon",
	SEASONAL: "Seasonal",
	SURVEY_REWARD: "Survey reward",
} as const

export type DiscountModeFilterKey = keyof typeof DiscountModeFilter
export type DiscountModeFilter =
	(typeof DiscountModeFilter)[DiscountModeFilterKey]
