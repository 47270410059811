import { PrimitiveAtom } from "jotai/vanilla"
import { MarkerResizer } from "./marker-resizer"
import { MarkerState, imageRefAtom } from "./state"
import { useAtom, useAtomValue } from "jotai/react"
import { styled as s } from "#/css/jsx"
import { MarkerRemover } from "./marker-remover"
import { MarkerIndex } from "./marker-index"

export interface MarkerProps {
	atom: PrimitiveAtom<MarkerState>
	index: number
	readOnly?: boolean | null
	slotImage?: string | null
}

export function Marker(props: MarkerProps) {
	const imageRef = useAtomValue(imageRefAtom)
	const imageWidth = imageRef?.ref.width ?? 0
	const ref = useRef<HTMLDivElement>(null)
	const [state, setState] = useAtom(props.atom)
	const [isPressed, setIsPressed] = useState(false)
	const [deltaPos, setDeltaPos] = useState<{ x: number; y: number }>({
		x: 0,
		y: 0,
	})

	const radiusPx = imageWidth * state.radius

	useEffect(() => {
		function handler(e: MouseEvent) {
			if (!isPressed) return
			if (e.button !== 0) return
			setIsPressed(false)
		}

		window.addEventListener("mouseup", handler)
		return () => window.removeEventListener("mouseup", handler)
	}, [isPressed])

	useEffect(() => {
		function handler(e: MouseEvent) {
			if (!isPressed) return
			const imageLeft = imageRef?.ref.getBoundingClientRect().left ?? 2
			const imageTop = imageRef?.ref.getBoundingClientRect().top ?? 2
			const imageWidth = imageRef?.ref.width ?? 0
			const imageHeight = imageRef?.ref.height ?? 0

			let x = (e.clientX - imageLeft) / imageWidth
			x += deltaPos.x
			let y = (e.clientY - imageTop) / imageHeight
			y += deltaPos.y

			if (x < 0) {
				x = 0
			} else if (x > 1) {
				x = 1
			}

			if (y < 0) {
				y = 0
			} else if (y > 1) {
				y = 1
			}

			setState((prev) => ({ ...prev, x, y }))
		}

		window.addEventListener("mousemove", handler)
		return () => window.removeEventListener("mousemove", handler)
	}, [isPressed, deltaPos.x, deltaPos.y])

	function handleMouseDown(e: React.MouseEvent) {
		if (props?.readOnly) return

		if (e.button !== 0) return
		e.preventDefault()

		setIsPressed(true)

		const imageBb = imageRef?.ref.getBoundingClientRect()
		const imageLeft = imageBb?.left ?? 1
		const imageTop = imageBb?.top ?? 1
		const imageWidth = imageBb?.width ?? 1
		const imageHeight = imageBb?.height ?? 1
		setDeltaPos({
			x: state.x - (e.clientX - imageLeft) / imageWidth,
			y: state.y - (e.clientY - imageTop) / imageHeight,
		})
	}

	return (
		<s.div
			pos="absolute"
			style={{
				width: `${radiusPx * 2}px`,
				height: `${radiusPx * 2}px`,
				left: `calc(${state.x * 100}% - ${radiusPx}px)`,
				top: `calc(${state.y * 100}% - ${radiusPx}px)`,
			}}
		>
			<MarkerIndex atom={props.atom} index={props.index} />
			{!props.readOnly && (
				<>
					<MarkerRemover atom={props.atom} />
					<MarkerResizer atom={props.atom} />
				</>
			)}
			<s.div
				ref={ref}
				rounded="full"
				border="1px solid token(colors.red.500)"
				width="100%"
				height="100%"
				pos="absolute"
				onMouseDown={handleMouseDown}
			>
				{props.readOnly && (
					<s.img
						src={props?.slotImage ?? ""}
						style={{
							width: `${radiusPx * 2}px`,
							height: `${radiusPx * 2}px`,
						}}
					/>
				)}
			</s.div>
		</s.div>
	)
}
