import { RouterOutputs } from "#/trpc.js"
import { cartEntriesAtom } from "./cart-entries.js"
import { atom } from "jotai/vanilla"
import type { Coupon, Discount, DiscountWithDepartments } from "server"
import { UserSeasonalDiscount } from "./types.js"
import { subtotalAtom } from "./cost-breakdown.js"
import { reportUserError } from "#/util/index.js"

const discountsBaseAtom = atom<DiscountWithDepartments[]>([])
const globalDiscountsBaseAtom = atom<DiscountWithDepartments[]>([])
type Promotion = RouterOutputs["promotion"]["getManyActive"][0]
const promotionsBaseAtom = atom<Promotion[]>([])

export const discountsAtom = atom(
	(get) => get(discountsBaseAtom),
	(_get, set, newDiscounts: DiscountWithDepartments[]) => {
		set(discountsBaseAtom, newDiscounts)
		set(cartEntriesAtom, {
			type: "reloadDiscounts",
		})
	},
)

export const globalDiscountsAtom = atom(
	(get) => get(globalDiscountsBaseAtom),
	(_get, set, newDiscounts: DiscountWithDepartments[]) => {
		set(globalDiscountsBaseAtom, newDiscounts)
		set(cartEntriesAtom, {
			type: "reloadDiscounts",
		})
	},
)

export const promotionsAtom = atom(
	(get) => get(promotionsBaseAtom),
	(_get, set, newPromotions: Promotion[]) => {
		set(promotionsBaseAtom, newPromotions)
		set(cartEntriesAtom, {
			type: "reloadDiscounts",
		})
	},
)

const seasonalDiscountBaseAtom = atom<UserSeasonalDiscount | null>(null)
export const seasonalDiscountAtom = atom(
	(get) => {
		const currentUserDiscount = get(seasonalDiscountBaseAtom)

		if (currentUserDiscount == null) {
			return null
		}

		const orderSubtotal = get(subtotalAtom)
		if (orderSubtotal.lt(currentUserDiscount.discount.minPurchase ?? 0)) {
			return null
		}

		return currentUserDiscount
	},
	(get, set, newUserDiscount: UserSeasonalDiscount | null) => {
		const orderSubtotal = get(subtotalAtom)

		if (
			newUserDiscount != null &&
			orderSubtotal.lt(newUserDiscount.discount.minPurchase ?? 0)
		) {
			reportUserError({
				title: "Discount not applicable",
				message: `Order does not meet min. purchase requirement of $${newUserDiscount.discount.minPurchase?.toFixed(2)}`,
			})

			return
		}

		if (newUserDiscount != null) {
			set(couponBaseAtom, null)
		}

		set(seasonalDiscountBaseAtom, newUserDiscount)
	},
)

export const couponBaseAtom = atom<(Coupon & { discount: Discount }) | null>(
	null,
)

export const couponAtom = atom(
	(get) => get(couponBaseAtom),
	(get, set, newCoupon: (Coupon & { discount: Discount }) | null) => {
		if (newCoupon != null) {
			// set(seasonalDiscountAtom, null)
		}

		set(couponBaseAtom, newCoupon)
	},
)
