import { ShippingCost } from "./ShippingCost.js";
import { paymentMethodsMap } from "./payment-methods-map.js";
import { customerAtom, seasonalDiscountAtom } from "./state/index.js";
import {
  amountDueAtom,
  cashPaidOutAtom,
  subtotalAtom,
  taxAtom,
  orderTotalAtom,
  subtotalTaxAtom,
} from "./state/cost-breakdown.js";
import {
  cardPaymentMethodsAtom,
  cashPaymentMethodAtom,
  giftPaymentMethodAtom,
  userCreditsPaymentMethodAtom,
} from "./state/payment-methods.js";
import { Payment } from "./state/types.js";
import * as C from "@chakra-ui/react";
import { AccordionProps } from "@chakra-ui/react";
import * as M from "@mantine/core";
import Decimal from "decimal.js";
import { useAtomValue } from "jotai";
import React from "react";
import GiftIcon from "~icons/ic/baseline-card-giftcard";
import { RouterOutputs, trpc } from "#/trpc.js";
import { getDiscountLabel } from "#/util/discounts.js";
import { css } from "#/css/css";
import { styled } from "#/css/jsx";
import { token } from "#/css/tokens";
import { uniqBy } from "lodash";
import { Select } from "#/components-ng/select.js";
import { SeasonalDiscountsGoals } from "./seasonal-discounts.js";
import { CouponField } from "./coupon-field.js"
import { S } from "#/s.js"

export function CostBreakdown(props: AccordionProps) {
  const customer = useAtomValue(customerAtom);
  const cashPaymentMethod = useAtomValue(cashPaymentMethodAtom);
  const cardPaymentMethods = useAtomValue(cardPaymentMethodsAtom);
  const giftPaymentMethod = useAtomValue(giftPaymentMethodAtom);
  const userCreditsPaymentMethod = useAtomValue(userCreditsPaymentMethodAtom);
  const subtotal = useAtomValue(subtotalAtom);
  const subtotalTax = useAtomValue(subtotalTaxAtom);
  const total = useAtomValue(orderTotalAtom);
  const tax = useAtomValue(taxAtom);
  const amountDue = useAtomValue(amountDueAtom);
  const cashPaidOut = useAtomValue(cashPaidOutAtom);

  const paymentMethods = React.useMemo(
    () =>
      [
        {
          type: "CASH",
          paidIn: cashPaymentMethod,
          paidOut: new Decimal(0),
        },
        ...cardPaymentMethods,
      ] as Payment[],
    [cardPaymentMethods, cashPaymentMethod],
  );

  const { data: seasonalDiscounts } =
    trpc.v2_5.discounts.getSeasonalDiscounts.useQuery(
      {
        filter: {
          onlyEligible: {
            customerId: customer?.id ?? 0,
          },
        },
      },
      {
        enabled: customer != null,
      },
    );

  return (
			<C.Accordion allowToggle {...props}>
				<C.AccordionItem borderTop="none">
					<C.AccordionButton
						bg="green.400"
						color="white"
						borderRadius="md"
						_hover={{ bg: "green.500" }}
					>
						<C.HStack w="100%" justify="space-between">
							<C.Text fontWeight="600">Total</C.Text>
							<C.Text fontWeight="600">${total.toFixed(2)}</C.Text>
						</C.HStack>
						<C.AccordionIcon ml={4} />
					</C.AccordionButton>
					<C.AccordionPanel>
						<C.SimpleGrid
							columns={2}
							rowGap={2}
							w="100%"
							px={3}
							py={1}
							color="gray.500"
						>
							<C.Text>Subtotal</C.Text>
							<C.Text textAlign="end" fontWeight="bold">
								${subtotal.toFixed(2)}
							</C.Text>
							<C.Text>Tax {tax.displayValue}</C.Text>
							<C.Text textAlign="end" fontWeight="bold">
								{customer && customer.taxable
									? `$${subtotalTax.toFixed(2)}`
									: `N/A`}
							</C.Text>
							<ShippingCost />
							<M.Divider color="gray.2" my={12} sx={{ gridColumn: "span 2" }} />
							{paymentMethods
								.filter((pm) => pm.paidIn.gt(0))
								.map((pm, index) => (
									<React.Fragment key={index}>
										<C.HStack>
											<C.Icon
												as={paymentMethodsMap[pm.type].icon}
												fontSize="1.25em"
											/>
											<C.Text>{paymentMethodsMap[pm.type].text}</C.Text>
										</C.HStack>
										<C.Text textAlign="end" fontWeight="bold">
											${pm.paidIn.sub(pm.paidOut).toFixed(2)}
										</C.Text>
									</React.Fragment>
								))}
							{userCreditsPaymentMethod.gt(0) && (
								<>
									<C.HStack>
										<C.Icon as={GiftIcon} fontSize="1.25em" />
										<C.Text>Applied credits</C.Text>
									</C.HStack>
									<C.Text textAlign="end" fontWeight="bold">
										${userCreditsPaymentMethod.toFixed(2)}
									</C.Text>
								</>
							)}
							{giftPaymentMethod.amount.gt(0) && (
								<>
									<C.HStack>
										<C.Icon as={GiftIcon} fontSize="1.25em" />
										<C.Text>Applied discount</C.Text>
									</C.HStack>
									<C.Text textAlign="end" fontWeight="bold">
										{giftPaymentMethod.type === "Fixed"
											? `$${giftPaymentMethod.amount.toFixed(2)}`
											: `${giftPaymentMethod.amount.toFixed(2)}%`}
									</C.Text>
								</>
							)}
							<M.Divider
								color="gray.2"
								my={12}
								className={css({
									gridColumn: "span 2",
									display:
										paymentMethods.length > 0 &&
										userCreditsPaymentMethod.gt(0) &&
										giftPaymentMethod.amount.gt(0)
											? "block"
											: "none",
								})}
							/>
							<C.Text
								fontWeight="bold"
								color={amountDue.gt("0.001") ? "red.500" : undefined}
							>
								Amount due
							</C.Text>
							<C.Text
								textAlign="end"
								fontWeight="bold"
								color={amountDue.gt("0.001") ? "red.500" : undefined}
							>
								${amountDue.toFixed(2)}
							</C.Text>
							<C.Text fontWeight="bold" gridColumn="span 2">
								Change
							</C.Text>
							{paymentMethods
								.filter((pm) => pm.paidOut.gt(0))
								.map((pm) => (
									<React.Fragment key={pm.type}>
										<C.HStack>
											<C.Icon
												as={paymentMethodsMap[pm.type].icon}
												fontSize="1.25em"
											/>
											<C.Text>{paymentMethodsMap[pm.type].text}</C.Text>
										</C.HStack>
										<C.Text textAlign="end" fontWeight="bold">
											${pm.paidIn.sub(pm.paidOut).toFixed(2)}
										</C.Text>
									</React.Fragment>
								))}
							<C.HStack>
								<C.Icon as={paymentMethodsMap["CASH"].icon} fontSize="1.25em" />
								<C.Text>{paymentMethodsMap["CASH"].text}</C.Text>
							</C.HStack>
							<C.Text textAlign="end" fontWeight="bold">
								${cashPaidOut.toFixed(2)}
							</C.Text>
							<M.Divider
								color="gray.2"
								my={12}
								className={css({
									gridColumn: "span 2",
								})}
							/>
							<S.div gridColumn="span 2">
								<CouponField />
							</S.div>
							<M.Divider
								color="gray.2"
								my={12}
								className={css({
									gridColumn: "span 2",
								})}
							/>
							<RedeemDiscount />
							<M.Divider
								color="gray.2"
								my={12}
								className={css({
									gridColumn: "span 2",
								})}
							/>
							<styled.div gridColumn="span 2">
								<M.Text
									color={token("colors.slate.500")}
									fw={token("fontWeights.medium")}
									mb={token("spacing.3")}
								>
									Seasonal discounts
								</M.Text>
								<SeasonalDiscountsGoals
									seasonalDiscounts={seasonalDiscounts ?? []}
								/>
							</styled.div>
						</C.SimpleGrid>
					</C.AccordionPanel>
				</C.AccordionItem>
			</C.Accordion>
		)
}

function RedeemDiscount() {
  const customer = useAtomValue(customerAtom);
  const [seasonalDiscount, setSeasonalDiscount] = useAtom(seasonalDiscountAtom);

  const { data: userDiscounts } =
    trpc.v2_5.discounts.getUserSeasonalDiscounts.useQuery(
      {
        customerId: customer?.id ?? 0,
      },
      {
        enabled: customer != null,
      },
    );

  return (
    <div className={css({ gridColumn: "span 2" })}>
      <M.Text fw={token("fontWeights.medium")}>Apply seasonal discount</M.Text>
      <Select
        data={userDiscounts ?? []}
        entryId={(d) => d.id.toString()}
        entryLabel={(d) =>
          `${d.redeemCode} | ${d.discount.reference} | ${getDiscountLabel(d.discount)} | Min. $${d.discount.minPurchase?.toFixed(2)}`
        }
        searchable
        clearable
        value={seasonalDiscount}
        onChange={(d) => {
          setSeasonalDiscount(d);
        }}
      />
    </div>
  );
}

type SeasonalDiscount =
  RouterOutputs["v2_5"]["discounts"]["getSeasonalDiscounts"][0];

function getSeasonalDiscountsEligibility({
  seasonalDiscounts,
  orderTotal,
}: {
  seasonalDiscounts: Array<SeasonalDiscount>;
  orderTotal: Decimal;
}) {
  const uniqueRefs = uniqBy(seasonalDiscounts, (d) => d.reference).map(
    (d) => d.reference,
  );

  const discountsEligibility: Array<{
    discount: SeasonalDiscount;
    amountLeft: Decimal;
    minPurchaseMet: boolean;
    isBestEligibleRate: boolean;
  }> = [];

  for (const ref of uniqueRefs) {
    let discounts = seasonalDiscounts.filter((d) => d.reference === ref);
    // Sort in descending order
    discounts = discounts.sort(
      (a, b) =>
        b.minPurchaseForEligibility
          ?.minus(a.minPurchaseForEligibility ?? 0)
          .toNumber() ?? 0,
    );

    let minPurchaseMetAlready = false;
    for (const discount of discounts) {
      const amountLeft = new Decimal(
        discount.minPurchaseForEligibility ?? 0,
      ).minus(orderTotal);

      let minPurchaseMet = true;
      if (amountLeft.gt(0)) {
        minPurchaseMet = false;
      }

      discountsEligibility.push({
        discount,
        amountLeft,
        minPurchaseMet: minPurchaseMet,
        isBestEligibleRate: minPurchaseMet && !minPurchaseMetAlready,
      });

      if (minPurchaseMet) {
        minPurchaseMetAlready = true;
      }
    }
  }

  return discountsEligibility;
}
