import { RouterOutputs, trpc } from "#/trpc"
import { Modal, NumberInput } from "@mantine/core"
import { css } from "#/css/css"
import { Fragment } from "react"
import { z } from "zod"
import { makeController } from "#/components-ng"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider } from "react-hook-form"
import { Button } from "@gt/ui"
import { reportUserSuccess } from "#/util"
import dayjs from "dayjs"
import {
	MantineReactTable,
	MRT_ColumnDef,
	useMantineReactTable,
} from "mantine-react-table"
import { S } from "#/s"

type PurchaseOrderItemSku =
	RouterOutputs["v2_5"]["purchaseOrder"]["getById"]["purchaseOrderItemSku"][number]

interface ReceivedItemModalProps {
	opened: boolean
	onClose: () => void
	purchaseOrderItemSku: PurchaseOrderItemSku
}

const createReceivedItemSchema = z.object({
	filials: z.array(
		z.object({
			filialId: z.string(),
			receivedQuantity: z.number(),
		}),
	),
})

export type FormValues = z.infer<typeof createReceivedItemSchema>
export const C = makeController<FormValues>()

export const ReceivedItemModal = (props: ReceivedItemModalProps) => {
	const ctx = trpc.useContext()
	const expectedQty = props.purchaseOrderItemSku.expectedQuantity
	const receivedQty =
		props.purchaseOrderItemSku.receivedPurchaseOrderItemSku?.reduce(
			(acc, received) => acc + (received?.receivedQuantity ?? 0),
			0,
		)

	const shortFallQtyTotal = expectedQty - receivedQty

	const form = C.useForm({
		resolver: zodResolver(createReceivedItemSchema),
		shouldUnregister: false,
		defaultValues: {
			filials: [],
		},
	})

	const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
		onSuccess: (data) => {
			form.setValue(
				"filials",
				data?.map((filial) => ({
					filialId: filial.id.toString(),
					receivedQuantity: 0,
				})),
			)
		},
		refetchInterval: 0,
		refetchOnWindowFocus: false,
	})

	const { mutate, isLoading } =
		trpc.v2_5.purchaseOrder.receiveItems.useMutation({
			onSuccess() {
				ctx.v2_5.purchaseOrder.invalidate()
				reportUserSuccess({
					title: "Received quantity saved",
				})
				props.onClose()
			},
		})

	const handleSubmit = (values: FormValues) => {
		const receivedItems = values.filials.map((item) => ({
			filialId: Number(item.filialId),
			receivedQuantity: item.receivedQuantity,
		}))

		mutate({
			purcharseOrderItemSkuId: props.purchaseOrderItemSku.id,
			itemSkuId: props.purchaseOrderItemSku.itemSkuId,
			filials: receivedItems,
		})
	}

	const itemSkuOnFilials = form.watch("filials")
	const receivedQuantityTemp = itemSkuOnFilials.reduce((acc, itemSku) => {
		return acc + Number(itemSku.receivedQuantity)
	}, 0)

	const shortFallQty = shortFallQtyTotal - receivedQuantityTemp

	return (
		<Modal
			opened={props.opened}
			onClose={() => props.onClose()}
			closeOnClickOutside={false}
			closeOnEscape={false}
			centered
			size="auto"
		>
			<S.div d="flex" flexDir="column" gap="1rem" p="1rem">
				<S.p fs="1.5rem" fw={500}>
					Received quantity
				</S.p>
				<S.div d="flex" gap="1rem">
					<S.p color="red">Shortfall Qty: {shortFallQty}</S.p>
				</S.div>
				<S.hr marginX="-1rem" />
				<FormProvider {...form}>
					<form
						onSubmit={(e) => {
							e.preventDefault()
							form.handleSubmit(handleSubmit)(e)
						}}
					>
						<S.div d="grid" gridTemplateColumns={2} gridGap="1rem">
							{filials?.map((filial, i) => {
								const currentStock =
									props.purchaseOrderItemSku?.itemSku?.itemSkuStock?.find(
										(item) => item.filialId === filial.id,
									)?.quantity ?? 0

								return (
									<Fragment key={i}>
										<S.div d="flex" flexDir="column" gap="0.2rem">
											<p>{filial.name}</p>
											<S.p fs="12px" color="gray.500">
												(Current stock: {currentStock})
											</S.p>
										</S.div>
										<C.M
											as={NumberInput}
											name={`filials.${i}.receivedQuantity`}
											hideControls
											type="number"
											min={0}
											max={
												shortFallQtyTotal -
												(form
													.watch("filials")
													?.filter((fi) => Number(fi.filialId) !== filial.id)
													.reduce(
														(acc, fi) => acc + Number(fi.receivedQuantity),
														0,
													) ?? 0)
											}
										/>
									</Fragment>
								)
							})}
						</S.div>
						<S.div d="flex" justifyContent="flex-end">
							<Button
								type="submit"
								className={css({
									marginTop: "1rem",
								})}
								isLoading={isLoading}
							>
								Save
							</Button>
						</S.div>
					</form>
				</FormProvider>
				{props.purchaseOrderItemSku.receivedPurchaseOrderItemSku?.length >
					0 && (
					<ReceivedItemsHistoryTable
						receivedItems={
							props.purchaseOrderItemSku.receivedPurchaseOrderItemSku
						}
					/>
				)}
			</S.div>
		</Modal>
	)
}

interface ReceivedItemsHistoryTableProps {
	receivedItems: PurchaseOrderItemSku["receivedPurchaseOrderItemSku"]
}

export const ReceivedItemsHistoryTable = (
	props: ReceivedItemsHistoryTableProps,
) => {
	const table = useMantineReactTable({
		columns,
		data: props.receivedItems,
		enableFilters: false,
		enableColumnOrdering: false,
		enableTopToolbar: false,
	})

	return (
		<>
			<S.p fs="1rem" fw={500}>
				Received quantity history
			</S.p>
			<MantineReactTable table={table} />
		</>
	)
}

const columns: MRT_ColumnDef<
	PurchaseOrderItemSku["receivedPurchaseOrderItemSku"][number]
>[] = [
	{
		id: "receivedQuantity",
		header: "Received Quantity",
		accessorKey: "receivedQuantity",
		enableSorting: false,
	},
	{
		id: "receivedAt",
		header: "Received At",
		accessorKey: "receivedAt",
		Cell: (table) => {
			return dayjs(table.row.original.receivedAt).format("YYYY/DD/MM")
		},
	},
	{
		id: "filial",
		header: "Filial",
		accessorKey: "filial",
		enableSorting: false,
		Cell: (table) => {
			return table.row.original.filial?.name ?? ""
		},
	},
]
