import { Eye } from "#/components-ng"
import { RouterOutputs } from "#/trpc"
import { Drawer, Stack, Text, TextInput, Title } from "@mantine/core"
import * as M from "@mantine/core"
import { CustomAttribute, OrderItemSkuCustomAttribute } from "server"
import { styled } from "#/css/jsx"
import { modals } from "@mantine/modals"
import { ImageMarkerEditor } from "#/scenes/Inventory/ItemList/Form/Variant/components/image-marker-editor"

type OrderItemSku = NonNullable<
	RouterOutputs["order"]["pos"]["ecommerceOrders"]["ecommerceOrder"]["get"]
>["orderItemSku"][number]

interface ViewAttributesButtonProps {
	title: string
	sku: number
	customAttribute?: (OrderItemSkuCustomAttribute & {
		customAttribute: CustomAttribute
	})[]
	orderItemSku: OrderItemSku
}

export const ViewAttributesButton = ({
	title,
	sku,
	customAttribute,
	orderItemSku,
}: ViewAttributesButtonProps) => {
	const [opened, setOpened] = useState(false)

	return (
		<>
			{customAttribute && customAttribute.length > 0 && (
				<M.Tooltip label="View attributes">
					<M.ActionIcon onClick={() => setOpened(true)}>
						<Eye />
					</M.ActionIcon>
				</M.Tooltip>
			)}
			<OrderItemSkuCustomAttributesDrawer
				open={opened}
				setOpen={setOpened}
				customAttribute={customAttribute}
				sku={sku}
				title={title}
				orderItemSku={orderItemSku}
			/>
		</>
	)
}

interface OrderItemSkuCustomAttributesDrawerProps {
	open: boolean
	setOpen(value: boolean): void
	title: string
	sku: number
	customAttribute?: (OrderItemSkuCustomAttribute & {
		customAttribute: CustomAttribute
	})[]
	orderItemSku: OrderItemSku
}

const OrderItemSkuCustomAttributesDrawer = ({
	open,
	setOpen,
	customAttribute,
	sku,
	title,
	orderItemSku,
}: OrderItemSkuCustomAttributesDrawerProps) => {
	return (
		<Drawer
			opened={open}
			onClose={() => setOpen(false)}
			size="lg"
			position="right"
			padding="md"
		>
			<Stack py="20px" px="20px" spacing="md">
				<styled.div display="flex" justifyContent="space-between">
					<styled.div display="flex" flexDir="column" gap="1rem">
						<Title order={3} weight={500} color="#212121" size={20}>
							{title}
						</Title>
						<Title order={5} weight={400} color="#666" size={16}>
							SKU {sku}
						</Title>
					</styled.div>
					{customAttribute?.find(
						(item) => item.customAttribute?.type === "MULTISELECT_ITEM",
					) && (
						<SlotsViewButton
							itemSkuId={orderItemSku.itemSkuId}
							slotImages={orderItemSku.orderItemSkuToCustomAttributeMultipleItem.map(
								(item) => item.itemSku?.defaultSlotImage ?? "./placeholder.png",
							)}
							setOpen={setOpen}
							limitSlot={
								orderItemSku.orderItemSkuToCustomAttributeMultipleItem.length
							}
						/>
					)}
				</styled.div>
				{customAttribute?.map((item, i) => (
					<Stack key={i} spacing="md">
						{(item.customAttribute?.type === "TEXT" ||
							item.customAttribute?.type === "SELECT") && (
							<>
								<TextInput
									label={item.customAttribute?.name}
									value={item.value}
									readOnly
								/>
							</>
						)}
						{item.customAttribute?.type === "FILE" && (
							<Stack>
								<Text>{item.customAttribute.name}</Text>
								<img
									src={item.value}
									alt={item.customAttribute.name}
									width={100}
									height={100}
									className="rounded-md"
								/>
							</Stack>
						)}
						{item.customAttribute.type === "MULTISELECT_ITEM" && (
							<Stack spacing="md">
								<Text>{item.customAttribute.name}</Text>
								<Stack spacing="md">
									{orderItemSku.orderItemSkuToCustomAttributeMultipleItem.map(
										(item, i) => {
											return (
												<M.Group key={i} spacing="md">
													<img
														src={
															item.itemSku.defaultImage ?? "./placeholder.png"
														}
														alt={item.itemSku.title}
														width={100}
														height={100}
													/>
													<Stack>
														<Text>
															{item.itemSku.sku} - {item.itemSku.title}
														</Text>
														<styled.div display="flex" gap="1rem">
															<Text className="text-gray-500 text-sm">
																QTY: {item.quantity}
															</Text>
															{item.slotPosition != null && (
																<Text className="text-gray-500 text-sm">
																	Slot: {item.slotPosition + 1}
																</Text>
															)}
														</styled.div>
													</Stack>
												</M.Group>
											)
										},
									)}
								</Stack>
							</Stack>
						)}
					</Stack>
				))}
			</Stack>
		</Drawer>
	)
}

interface SlotsViewButtonProps {
	itemSkuId: number
	limitSlot: number
	slotImages: string[]
	setOpen(value: boolean): void
}
const SlotsViewButton = (props: SlotsViewButtonProps) => {
	const openModal = () => {
		props.setOpen(false)

		modals.open({
			modalId: "slots-view-modal",
			children: (
				<SlotsView
					itemSkuId={props.itemSkuId}
					slotImages={props.slotImages}
					setOpen={props.setOpen}
					limitSlot={props.limitSlot}
				/>
			),
		})
	}

	return (
		<M.Button size="sm" onClick={openModal}>
			View slots
		</M.Button>
	)
}

const SlotsView = (props: SlotsViewButtonProps) => {
	return (
		<ImageMarkerEditor
			itemSkuId={props.itemSkuId}
			slotImages={props.slotImages}
			readOnly={true}
			limitSlot={props.limitSlot}
		/>
	)
}
