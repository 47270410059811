import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { useRef } from "react"

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
)

interface DataSet {
	label: string
	backgroundColor: string
	borderColor: string
	data: number[]
}

interface LinearScaleChartProps {
	datasets: DataSet[]
	labels: string[]
	includeTitle: boolean
	includeLegend: boolean
}

export const LinearScaleChart = (props: LinearScaleChartProps) => {
	const chartRef = useRef(null)

	const options = {
		responsive: true,
		plugins: {
			title: {
				display: props.includeTitle,
			},
			legend: {
				display: props.includeLegend,
			},
			tooltip: {
				mode: "index" as any,
				intersect: false,
			},
		},
	}

	const chartData = {
		labels: props.labels,
		datasets: props.datasets,
	}

	return <Line ref={chartRef} data={chartData} options={options} />
}
