import { makeController } from "#/components-ng/index.js";
import { z } from "zod";

export const productsRange = ["10", "25", "50", "100"] as const;

export const generateReportProductsMostSoldEcommerceSchema = z.object({
	dateRange: z.array(z.date()),
	productsRange: z.enum(productsRange),
	departmentId: z.string().optional().nullish(),
	categoryId: z.string().optional().nullish(),
	seasonId: z.string().optional().nullish(),
});

export type FormValues = z.infer<
	typeof generateReportProductsMostSoldEcommerceSchema
>;
export const C = makeController<FormValues>();
