import * as M from "@mantine/core"
import { S, Stack, css } from "#/s"
import { Schema } from "./types"
import { C, useFormContext } from "./common"
import { CloseOutline } from "#/components-ng"
import { useWatch } from "react-hook-form"
import { useDisclosure } from "@mantine/hooks"
import { QuestionFormDrawer } from "./question-form"
import { ModeMolecule } from "./state"
import { useMolecule } from "bunshi/react"
import { match } from "ts-pattern"

export function FormQuestion(props: {
	index: number
	onRemove: (index: number) => void
}) {
	const { index } = props
	const form = useFormContext()
	const question = useWatch({
		control: form.control,
		name: `questions.${index}`,
	})
	const [opened, { open, close }] = useDisclosure(false)
	const modeAtom = useMolecule(ModeMolecule)
	const mode = useAtomValue(modeAtom)

	return (
		<>
			<QuestionFormDrawer
				mode={mode}
				opened={opened}
				onClose={close}
				withinPortal
				initialValues={{
					title: question.title,
					answerType: question.answerType,
					multiOptions: question.multiOptions,
				}}
				onSubmit={(values) => {
					form.setValue(`questions.${index}`, values)
				}}
			/>
			<S.div w="full" h="full" pos="relative">
				<S.button
					type="button"
					d="grid"
					gridTemplateColumns="auto 1fr"
					pos="relative"
					p={4}
					bg={"white"}
					border="token(colors.slate.300) solid 1px"
					rounded="md"
					gapX={4}
					c="slate.700"
					w="full"
					h="full"
					onClick={open}
				>
					<S.p fs="sm" c="slate.500" gridRow="1 / span 10">
						{index + 1}
					</S.p>
					<S.p>{question.title.en}</S.p>
					<S.p fs="sm" c="slate.500">
						{formatAnswerType(question)}
					</S.p>
				</S.button>
				{mode === "create" && (
					<S.button
						type="button"
						pos="absolute"
						top={4}
						right={4}
						c="slate.400"
						bg={{
							_hover: "slate.100",
						}}
						rounded="md"
						transition="all 0.1s ease-in-out"
						onClick={() => props.onRemove(props.index)}
					>
						<CloseOutline />
					</S.button>
				)}
			</S.div>
		</>
	)
}

function formatAnswerType(question: Schema["questions"][number]) {
	return match(question.answerType)
		.with("MULTI_OPTION", () => {
			const numOfOptions = question.multiOptions.length
			return `Multi option (${numOfOptions} options)`
		})
		.with("RATING", () => "Rating")
		.with("TEXT", () => "Free text")
		.exhaustive()
}
