import { BarChart } from "#/components-ng"
import GTLoader from "#/components-ng/loader"
import { S } from "#/s"
import { trpc } from "#/trpc"
import {
	C,
	type FormValues,
	generateReportProductsMostSoldEcommerceSchema,
	productsRange,
} from "./types"
import { zodResolver } from "@hookform/resolvers/zod"
import * as M from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import dayjs from "dayjs"
import { FormProvider } from "react-hook-form"

export const ProductsMostSoldEcommerceReport = () => {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().startOf("month").toDate(),
		dayjs().endOf("month").toDate(),
	])

	const form = C.useForm({
		resolver: zodResolver(generateReportProductsMostSoldEcommerceSchema),
		shouldUnregister: false,
		defaultValues: {
			dateRange: [dateRange[0]!, dateRange[1]!],
			productsRange: "10",
			departmentId: null,
			categoryId: null,
			seasonId: null,
		},
	})

	const {
		data: report,
		mutate: generateReport,
		isLoading,
	} = trpc.v2_5.report.getProductsMostSoldEcommerce.useMutation()

	useEffect(() => {
		generateReport({
			dateRange: {
				from: dateRange[0]!,
				to: dateRange[1]!,
			},
			productsRange: 10,
			categoryId: null,
			departmentId: null,
			seasonId: null,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const data = useMemo(() => {
		if (report) {
			return report
		}
		return null
	}, [report])

	const handleSubmit = (values: FormValues) => {
		generateReport({
			dateRange: {
				from: values.dateRange[0],
				to: values.dateRange[1],
			},
			productsRange: Number(values.productsRange),
			categoryId: values.categoryId ? Number(values.categoryId) : null,
			departmentId: values.departmentId ? Number(values.departmentId) : null,
			seasonId: values.seasonId ? Number(values.seasonId) : null,
		})
	}

	return (
		<M.Container size="xl">
			<M.Stack spacing="md">
				<M.Title order={2}>Products most sold</M.Title>
				<FormProvider {...form}>
					<form
						onSubmit={(e) => {
							e.stopPropagation()
							form.handleSubmit(handleSubmit)(e)
						}}
					>
						<S.div
							d="flex"
							justifyItems="center"
							gap={4}
							flexWrap={{
								base: "wrap",
								lg: "nowrap",
							}}
						>
							<C.M
								as={DatePickerInput}
								label="Date range"
								name="dateRange"
								type="range"
								onChange={(v) => {
									if (!v) return
									if (v[0] == null || v[1] == null) return
									setDateRange([v[0], v[1]])
								}}
								numberOfColumns={2}
								popoverProps={{
									zIndex: 9000,
								}}
								w={300}
								required
							/>
							<C.M
								as={M.Select}
								label="Products range"
								name="productsRange"
								data={productsRange.map((r) => ({
									label: r,
									value: r,
								}))}
								className="w-[150px]"
							/>
							<DepartmentField />
							<CategoryField />
							<SeasonField />
							<M.Button
								type="submit"
								loading={isLoading}
								className="mt-6 px-12 "
							>
								Generate
							</M.Button>
						</S.div>
					</form>
				</FormProvider>
				{isLoading ? (
					<M.LoadingOverlay
						visible={isLoading}
						loader={<GTLoader width={100} height={100} />}
					/>
				) : (
					<S.div d="flex" flexDir="column" gap={4}>
						<S.hr />
						<S.h3>Most Sold</S.h3>
						<BarChart
							pathname="/inventory/item-list/preview/"
							enableRedirect
							textLabel="Sold items"
							data={
								data?.mostSoldProducts?.map((p) => ({
									id: p.id,
									label: `${p.sku}`,
									total: p.total ?? 0,
								})) ?? []
							}
						/>
						<S.hr my="1rem" />
						<S.h3>Most Frequent</S.h3>
						<BarChart
							pathname="/inventory/item-list/preview/"
							enableRedirect
							textLabel="Unique purchases"
							data={
								data?.mostFrequentProducts?.map((p) => ({
									id: p.id,
									label: `${p.sku}`,
									total: p.total ?? 0,
								})) ?? []
							}
						/>
					</S.div>
				)}
			</M.Stack>
		</M.Container>
	)
}

const DepartmentField = () => {
	const form = C.useFormContext()
	const controller = C.useController({
		name: "departmentId",
		control: form.control,
	})

	const { data } = trpc.department.getAll.useQuery()

	return (
		<C.M
			as={M.Select}
			label="Department"
			name={controller.field.name}
			data={data?.map((d) => ({ label: d.name, value: d.id.toString() })) ?? []}
			clearable
			searchable
			onChange={(e) => {
				controller.field.onChange(e)
			}}
		/>
	)
}

const CategoryField = () => {
	const form = C.useFormContext()
	const controller = C.useController({
		name: "categoryId",
		control: form.control,
	})

	const { data } = trpc.category.getAll.useQuery()

	return (
		<C.M
			as={M.Select}
			label="Category"
			name={controller.field.name}
			data={data?.map((c) => ({ label: c.name, value: c.id.toString() })) ?? []}
			clearable
			searchable
			onChange={(e) => {
				controller.field.onChange(e)
			}}
		/>
	)
}

const SeasonField = () => {
	const form = C.useFormContext()
	const controller = C.useController({
		name: "seasonId",
		control: form.control,
	})

	const { data } = trpc.season.getAll.useQuery()

	return (
		<C.M
			as={M.Select}
			label="Season"
			name={controller.field.name}
			data={data?.map((s) => ({ label: s.name, value: s.id.toString() })) ?? []}
			clearable
			searchable
			onChange={(e) => {
				controller.field.onChange(e)
			}}
		/>
	)
}
