import { EllipsisHorizontal, Eye, makeController } from "#/components-ng";
import { css } from "#/css/css";
import { styled } from "#/css/jsx";
import { trpc } from "#/trpc";
import type { RouterOutputs } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { flatMapDeep, omit, sortBy } from "lodash";
import { ShippingInfoDrawer } from "./ShippingInfoDrawer";
import { CustomerPanel, UserSelect } from "./_customer-panel";
import { CreatePackageButton, ViewAttributesButton } from "./components";
import { useOrder, type Order } from "./use-order";
import {
	Accordion,
	Button,
	Checkbox,
	Dropdown,
	Field,
	Form,
	Select,
	Table,
} from "@gt/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import * as M from "@mantine/core";
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Provider, atom, useAtom, useSetAtom } from "jotai";
import { Link } from "react-router-dom";
import { z } from "zod";
import { Badge, Tooltip } from "@radix-ui/themes";
import { openConfirmModal } from "@mantine/modals";
import Print from "~icons/ion/print-outline";
import QRIcon from "~icons/ion/qr-code-outline";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { useState, useEffect } from "react";
import { PrintLabelsModal } from "#/scenes/Inventory/ItemList/PrintLabelsModal";
import { S } from "#/s";
import { PrintAllLabels } from "../components/print-all-labels";
import FileSaver from "file-saver";
import { printTicketFromOrderV2 } from "#/modules/ticket/print";

type SelectedOrderItemSku = {
	id: number;
	// ^ Id of the OrderItemSku, not the ItemSku.
	sku: number;
	title: string;
	quantity: number;
};
const selectedOrderItemSkusAtom = atom<Record<number, SelectedOrderItemSku>>(
	{},
);

export function EcommerceOrder() {
	return (
		<Provider>
			<EcommerceOrderInner />
		</Provider>
	);
}

type ItemSku = Order["orderItemSku"][number]["itemSku"];
const itemSkuToPrintAtom = atom<ItemSku | null>(null);
const openModalItemSkuToPrintAtom = atom<boolean>(false);

export function EcommerceOrderInner() {
	const [browserPrint] = useState(() => new ZebraBrowserPrintWrapper());
	const [isLoadingPrinters, setIsLoadingPrinters] = useState<boolean>(false);

	const [selectedItemSkuToPrint, setSelectedItemSkuToPrint] =
		useAtom(itemSkuToPrintAtom);
	const [openModalToPrintLabel, setOpenModalToPrintLabel] = useAtom(
		openModalItemSkuToPrintAtom,
	);

	useEffect(() => {
		async function setDefaultPrinter() {
			setIsLoadingPrinters(true);
			const availablePrinters = await browserPrint.getAvailablePrinters();
			browserPrint.setPrinter(availablePrinters[0]);
			setIsLoadingPrinters(false);
		}

		setDefaultPrinter();
	}, [browserPrint]);

	const { data: order, isLoading } = useOrder();

	// ^ Reset selected items on render
	const [selectedOrderItemSkus] = useAtom(selectedOrderItemSkusAtom);

	if (order && order.orderType !== "ONLINE" && order.orderType !== "PICKUP") {
		return (
			<div className="mx-auto my-24">
				You must select an &quot;Online&quot; or &quot;Pick up&quot; order
			</div>
		);
	}

	const isOnline = order?.orderType === "ONLINE";

	return (
		<>
			<Provider key={selectedItemSkuToPrint?.id}>
				<PrintLabelsModal
					printer={browserPrint}
					isLoadingPrinters={isLoadingPrinters}
					defaultProduct={selectedItemSkuToPrint as any}
					setSelectedItemSkuToPrint={setSelectedItemSkuToPrint}
					opened={openModalToPrintLabel}
					setOpened={setOpenModalToPrintLabel}
				/>
			</Provider>
			<div className="grid gap-y-4">
				<div className="grid bg-white px-8">
					<M.Group position="apart" py="1.2rem" align="end">
						<M.Stack spacing="8px">
							<h2>
								{order?.orderType === "ONLINE"
									? "Shipping"
									: order?.orderType === "PICKUP"
										? "Pick up"
										: "..."}
							</h2>
							<M.Text fw={500}>Order #{order?.receiptNumber}</M.Text>
						</M.Stack>
						{isOnline && (
							<M.Group>
								<CreatePackageButton />
								<ViewShippingInfoButton
									isLoading={isLoading}
									customer={order?.customer ?? order?.userBasicInfo ?? null}
									receiptNumber={order?.receiptNumber ?? null}
									shipping={order?.orderShipping ?? null}
									packages={order?.packages ?? null}
									orderItemSku={order?.orderItemSku ?? null}
									withInsurance={order?.withInsurance ?? false}
								/>
							</M.Group>
						)}
					</M.Group>
					<hr className="-mx-8 border-slate-100" />
					<div className="flex items-center justify-between py-4">
						<div className="flex items-center justify-between gap-2">
							<OrderStatusForm />
							<AssociatedSelect order={order} />
						</div>
						{order?.orderGifts && order?.orderGifts.length > 0 && (
							<Tooltip content="This order has a gift">
								<Badge size="3" color="crimson">
									{order?.orderGifts[0].userRouletteReward.rouletteItem
										?.title ?? ""}
								</Badge>
							</Tooltip>
						)}
					</div>
				</div>
				<div className="bg-white p-6">
					<CustomerPanel order={order} />
					<hr className="my-8 border-slate-100" />
					<S.div
						d="flex"
						justifyContent="space-between"
						alignItems="center"
						mb="2"
					>
						<h4>Public: Items</h4>
						<S.div d="flex" alignItems="center" gap="2">
              <PrintEpsonButton orderId={order?.id ?? 0} />
							<PrintQrButton orderId={order?.id ?? 0} />
							<PrintAllLabels
								itemSkus={
									order?.orderItemSku?.map((o) => ({
										...o.itemSku,
										qty: o.quantity,
									})) ?? []
								}
								type="ecommerce-order"
							/>
						</S.div>
					</S.div>
					<RegularItemsTable />
					<hr className="my-8 border-slate-100" />
					<CustomizedItemsTable />
					<hr className="my-8 border-slate-100" />
					<CustomizedMultipleItemsTable />
					<div className="mt-6 flex justify-between">
						<Button
							asChild
							variant="destructive"
							className="px-8"
							disabled={Object.values(selectedOrderItemSkus).length === 0}
						>
							<Link to="refund" state={{ items: selectedOrderItemSkus }}>
								Refund
							</Link>
						</Button>
						<Accordion.Root type="single" collapsible>
							<Accordion.Item value="root" className="border-none">
								<Accordion.Trigger className="rounded-md bg-green-8 px-4 py-1 text-white hover:no-underline">
									<div className="flex w-[220px] justify-between text-sm">
										<span>Total</span>
										<span>${order?.total.toFixed(2)}</span>
									</div>
								</Accordion.Trigger>
								<Accordion.Content></Accordion.Content>
							</Accordion.Item>
						</Accordion.Root>
					</div>
				</div>
			</div>
		</>
	);
}

interface ViewShippingInfoButtonProps {
	customer: Order["userBasicInfo"] | null;
	shipping: Order["orderShipping"] | null;
	packages: Order["packages"] | null;
	orderItemSku: Order["orderItemSku"] | null;
	receiptNumber: number | null;
	withInsurance?: boolean;
	isLoading?: boolean;
}

function ViewShippingInfoButton(props: ViewShippingInfoButtonProps) {
	const [opened, setOpened] = useState(false);

	return (
		<>
			<ShippingInfoDrawer
				isLoading={props.isLoading}
				open={opened}
				setOpen={setOpened}
				data={{
					customer: props.customer,
					receiptNumber: props.receiptNumber,
					orderShipping: props.shipping,
					packages: props.packages,
					orderItemSku: props.orderItemSku,
					withInsurance: props.withInsurance,
				}}
			/>
			<M.Button
				leftIcon={<Eye />}
				color="gray.0"
				c="gray.8"
				onClick={() => setOpened(true)}
			>
				View shipping info
			</M.Button>
		</>
	);
}

const OrderStatuses = [
	"PROCESSING",
	"READY_TO_PICKUP",
	"COMPLETE",
	"CANCELLED",
] as const;

const OrderStatusFormSchema = z.object({
	status: z.enum(OrderStatuses),
});

type OrderStatusFormSchema = z.TypeOf<typeof OrderStatusFormSchema>;

const { useForm: useOrderStatusForm } = makeController<OrderStatusFormSchema>();

function OrderStatusForm() {
	const { data: order } = useOrder();
	const { mutate, isLoading } = trpc.v2_5.order.updateStatus.useMutation({
		onSuccess: () => {
			reportUserSuccess({
				title: "Order status updated",
			});
		},
	});

	const form = useOrderStatusForm({
		values: {
			status:
				(order?.orderStatus as (typeof OrderStatuses)[number]) ?? "PROCESSING",
		},
		resolver: zodResolver(OrderStatusFormSchema),
		resetOptions: {
			keepDirtyValues: true,
		},
	});

	const handleOpenConfirmModal = (
		orderId: number,
		values: OrderStatusFormSchema,
	) =>
		openConfirmModal({
			title: "You don't have an associate, are you sure you want to continue?",
			labels: { confirm: "Confirm", cancel: "Cancel" },
			confirmProps: { color: "red" },
			onConfirm: () => {
				mutate({
					id: orderId,
					status: values.status,
				});
			},
		});

	function handleSubmit(values: OrderStatusFormSchema) {
		if (!order) {
			reportUserError({
				title: "There is no order to update",
			});
			return;
		}

		if (!order.associated) {
			handleOpenConfirmModal(order.id, values);
			return;
		}

		mutate({
			id: order.id,
			status: values.status,
		});
	}

	return (
		<Form.Root {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<div className="flex items-end gap-x-4">
					<Form.Field
						control={form.control}
						name="status"
						render={({ field }) => (
							<Field.Root>
								<Field.Label>Status</Field.Label>
								<Select.Root onValueChange={field.onChange} value={field.value}>
									<Field.Control>
										<Select.Trigger>{field.value}</Select.Trigger>
									</Field.Control>
									<Select.Content>
										{OrderStatuses.map((status) => (
											<Select.Item
												key={status}
												value={status}
												disabled={status === "CANCELLED"}
											>
												{status}
											</Select.Item>
										))}
									</Select.Content>
								</Select.Root>
								<Field.Message />
							</Field.Root>
						)}
					/>
					<div>
						<Button
							type="submit"
							isLoading={isLoading}
							disabled={
								order?.orderStatus === "CANCELLED" ||
								order?.orderStatus === "COMPLETE"
							}
						>
							Save status
						</Button>
					</div>
				</div>
			</form>
		</Form.Root>
	);
}

type OrderItemSku = NonNullable<
	RouterOutputs["order"]["pos"]["ecommerceOrders"]["ecommerceOrder"]["get"]
>["orderItemSku"][number];

const column = createColumnHelper<OrderItemSku>();

const columns = [
	column.display({
		id: "Actions",
		cell: function Cell(table) {
			const orderItemSkuId = table.row.original.id;
			const [selectedItemSkus, setSelectedItemSkus] = useAtom(
				selectedOrderItemSkusAtom,
			);
			const setItemSkuToPrint = useSetAtom(itemSkuToPrintAtom);
			const setOpenModalToPrint = useSetAtom(openModalItemSkuToPrintAtom);

			let refundedQuantity = 0;

			for (const refund of table.row.original.refundOrderItemSku) {
				refundedQuantity += refund.quantity;
			}

			const hasBeenRefundedCompletely =
				refundedQuantity === table.row.original.quantity;
			const isActive =
				hasBeenRefundedCompletely || selectedItemSkus[orderItemSkuId] != null;

			function changeActiveState(active: boolean) {
				if (!active) {
					setSelectedItemSkus((prev) => {
						const newSelectedItemSkus = { ...prev };
						delete newSelectedItemSkus[orderItemSkuId];
						return newSelectedItemSkus;
					});
					return;
				}
				setSelectedItemSkus((prev) => ({
					...prev,
					[orderItemSkuId]: {
						id: orderItemSkuId,
						sku: table.row.original.itemSku.sku,
						title: table.row.original.itemSku.title,
						quantity: table.row.original.quantity,
					},
				}));
			}

			return (
				<M.Group position="center">
					<Dropdown.Root>
						<Dropdown.Trigger asChild>
							<Button
								variant="ghost"
								className="relative size-[24px] bg-transparent p-1 text-slate-400"
							>
								<EllipsisHorizontal />
							</Button>
						</Dropdown.Trigger>
						<Dropdown.Content>
							<Dropdown.Item
								asChild
								onClick={() => {
									setItemSkuToPrint(table.row.original.itemSku);
									setOpenModalToPrint(true);
								}}
							>
								<div
									className={css({
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										gap: 2,
									})}
								>
									<Print />
									Print label
								</div>
							</Dropdown.Item>
						</Dropdown.Content>
					</Dropdown.Root>
					<Checkbox
						checked={isActive}
						disabled={hasBeenRefundedCompletely}
						onCheckedChange={(v) => {
							if (v === "indeterminate" || hasBeenRefundedCompletely) return;
							changeActiveState(v);
						}}
					/>
					<ViewAttributesButton
						title={table.row.original.itemSku.title}
						sku={table.row.original.itemSku.sku}
						customAttribute={table.row.original.orderItemSkuCustomAttribute}
						orderItemSku={table.row.original}
					/>
				</M.Group>
			);
		},
	}),
	column.accessor("itemSku.defaultImage", {
		header: "Image",
		cell: (table) => {
			return (
				<img
					src={table.getValue() ?? "/placeholder.png"}
					className="size-[80px]"
				/>
			);
		},
	}),
	column.accessor("itemSku.sku", {
		header: "SKU",
	}),
	column.accessor("itemSku.title", {
		header: "Title",
	}),
	column.display({
		id: "pkg",
		header: "PKG",
		cell: ({ row }) => {
			const pkgValue = row.original.itemSku.presentationValue;
			const pkgType = row.original.itemSku.presentationType;
			return `${pkgValue} ${pkgType}`;
		},
	}),
	column.accessor("quantity", {
		header: "Qty",
	}),
	column.display({
		id: "discount",
		header: "Discount",
		cell: (table) => {
			const discountAmount = table.row.original.discountAmount;
			const discountType = table.row.original.discountType;
			const discountReference = table.row.original.discountReference;
			const discountMode = table.row.original.discountMode;
			if (discountAmount) {
				return (
					<>
						<styled.p fontSize="12px" textAlign="center">
							{`${discountType === "AMOUNT" ? "$" : ""}
              ${discountAmount.toFixed(2)}
              ${discountType === "PERCENTAGE" ? "%" : ""}
            `}
						</styled.p>
						<styled.p fontSize="8px" textAlign="center">
							{`${discountReference}`}{" "}
						</styled.p>
						<styled.p fontSize="8px" color="red" textAlign="center">
							{discountMode ? `(${discountMode})` : ""}{" "}
						</styled.p>
					</>
				);
			}
			return "";
		},
	}),
	column.display({
		id: "subtotal",
		header: "Subtotal",
		cell: (table) => {
			const qty = table.row.original.quantity;
			const price = table.row.original.price;
			const subtotal = price.mul(qty);
			return `$${subtotal.toFixed(2)}`;
		},
	}),
	column.accessor("total", {
		header: "Total",
		cell: (table) => {
			return `$${table.getValue().toFixed(2)}`;
		},
	}),
];

function RegularItemsTable() {
	const { data: order } = useOrder();

	const data = useMemo(() => {
		const products_ =
			order?.orderItemSku
				?.filter(
					(o) =>
						o.isCustomAttributeMultipleItem === false &&
						o.orderItemSkuCustomAttribute.length === 0,
				)
				?.map((item) => ({
					...item,
					location:
						item.itemSku?.itemSkuStock?.find((is) => is.filialId === 2)
							?.storeLocation ?? item.itemSku?.itemSkuStock?.[0]?.storeLocation,
				})) ?? [];

		const sortProducts = sortBy(products_, ["location"]);

		const products =
			sortProducts.map((product) => {
				return omit(product, ["location"]);
			}) ?? [];

		return products ?? [];
	}, [order?.orderItemSku]);

	const table = useReactTable({
		columns,
		data: data,
		getCoreRowModel: getCoreRowModel(),
	});

	return (
		<div className="relative mb-10 max-h-[450px] overflow-auto">
			<Table.Root className="border-collapse">
				<Table.Header className="sticky top-0 bg-white">
					{table.getHeaderGroups().map((headerGroup) => (
						<Table.Row key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<Table.Head key={header.id} className="sticky top-0 bg-white">
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
								</Table.Head>
							))}
						</Table.Row>
					))}
				</Table.Header>
				<Table.Body>
					{table.getRowModel().rows.map((row) => (
						<Table.Row key={row.id}>
							{row.getVisibleCells().map((cell) => (
								<Table.Cell key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</Table.Cell>
							))}
						</Table.Row>
					))}
				</Table.Body>
			</Table.Root>
		</div>
	);
}

function CustomizedItemsTable() {
	const { data: order } = useOrder();

	const data = useMemo(
		() =>
			order?.orderItemSku?.filter(
				(o) =>
					o.isCustomAttributeMultipleItem === false &&
					o.orderItemSkuCustomAttribute.length > 0 &&
					o.orderItemSkuToCustomAttributeMultipleItem.length === 0,
			) ?? [],
		[order?.orderItemSku],
	);

	const table = useReactTable({
		columns,
		data: data,
		getCoreRowModel: getCoreRowModel(),
	});

	if (data.length === 0) {
		return null;
	}

	return (
		<>
			<h4>Custom: Personalized Items</h4>
			<div className="relative mb-10 max-h-[450px] overflow-auto">
				<Table.Root className="border-collapse">
					<Table.Header className="sticky top-0 bg-white">
						{table.getHeaderGroups().map((headerGroup) => (
							<Table.Row key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<Table.Head key={header.id} className="sticky top-0 bg-white">
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</Table.Head>
								))}
							</Table.Row>
						))}
					</Table.Header>
					<Table.Body>
						{table.getRowModel().rows.map((row) => (
							<Table.Row
								key={row.id}
								className={css({
									backgroundColor:
										row.original.isCustomAttributeMultipleItem ||
										row.original.orderItemSkuToCustomAttributeMultipleItem
											.length > 0
											? "#fbfcdb"
											: "",
								})}
							>
								{row.getVisibleCells().map((cell) => (
									<Table.Cell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</Table.Cell>
								))}
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			</div>
		</>
	);
}

function CustomizedMultipleItemsTable() {
	const { data: order } = useOrder();

	const data = useMemo(
		() =>
			flatMapDeep(
				order?.orderItemSku
					?.filter(
						(o) =>
							o.orderItemSkuCustomAttribute.length > 0 &&
							o.orderItemSkuToCustomAttributeMultipleItem.length > 0,
					)
					.map((o) => {
						const associatedItems =
							o.orderItemSkuToCustomAttributeMultipleItem.map((oi) => {
								const orderItemSku = order.orderItemSku.find(
									(o) => o.id === oi.id,
								);
								// biome-ignore lint/style/noNonNullAssertion: <explanation>
								return orderItemSku!;
							});

						return [o, associatedItems];
					}),
			) ?? [],
		[order?.orderItemSku],
	);

	const table = useReactTable({
		columns,
		data: data,
		getCoreRowModel: getCoreRowModel(),
	});

	if (data.length === 0) {
		return null;
	}

	return (
		<>
			<h4>Custom: Selected Items</h4>
			<div className="relative mb-10 max-h-[450px] overflow-auto">
				<Table.Root className="border-collapse">
					<Table.Header className="sticky top-0 bg-white">
						{table.getHeaderGroups().map((headerGroup) => (
							<Table.Row key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<Table.Head key={header.id} className="sticky top-0 bg-white">
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</Table.Head>
								))}
							</Table.Row>
						))}
					</Table.Header>
					<Table.Body>
						{table.getRowModel().rows.map((row) => (
							<Table.Row
								key={row.id}
								className={css({
									backgroundColor:
										row.original.orderItemSkuToCustomAttributeMultipleItem
											.length > 0
											? "#EDF8FF"
											: "",
								})}
							>
								{row.getVisibleCells().map((cell) => (
									<Table.Cell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</Table.Cell>
								))}
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			</div>
		</>
	);
}

export interface AssociatedSelectProps {
	order?: Order | null;
}

type User = {
	id: number;
	firstName: string;
	lastName?: string | null;
};

function AssociatedSelect(props: AssociatedSelectProps) {
	const [associated, setAssociated] = useState<User | null>(null);
	const ctx = trpc.useContext();

	useEffect(() => {
		setAssociated(props?.order?.associated ?? null);
	}, [props?.order]);

	const { mutate, isLoading } =
		trpc.order.pos.ecommerceOrders.ecommerceOrder.updateAssociated.useMutation({
			onSuccess() {
				reportUserSuccess({
					title: "Associated updated succesfully",
				});
				ctx.order.pos.invalidate();
			},
			onError() {
				reportUserSuccess({
					title: "Failed to update associated",
				});
			},
		});

	function submit() {
		if (props.order == null) {
			return;
		}
		mutate({
			id: props.order.id,
			assignedId: null,
			associatedId: associated?.id ?? null,
		});
	}

	return (
		<div className="flex items-end gap-x-4">
			<UserSelect
				label="Sales associate"
				value={associated}
				onChange={setAssociated}
			/>
			<Button
				isLoading={isLoading}
				onClick={submit}
				disabled={
					props.order?.orderStatus === "CANCELLED" ||
					props.order?.orderStatus === "COMPLETE"
				}
			>
				Save associate
			</Button>
		</div>
	);
}

interface PrintQrButtonProps {
	orderId: number;
}

const PrintQrButton = (props: PrintQrButtonProps) => {
	const { mutate: exportQr, isLoading } = trpc.v2_5.order.exportLabel.useMutation({
		onError(err) {
			reportUserError({
				title: "Failed to export QR",
				message: err.message,
			});
		},
		async onSuccess(data) {
			FileSaver.saveAs(data.url, `order_qr_${props.orderId}.pdf`);

			reportUserSuccess({
				title: "QR exported",
				message: "QR exported successfully",
			});
		},
	});

	return (
		<Button
			variant="secondary"
			onClick={() => exportQr({ id: props.orderId, includeQr: true })}
      isLoading={isLoading}
		>
      <QRIcon />
			Print QR
		</Button>
	);
};

interface PrintEpsonButtonProps {
	orderId: number
}

const PrintEpsonButton = (props: PrintEpsonButtonProps) => {
	const [reprintEpson, setReprintEpson] = useState<{
		enabled: boolean
		includeLocation?: boolean
	}>({ enabled: false })
	const [enabledFecth, setEnabledFetch] = useState(false)

	const { isLoading } = trpc.order.getById.useQuery(
		{
			id: props.orderId,
		},
		{
			enabled: enabledFecth,
      refetchOnWindowFocus: false,
			onSuccess(data) {
				if (reprintEpson.enabled) {
					printTicketFromOrderV2({
						order: data!,
						withLocation: !!reprintEpson.includeLocation,
					})
					setReprintEpson({ enabled: false })
					setEnabledFetch(false)
				}
				setEnabledFetch(false)
			},
		},
	)

	return (
		<Button
			variant="secondary"
			onClick={async () => {
				setEnabledFetch(true)
				setReprintEpson({ enabled: true })
			}}
      isLoading={isLoading}
		>
			<Print />
			Print Epson
		</Button>
	)
}