import { styled as s } from "#/css/jsx"
import { useAtomValue, useSetAtom } from "jotai/react"
import { imageRefAtom, imageSrcAtom } from "./state"

export interface ImagePreviewProps {}

export function ImagePreview(_props: ImagePreviewProps) {
	const setImageRef = useSetAtom(imageRefAtom)
	const imageSrc = useAtomValue(imageSrcAtom)

	return imageSrc != null ? (
		<s.img
			src={imageSrc}
			rounded="md"
			shadow="sm"
			ref={(ref) => (ref != null ? setImageRef({ ref }) : setImageRef(null))}
			onLoad={() => {
				// Trigger re-render when image is loaded
				setImageRef((prev) => (prev != null ? { ref: prev.ref } : null))
			}}
		/>
	) : null
}
