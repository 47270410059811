import { Atom, Provider, WritableAtom } from "jotai"
import { useHydrateAtoms } from "jotai/utils"

export const withJotaiProvider =
	<C extends (...args: any) => any>(Child: C) =>
	(...params: any) => {
		return (
			<Provider>
				<Child {...params} />
			</Provider>
		)
	}

export function HydrateAtoms({
	initialValues,
	children,
}: {
	initialValues: [WritableAtom<any, any, any>, any][]
	children: React.ReactNode
}) {
	useHydrateAtoms(initialValues)
	return children
}
