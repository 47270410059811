import { trpc } from "#/trpc"
import { reportUserError, reportUserSuccess } from "#/util"
import { useNavigate, useParams } from "react-router"
import { FormForm } from "../components/form"
import { Schema } from "../components/form/types"

export function FormEditFormPage() {
	const navigate = useNavigate()

	const uuid = useParams().uuid as string
	const { data } = trpc.v2_5.form.getOne.useQuery({
		uuid,
	})
	const { mutate } = trpc.v2_5.form.updateOne.useMutation({
		onSuccess() {
			navigate("/ecommerce-crm/forms")
			reportUserSuccess({
				title: "Form updated successfully,",
			})
		},
		onError(data) {
			reportUserError({
				title: "Failed to update form",
				message: data.message,
			})
		},
	})

	function handleSubmit(values: Schema) {
		mutate({
			uuid,
			title: values.title,
		})
	}

	return (
		<FormForm
			onSubmit={handleSubmit}
			mode="edit"
			defaultValues={{
				title: (data?.title as any) ?? "",
				discount: data?.discount ?? null,
				questions:
					data?.questions.map((q) => ({
						answerType: q.answerType,
						multiOptions: q.multiOptions as any,
						title: q.title as any,
					})) ?? [],
			}}
		/>
	)
}
