import { MantineController as Mc } from "#/components/Form/v3/index.js"
import { RouterPrompt } from "#/components/index.js"
import { RouterOutputs } from "#/trpc.js"
import { HydrateAtoms } from "#/util/jotai.js"
import { stateAtom, addFeesByWeightAtom } from "./state.js"
import * as M from "@mantine/core"
import { Provider, useAtom, useAtomValue } from "jotai"
import React from "react"
import {
	FormProvider,
	useFieldArray,
	useForm,
	useFormContext,
} from "react-hook-form"
import { MdClear } from "react-icons/md/index.js"
import { FeesType, FeesCategory } from "server"

export type InputRateCost = RouterOutputs["rateCost"]["create"]

const feeCategoryOptions = [
	{ label: "Freight", value: "FREIGHT" },
	{ label: "Tax", value: "TAX" },
	{ label: "Other", value: "OTHER" },
]

const feeAmountTypeOptions = [
	{ label: "Percentage", value: "PERCENTAGE" },
	{ label: "Fixed", value: "FIXED" },
]

const weightFeeCategoryOptions = [
	{ label: "Freight", value: "FREIGHT" },
	{ label: "Soldering", value: "SOLDERING" },
	{ label: "Plated", value: "PLATED" },
	{ label: "Labor", value: "LABOR" },
	{ label: "Other", value: "OTHER" },
]

const weightFeeTypeOptions = [
	{ label: "Fixed", value: "FIXED" },
	{ label: "Unit", value: "UNIT" },
]

export interface RateCostFormValues {
	id?: number
	name: string
	isWithWeight: boolean
	extendedByCost: {
		id_?: number
		exchangeRate: number
		fees: {
			id_?: number
			description: string
			amount: number
			type: FeesType
			category: FeesCategory
		}[]
	}
	extendedByWeight?: {
		id_?: number
		taxCostPercentage: number
		fees: {
			id_?: number
			description: string
			amount: number
			type: FeesType
			category: FeesCategory
		}[]
	}
	utilityRangeMin: number
	utilityRangeMax: number
}

export interface RateCostFormProps {
	onSubmit: (formValues: RateCostFormValues) => void
	defaultValues: RateCostFormValues
	loading: boolean
}

const RateCostFormInner = ({
	onSubmit,
	defaultValues,
	loading,
}: RateCostFormProps) => {
	const { id, ...defaultFormValues } = defaultValues
	const form = useForm({
		defaultValues: defaultFormValues,
	})
	const addFeesByWeight = useAtomValue(addFeesByWeightAtom)

	const [submitted, setSubmitted] = React.useState(false)
	return (
		<M.Container size="lg" p={18}>
			<RouterPrompt when={!submitted} />
			<M.Title order={2}>Rate cost</M.Title>
			<form
				onSubmit={form.handleSubmit((formValues) => {
					setSubmitted(true)
					onSubmit({
						...formValues,
						isWithWeight: (addFeesByWeight as boolean) ?? false,
					})
				})}
			>
				<FormProvider {...form}>
					<M.Box
						mt={12}
						p={18}
						sx={(t) => ({ borderRadius: t.radius.md, background: "white" })}
					>
						<M.Stack spacing="xl">
							<M.Group noWrap>
								<Mc
									wrap={M.TextInput}
									name="id"
									label="Reference code"
									value={id}
									readOnly
									variant="filled"
									styles={{ input: { cursor: "default" } }}
								/>
								<Mc
									wrap={M.TextInput}
									name="name"
									label="Rate cost reference"
									required
								/>
								<Mc
									wrap={M.NumberInput}
									min={0}
									precision={2}
									name="extendedByCost.exchangeRate"
									label="Exchange rate"
									required
								/>
								<Mc
									wrap={M.NumberInput}
									min={0}
									precision={2}
									name="utilityRangeMin"
									label="Profit range (min)"
									required
								/>
								<Mc
									wrap={M.NumberInput}
									min={0}
									precision={2}
									name="utilityRangeMax"
									label="Profit range (max)"
									required
								/>
							</M.Group>
							<M.Divider />
							<Fees />
							{(addFeesByWeight as boolean) && (
								<>
									<M.Divider />
									<FeesByWeight />
									<M.Divider />
									<M.Box>
										<M.Title order={3} mb={18}>
											Tax
										</M.Title>
										<M.Group noWrap>
											<Mc
												wrap={M.NumberInput}
												name="extendedByWeight.taxCostPercentage"
												label="Tax %"
												min={0}
												placeholder="0"
												required
											/>
										</M.Group>
									</M.Box>
								</>
							)}
							<M.Group position="right">
								<M.Button
									type="submit"
									sx={{ minWidth: "12ch" }}
									loading={loading}
								>
									Save
								</M.Button>
							</M.Group>
						</M.Stack>
					</M.Box>
				</FormProvider>
			</form>
		</M.Container>
	)
}

export const RateCostForm = (props: RateCostFormProps) => {
	return (
		<Provider>
			<HydrateAtoms
				initialValues={[
					[stateAtom, { addFeesByWeight: props.defaultValues.isWithWeight }],
				]}
			>
				<RateCostFormInner {...props} />
			</HydrateAtoms>
		</Provider>
	)
}

const Fees = () => {
	const [addFeesByWeight, setAddFeesByWeight] = useAtom(addFeesByWeightAtom)

	const namePrefix = "extendedByCost.fees"
	const { control } = useFormContext()
	const list = useFieldArray({
		control,
		name: namePrefix,
	})

	return (
		<M.Box>
			<M.Title order={3} mb={18}>
				Fees
			</M.Title>
			<M.Stack>
				{list.fields.map((field, index) => (
					<M.Group noWrap key={field.id} align="start">
						<Mc
							as={M.Textarea}
							name={`${namePrefix}.${index}.description`}
							label="Description"
							required
							autosize
							minRows={1}
							maxRows={8}
							sx={{ width: "40%" }}
						/>
						<Mc
							wrap={M.Select}
							name={`${namePrefix}.${index}.category`}
							label="Fee category"
							data={feeCategoryOptions}
							required
						/>
						<Mc
							wrap={M.NumberInput}
							name={`${namePrefix}.${index}.amount`}
							label="Amount"
							min={0}
							precision={2}
							required
						/>
						<Mc
							wrap={M.Select}
							name={`${namePrefix}.${index}.type`}
							label="Type"
							data={feeAmountTypeOptions}
							required
						/>
						<M.ActionIcon
							onClick={() => list.remove(index)}
							sx={{ alignSelf: "end" }}
						>
							<MdClear />
						</M.ActionIcon>
					</M.Group>
				))}
			</M.Stack>
			<M.Stack align="start" spacing="lg" mt={list.fields.length > 0 ? 24 : 0}>
				<M.Button
					onClick={() =>
						list.append({
							id_: undefined,
							description: "",
							amount: 0,
							category: "FREIGHT",
							type: "PERCENTAGE",
						})
					}
				>
					Add fee
				</M.Button>
				<M.Checkbox
					label="Add fees by weight?"
					checked={(addFeesByWeight as boolean) ?? false}
					onChange={(e) => setAddFeesByWeight(e.currentTarget.checked)}
				/>
			</M.Stack>
		</M.Box>
	)
}

const FeesByWeight = () => {
	const namePrefix = "extendedByWeight.fees"
	const { control } = useFormContext()
	const list = useFieldArray({
		control,
		name: namePrefix,
	})

	return (
		<M.Box>
			<M.Title order={3} mb={18}>
				Fees by weight
			</M.Title>
			<M.Stack>
				{list.fields.map((field, index) => (
					<M.Group noWrap key={field.id} align="start">
						<Mc
							as={M.Textarea}
							name={`${namePrefix}.${index}.description`}
							label="Description"
							autosize
							minRows={1}
							maxRows={8}
							required
							sx={{ width: "40%" }}
						/>
						<Mc
							wrap={M.Select}
							name={`${namePrefix}.${index}.category`}
							label="Fee category"
							data={weightFeeCategoryOptions}
							required
						/>
						<Mc
							wrap={M.NumberInput}
							name={`${namePrefix}.${index}.amount`}
							label="Amount"
							min={0}
							precision={2}
							required
						/>
						<Mc
							wrap={M.Select}
							name={`${namePrefix}.${index}.type`}
							label="Type"
							data={weightFeeTypeOptions}
							required
						/>
						<M.ActionIcon sx={{ alignSelf: "end" }}>
							<MdClear onClick={() => list.remove(index)} />
						</M.ActionIcon>
					</M.Group>
				))}
			</M.Stack>
			<M.Button
				mt={list.fields.length > 0 ? 24 : 0}
				onClick={() =>
					list.append({
						id_: undefined,
						description: "",
						amount: 0,
						category: "FREIGHT",
						type: "FIXED",
					})
				}
			>
				Add fee by weight
			</M.Button>
		</M.Box>
	)
}
