import { makeController } from "#/components-ng/index.js"
import { z } from "zod"

export const generateCustomersReportSchema = z.object({
	filter: z
		.discriminatedUnion("by", [
			z.object({
				by: z.literal("all"),
        origins: z.array(z.enum(["POS", "ECOMMERCE"])).default([]),
			}),
			z.object({
				by: z.literal("origin"),
				origins: z.array(z.enum(["POS", "ECOMMERCE"])),
			}),
		])
		.default({ by: "all" }),
	years: z.array(z.number().int().min(2022).max(new Date().getFullYear())),
})

export type FormValues = z.infer<typeof generateCustomersReportSchema>
export const C = makeController<FormValues>()
