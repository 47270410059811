import Decimal from "decimal.js"

export type I18NString = {
	en: string
	es: string
}

export interface Schema {
	title: I18NString
	questions: Array<QuestionSchema>
	discount: null | {
		id: number
		reference: string
		amount: Decimal
		type:
			| "PERCENTAGE"
			| "AMOUNT"
			| "SHIPPING_PERCENTAGE"
			| "ORDER_SUBTOTAL_PERCENTAGE"
			| "ORDER_SUBTOTAL_AMOUNT"
	}
}

export type QuestionSchema = {
	title: I18NString
	answerType: AnswerType
	multiOptions: Array<I18NString>
}

export const AnswerType = {
	Text: "TEXT",
	MultiOption: "MULTI_OPTION",
	Rating: "RATING",
} as const
export type AnswerType = (typeof AnswerType)[keyof typeof AnswerType]
