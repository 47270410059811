import { DisplayTable } from "#/components/index.js";
import { useAuth } from "#/context/AuthContext.js";
import { reportUserError } from "#/util/index.js";
import { applyDiscountV2 } from "../util/index.js";
import { filialAtom } from "./state/filial.js";
import { cartEntriesAtom, lastCartEntryAddedIndexAtom } from "./state/index.js";
import * as C from "@chakra-ui/react";
import * as M from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useAtom, useAtomValue } from "jotai/react";
import { uniqBy } from "lodash";
import React from "react";
import { MdOutlineClear } from "react-icons/md/index.js";

export function CartTable() {
  const [{ auth }] = useAuth();
  const lastCartEntryAddedIndex = useAtomValue(lastCartEntryAddedIndexAtom);
  const [cartEntries, dispatch] = useAtom(cartEntriesAtom);
  const filial = useAtomValue(filialAtom);
  const processedCartEntries = useMemo(
    () =>
      cartEntries
        .map((entry, index) => {
          return {
            ...entry,
            availableDiscounts: entry.availableDiscounts,
            availablePromotions: entry.availablePromotions,
            setQty: (qty: number) =>
              dispatch({ type: "setQty", payload: { index, qty } }),
            setDiscount: (discount: any) =>
              dispatch({ type: "setDiscount", payload: { index, discount } }),
            remove: () => dispatch({ type: "remove", payload: index }),
          };
        })
        .map((entry) => {
          const itemSkuStock = entry.itemWithVariant.itemSku.itemSkuStock.find(
            (itemSkuStock) => itemSkuStock.filialId === auth?.user?.filialId,
          );

          return {
            ...entry,
            subtotal: applyDiscountV2(
              filial?.type === "WAREHOUSE"
                ? entry.itemWithVariant.itemSku.cost
                : entry.itemWithVariant.itemSku.price,
              entry.discount,
            ),
            total: applyDiscountV2(
              filial?.type === "WAREHOUSE"
                ? entry.itemWithVariant.itemSku.cost.times(entry.quantity)
                : entry.itemWithVariant.itemSku.price.times(entry.quantity),
              entry.discount,
            ),
            __idleBg:
              itemSkuStock &&
              entry.quantity > itemSkuStock.quantity - itemSkuStock.holdStock
                ? "#F6B93B1A"
                : undefined,
          };
        }),
    [auth?.user?.filialId, cartEntries, dispatch, filial?.type],
  );

  const columns = React.useMemo(() => makeColumns(), []);

  return (
    <M.Box>
      <DisplayTable
        enableMemoization
        columns={columns}
        data={processedCartEntries}
        highlightedRow={lastCartEntryAddedIndex}
        display="block"
        pagination={false}
      />
    </M.Box>
  );
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  type: string;
  label: string;
  description: string;
}

// eslint-disable-next-line react/display-name
const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ type, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <M.Group noWrap spacing="xs">
        <M.Avatar
          radius="xl"
          size="sm"
          color={type === "D" ? "pink" : "violet"}
        >
          {type}
        </M.Avatar>
        <M.Text size="sm">{label}</M.Text>
      </M.Group>
    </div>
  ),
);

const makeColumns = () => [
  {
    Header: "Image",
    accessor: "itemWithVariant.itemSku.defaultImage",
    type: "image",
  },
  {
    Header: "Title",
    accessor: "itemWithVariant.itemSku.title",
    Cell: ({ value, row: { original } }) => (
      <C.Text as="span" color={original.returned ? "red.500" : undefined}>
        {value}
      </C.Text>
    ),
  },
  {
    Header: "SKU",
    accessor: "itemWithVariant.itemSku.sku",
    isNumeric: true,
    Cell: ({ value, row: { original } }) => (
      <C.Text as="span" color={original.returned ? "red.500" : undefined}>
        {value}
      </C.Text>
    ),
  },
  {
    Header: "Location",
    accessor: "itemWithVariant.itemSku.itemSkuStock.0.storeLocation",
    Cell: ({ value, row: { original } }) => (
      <C.Text as="span" color={original.returned ? "red.500" : undefined}>
        {value}
      </C.Text>
    ),
  },
  {
    Header: "PKG",
    accessor: "itemWithVariant.itemSku.presentationValue",
    Cell: ({ value, row: { original } }) => (
      <C.Text as="span" color={original.returned ? "red.500" : undefined}>
        {value} {original.itemWithVariant.itemSku.presentationType}
      </C.Text>
    ),
  },
  {
    Header: "QTY",
    accessor: "quantity",
    isNumeric: true,
    Cell: ({ value, row: { original } }) => {
      const filial = useAtomValue(filialAtom);
      const checkIsStoreFilial =
        filial && filial.name?.toLocaleLowerCase()?.includes("store");

      const maxQty =
        original.itemWithVariant.itemSku.itemSkuStock?.find(
          (s: any) => s.filialId === filial?.id,
        )?.quantity ?? 0;

      return original.returned ? (
        <C.Text color="red.500">{value}</C.Text>
      ) : (
        <M.NumberInput
          size="sm"
          min={0}
          max={!checkIsStoreFilial ? maxQty : undefined}
          miw="8ch"
          maw="10ch"
          value={
            !checkIsStoreFilial ? (value <= maxQty ? value : maxQty) : value
          }
          c={original.returned ? "red.500" : undefined}
          onChange={(v) => {
            if (v) {
              if (v && v > maxQty && !checkIsStoreFilial) {
                reportUserError({
                  title: `The Sku ${original.itemWithVariant.itemSku.sku} Could Not Have Stock`,
                  message: "The Item Could Not have Stock",
                });
              }
              original.setQty(v ?? maxQty);
            }
          }}
          readOnly={original.returned}
          styles={() => ({
            input: {
              fontSize: "0.8rem",
            },
          })}
        />
      );
    },
  },
  {
    Header: "Discount",
    accessor: "discount.reference",
    $memoizeBy: (prevCell: any, nextCell: any) => {
      const prevOrig = prevCell.row.original;
      const nextOrig = nextCell.row.original;

      return (
        prevOrig.availableDiscounts === nextOrig.availableDiscounts &&
        prevOrig.availablePromotions === nextOrig.availablePromotions &&
        prevOrig?.discount?.id === nextOrig?.discount?.id
      );
    },
    Cell: ({ row: { original } }) => {
      const availableDiscountsData = uniqBy(
        [
          ...original.availableDiscounts.map((d: any) => ({
            type: "D",
            discount: d,
          })),
          ...original.availablePromotions.map((p: any) => ({
            type: "P",
            discount: p.discount,
          })),
        ] as any[],
        (d) => d.discount.id,
      ).map((e) => ({
        label: `${e.discount.type === "AMOUNT" ? "$" : ""}${e.discount.amount}${
          e.discount.type === "PERCENTAGE" ? "%" : ""
        } - ${e.discount.reference}`,
        value: e.discount.id,
        type: e.type,
        discount: e.discount,
      }));

      const openConfirmDiscountChange = (discountCandidate: any) =>
        openConfirmModal({
          title: "Are you sure you want to change the discount?",
          labels: { confirm: "Change discount", cancel: "Cancel" },
          onConfirm: () => original.setDiscount(discountCandidate),
          confirmProps: { color: "red" },
          zIndex: 1600,
        });

      return (
        <M.Select
          data={availableDiscountsData}
          clearable
          itemComponent={SelectItem}
          value={original.discount?.id ?? null}
          zIndex={2000}
          onChange={(newId) => {
            openConfirmDiscountChange(
              availableDiscountsData.find((d: any) => d.value === newId)
                ?.discount ?? null,
            );
          }}
          styles={{
            dropdown: {
              width: "auto !important",
              maxWidth: "auto !important",
            },
            itemsWrapper: {
              width: "auto",
              maxWidth: 400,
            },
          }}
        />
      );
    },
  },
  {
    Header: "Subtotal",
    accessor: "itemWithVariant.itemSku.price",
    styles: {
      width: "4ch",
    },
    Cell: ({ value, row: { original } }) => {
      const filial = useAtomValue(filialAtom);

      const price =
        filial?.type === "WAREHOUSE"
          ? original.itemWithVariant.itemSku.cost
          : value;

      return (
        <C.Text
          as="span"
          display="inline-block"
          color={original.returned ? "red.500" : undefined}
        >{`${original.returned ? "-" : ""}$${price.toFixed(2)}`}</C.Text>
      );
    },
    PrintCell: "money",
  },
  {
    Header: "Total",
    accessor: "total",
    styles: {
      width: "4ch",
    },
    Cell: ({ value, row: { original } }) => (
      <C.Text
        as="span"
        display="inline-block"
        color={original.returned ? "red.500" : undefined}
      >{`${original.returned ? "-" : ""}$${value.toFixed(2)}`}</C.Text>
    ),
    PrintCell: "money",
  },
  {
    Header: "",
    id: "delete",
    skipPrint: true,
    Cell: ({ row: { original } }) => {
      return (
        <C.IconButton
          icon={<MdOutlineClear fontSize="inherit" />}
          aria-label="remove product"
          size="xs"
          fontSize="lg"
          variant="ghost"
          colorScheme="red"
          onClick={() => {
            original.remove();
          }}
        />
      );
    },
  },
];
