import { HTMLStyledProps, S, SystemStyleObject, css } from "#/s"
import { ComponentPropsWithoutRef } from "react"

export function PlaceholderButton(props: HTMLStyledProps<"button">) {
	const { css: cssProp, onClick, ...restProps } = props

	function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
		if (props.disabled) return
		onClick?.(e)
	}

	return (
		<S.button
			type="button"
			className={css(
				{
					border: "dashed 2px token(colors.slate.300)",
					h: "auto",
					py: 6,
					px: 4,
					fs: "sm",
					fw: "medium",
					bg: {
						base: "transparent",
						_hover: "slate.100",
					},
					c: {
						base: "slate.500",
						_hover: "slate.700",
					},
					rounded: "md",
				},
				props.css,
			)}
			onClick={handleClick}
			{...restProps}
		>
			{props.children}
		</S.button>
	)
}
