import { CheckmarkOutline } from "#/components-ng"
import { css, S } from "#/s"
import { trpc } from "#/trpc"
import * as M from "@mantine/core"
import React, { useState } from "react"
import { match, P } from "ts-pattern"
import { couponAtom, customerAtom, seasonalDiscountAtom } from "./state"
import { reportUserError } from "#/util/ux"

export function CouponField() {
	const [input, setInput] = useState("")
	const customer = useAtomValue(customerAtom)

	const [, setCoupon] = useAtom(couponAtom)
	const [, setDiscount] = useAtom(seasonalDiscountAtom)

	const tctx = trpc.useContext()

	async function retrieveDiscountOrCoupon(code: string) {
		const data = await tctx.v2_5.discounts.getDiscountOrCoupon.fetch({
			code,
			customerId: customer?.id,
		})
		match(data)
			.with({ type: "coupon" }, (data) => {
				setCoupon(data.coupon)
			})
			.with({ type: "userDiscount" }, (data) => {
				setDiscount(data.userDiscount)
			})
			.with(P.nullish, () => {
				reportUserError({
					title: "Coupon not found",
					message: "A coupon with this code was not found",
				})
			})
			.exhaustive()
	}

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		retrieveDiscountOrCoupon(input)
		e.preventDefault()
	}

	function handleCouponChange(e: React.ChangeEvent<HTMLInputElement>) {
		const newValue = e.currentTarget.value.toUpperCase()
		setInput(newValue)
	}

	return (
		<S.form
			d="flex"
			justifyContent="space-between"
			alignItems="end"
			gap="2"
			onSubmit={handleSubmit}
		>
			<M.TextInput
				label="Coupon"
				placeholder="CODE123"
				value={input}
				onChange={handleCouponChange}
				classNames={{
					root: css({
						flex: "1",
					}),
				}}
			/>
			<M.ActionIcon type="submit" size="lg">
				<CheckmarkOutline />
			</M.ActionIcon>
		</S.form>
	)
}
