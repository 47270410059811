import { PrimitiveAtom } from "jotai/vanilla"
import { markersAtom, MarkerState } from "./state"
import { useSetAtom } from "jotai/react"
import { CloseOutline } from "#/components-ng"
import { circle } from "#/css/patterns"

export interface MarkerRemoverProps {
	atom: PrimitiveAtom<MarkerState>
}

export function MarkerRemover(props: MarkerRemoverProps) {
	const setMarkers = useSetAtom(markersAtom)

	function handleClick() {
		// Remove the marker
		setMarkers((prev) => prev.filter((atom) => atom !== props.atom))
	}

	return (
		<button
			onClick={handleClick}
			className={circle({
				bg: "red.500",
				w: "10px",
				h: "10px",
				pos: "absolute",
				left: "50%",
				top: "-5px",
				translateX: "-50%",
				translate: "auto",
				zIndex: 1,
				cursor: "pointer",
			})}
		>
			<CloseOutline color="white" />
		</button>
	)
}
