import dayjs from "dayjs";
import { useState, useMemo } from "react";
import { C, type FormValues, generateDashboardByEmployeeSchema } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { type RouterOutputs, trpc } from "#/trpc";
import { reportUserError, reportUserSuccess } from "#/util";
import { css, S } from "#/s";
import { FormProvider } from "react-hook-form";
import { Select } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { Button } from "@gt/ui";
import {
	MantineReactTable,
	type MRT_ColumnDef,
	useMantineReactTable,
} from "mantine-react-table";
import { generateCsv } from "#/util/csv";
import FileSaver from "file-saver";

export const DashboardByEmployee = () => {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		dayjs().startOf("month").toDate(),
		dayjs().endOf("month").toDate(),
	]);

	const form = C.useForm({
		resolver: zodResolver(generateDashboardByEmployeeSchema),
		shouldUnregister: false,
		defaultValues: {
			dateRange: [dateRange[0]!, dateRange[1]!],
			employeeId: null,
		},
	});

	const { data: users } = trpc.user.getAll.useQuery(
		{
			filialId: null,
		},
		{
			refetchOnWindowFocus: false,
		},
	);

	const {
		data: report,
		mutate,
		isLoading,
	} = trpc.v2_5.report.getDashboardByEmployee.useMutation();

	const usersOptions = useMemo(
		() =>
			users?.map((user) => ({
				label: `${user.firstName} ${user.lastName ?? ""}`,
				value: user.id.toString(),
			})) ?? [],
		[users],
	);

	const handleSubmit = (values: FormValues) => {
		if (!values.employeeId) {
			reportUserError({
				title: "Error",
				message: "Please select an employee",
			});
			return false;
		}

		mutate({
			startDate: values.dateRange[0]!,
			endDate: values.dateRange[1]!,
			employeeId: Number(values.employeeId),
		});
	};

	return (
		<S.div gap="1rem" d="flex" flexDirection="column">
			<S.h2>Dashboard by Employee</S.h2>
			<S.hr />
			<FormProvider {...form}>
				<form
					onSubmit={(e) => {
						e.stopPropagation();
						form.handleSubmit(handleSubmit)(e);
					}}
				>
					<S.div d="flex" gap="1rem">
						<C.M
							as={Select}
							label="Employee"
							name="employeeId"
							data={usersOptions}
							required
							searchable
							clearable
						/>
						<C.M
							as={DatePickerInput}
							label="Date range"
							name="dateRange"
							type="range"
							onChange={(v) => {
								if (!v) return;
								if (v[0] == null || v[1] == null) return;
								setDateRange([v[0], v[1]]);
							}}
							numberOfColumns={2}
							popoverProps={{
								zIndex: 9000,
							}}
							w={300}
							required
						/>
						<Button
							type="submit"
							isLoading={isLoading}
							className={css({
								marginTop: "1.25rem",
								paddingX: "3rem",
							})}
						>
							Generate
						</Button>
					</S.div>
				</form>
			</FormProvider>
			<S.hr />
			{report || isLoading ? (
				<>
					<CustomersTable
						title="Customers attended"
						description="List of customers assisted by the employee"
						data={report?.summary?.customerAttendees ?? []}
						isLoading={isLoading}
						asocciated={report?.employee?.name ?? ""}
					/>
					<S.hr />
					<CustomersTable
						title="Most Frequent Customers"
						description="List of customers served more than once by the employee"
						data={report?.summary?.customersMostFrequent ?? []}
						isLoading={isLoading}
						asocciated={report?.employee?.name ?? ""}
					/>
					<S.hr />
					<CustomersTable
						title="Customers with Oldest Orders"
						description="List of customers whose last order was placed more than 15 days ago within the selected date range."
						data={report?.summary?.customersMostOldest ?? []}
						isLoading={isLoading}
						asocciated={report?.employee?.name ?? ""}
					/>
				</>
			) : (
				<S.p fs="14px" color="neutral.700">
					Please select an employee and a date range to generate the report.
				</S.p>
			)}
		</S.div>
	);
};

interface CustomersTableProps {
	data: CustomerAttend[];
	isLoading: boolean;
	title: string;
	description: string;
	asocciated: string;
}

const CustomersTable = (props: CustomersTableProps) => {
	const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);

	const table = useMantineReactTable({
		data: props.data ?? [],
		columns: columns,
		enableTopToolbar: false,
		state: {
			isLoading: props.isLoading,
		},
	});

	const handleDownloadCsv = async () => {
		setIsLoadingDownload(true);

		const headers = [
			{
				label: "Customer Name",
				value: "name",
			},
			{
				label: "Total Orders Attended",
				value: "totalOrdersAttended",
			},
			{
				label: "Total Amount",
				value: "totalAmount",
			},
			{
				label: "Last Order",
				value: "lastOrder",
			},
		];

		const csv = await generateCsv({
			data: props.data,
			headers: headers,
		});

		const fileName = `${props.title.toLowerCase().replace(/\s/g, "_")}_${props.asocciated}`;
		setIsLoadingDownload(false);
		FileSaver.saveAs(new Blob([csv], { type: "text/csv" }), `${fileName}.csv`);

		reportUserSuccess({
			title: "The csv file has been exported, download should start soon",
		});
	};

	return (
		<>
			<S.div
				d="flex"
				alignItems="center"
				gap="1rem"
				justifyContent="space-between"
			>
				<S.div d="flex" gap="1rem" flexDir="column">
					<S.h3>{props.title}</S.h3>
					<S.p color="neutral.500" fs="13px">
						{props.description}
					</S.p>
				</S.div>
				<Button
					variant="outline"
					isLoading={isLoadingDownload}
					onClick={handleDownloadCsv}
				>
					Download CSV
				</Button>
			</S.div>
			<MantineReactTable table={table} />
		</>
	);
};

type CustomerAttend =
	RouterOutputs["v2_5"]["report"]["getDashboardByEmployee"]["summary"]["customerAttendees"][number];

const columns: MRT_ColumnDef<CustomerAttend>[] = [
	{
		id: "name",
		accessorKey: "name",
		header: "Customer Name",
	},
	{
		id: "totalOrdersAttended",
		accessorKey: "totalOrdersAttended",
		header: "Total Orders Attended",
	},
	{
		id: "totalAmount",
		accessorKey: "totalAmount",
		header: "Total Amount",
		Cell: (table) => {
			return `$${table.row.original.totalAmount}`;
		},
	},
	{
		id: "lastOrder",
		accessorKey: "lastOrder",
		header: "Last Order",
		enableSorting: false,
		Cell: (table) => {
			const createdAt = table.row.original.lastOrder;
			if (!createdAt) return "";
			return dayjs(createdAt).format("MM/DD/YYYY").toString();
		},
	},
];
