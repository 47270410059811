import { styled } from "#/css/jsx"
import { css } from "#/css/css"
import { trpc } from "#/trpc"
import { useMemo, useState } from "react"
import { C, type FormValues, generateGeneralSalesReportSchema } from "./types"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useFormContext } from "react-hook-form"
import { LoadingOverlay, Select } from "@mantine/core"
import { Button } from "@gt/ui"
import { LinearScaleChart, MultiSelect } from "#/components-ng"
import GTLoader from "#/components-ng/loader"
import { generateRandomHexColor } from "#/util/color"

type OptionMultiselect = {
	id: number
	name: string
}

export const GeneralSalesReport = () => {
	const { data: entities } = trpc.entity.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})
	const { data: filials } = trpc.filial.getAll.useQuery(undefined, {
		refetchOnWindowFocus: false,
	})

	const form = C.useForm({
		resolver: zodResolver(generateGeneralSalesReportSchema),
		shouldUnregister: false,
		defaultValues: {
			filter: {
				by: "entity",
				ids: [1],
			},
			years: [new Date().getFullYear()],
		},
	})

	const {
		data: report,
		mutate: generateReport,
		isLoading,
	} = trpc.v2_5.report.getGeneralSalesReport.useMutation()

	const entityOptions = useMemo(
		() =>
			entities?.map((entity) => ({
				name: entity.name,
				id: entity.id,
			})) ?? [],
		[entities],
	)

	const filialOptions = useMemo(
		() =>
			filials?.map((filial) => ({
				name: filial.name,
				id: filial.id,
			})) ?? [],
		[filials],
	)

	const data = useMemo(() => {
		if (report) {
			return (
				report?.map((d) => {
					const color = generateRandomHexColor()

					const filialName = filials?.find((f) => f.id === d.filialId)?.name

					return {
						label: `${d.year.toString()}${filialName ? ` - ${filialName}` : ""}`,
						backgroundColor: color,
						borderColor: color,
						data: d.months.map((m) => m.totalOrderAmount ?? 0),
					}
				}) ?? []
			)
		}
		return []
	}, [report])

	const handleSubmit = (values: FormValues) => {
		generateReport({
			filter: {
				by: values.filter.by,
				ids: values.filter.ids,
			},
			years: values.years,
		})
	}

	const filterBy = form.watch("filter.by")

	return (
		<styled.div gap="1rem" display="flex" flexDirection="column">
			<styled.h2>General Sales Report</styled.h2>

			<FormProvider {...form}>
				<form
					onSubmit={(e) => {
						e.stopPropagation()
						form.handleSubmit(handleSubmit)(e)
					}}
				>
					<styled.div display="flex" gap="1rem">
						<C.M
							as={Select}
							label="Report type"
							name="filter.by"
							data={[
								{ label: "Entity", value: "entity" },
								{ label: "Filial", value: "filial" },
							]}
							required
						/>
						<EntityFilialMultiselect
							label={filterBy === "entity" ? "Select entity" : "Select filial"}
							options={filterBy === "entity" ? entityOptions : filialOptions}
							placeholder={
								filterBy === "entity" ? "Select entity..." : "Select filial..."
							}
						/>
						<YearsMultiselect />

						<Button
							type="submit"
							isLoading={isLoading}
							className={css({
								marginTop: "1.25rem",
								paddingX: "3rem",
							})}
						>
							Generate
						</Button>
					</styled.div>
				</form>
			</FormProvider>
			{isLoading && (
				<LoadingOverlay
					visible={isLoading}
					loader={<GTLoader width={100} height={100} />}
				/>
			)}
			{data && !isLoading && (
				<>
					<styled.hr />
					<LinearScaleChart
						datasets={data}
						labels={months}
						includeTitle={false}
						includeLegend={true}
					/>
				</>
			)}
		</styled.div>
	)
}

const YearsMultiselect = () => {
	const form = useFormContext()
	const years = form.watch("years")

	const [selectedYears, setSelectedYears] = useState<OptionMultiselect[]>(
		years.map((year) => ({
			id: year,
			name: year.toString(),
		})) ?? [],
	)

	const yearOptions = useMemo(() => {
		const currentYear = new Date().getFullYear()
		const years: OptionMultiselect[] = []
		for (let i = 2022; i <= currentYear; i++) {
			years.push({
				id: i,
				name: i.toString(),
			})
		}
		return years
	}, [])

	const handleYearsChange = (years: OptionMultiselect[]) => {
		form.setValue(
			"years",
			years.map((year) => year.id),
		)
		setSelectedYears(years)
	}

	return (
		<MultiSelect
			label="Years"
			data={yearOptions}
			entryId={(e) => e.id.toString()}
			entryLabel={(e) => e.name}
			onChange={handleYearsChange}
			placeholder="Select years..."
			value={selectedYears}
		/>
	)
}

interface EntityFilialMultiselectProps {
	label: string
	options: OptionMultiselect[]
	placeholder?: string
}

const EntityFilialMultiselect = (props: EntityFilialMultiselectProps) => {
	const form = useFormContext()
	const ids = form.watch("filter.ids")
	const filterBy = form.watch("filter.by")

	const [selectedIds, setSelectedIds] = useState<OptionMultiselect[]>(
		ids.map((id) => ({ id, name: id.toString() })) ?? [],
	)

	useEffect(() => {
		if (filterBy === "entity") {
			setSelectedIds([{ id: 1, name: "1" }])
		}
	}, [filterBy])

	const handleEntityFilialChange = (ids: OptionMultiselect[]) => {
		form.setValue(
			"filter.ids",
			ids.map((id) => id.id),
		)
		setSelectedIds(ids)
	}

	return (
		<MultiSelect
			label={props.label}
			data={props.options}
			entryId={(e) => e.id.toString()}
			entryLabel={(e) => e.name}
			onChange={handleEntityFilialChange}
			placeholder={props.placeholder}
			value={selectedIds}
		/>
	)
}

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]
