import {
	CallOutline,
	MailOutline,
	UserCreditsPaymentMethodCustomIcon,
} from "#/components-ng/index.js";
import { DisplayTable } from "#/components/index.js";
import { cn } from "#/lib/utils.js";
import { trpc } from "#/trpc.js";
import { paymentMethodsMap } from "../MakeASale/payment-methods-map.js";
import { applyDiscountV2 } from "../util/index.js";
import * as M from "@mantine/core";
import { useMemo } from "react";
import { useParams } from "react-router";
import { styled } from "#/css/jsx";
import { PrintAllLabels } from "../components/print-all-labels.js";
import { S } from "#/s.js";

export function SalePreview() {
	const params = useParams();
	const saleId = Number((params as any).id);
	const saleQuery = trpc.order.getById.useQuery({
		id: saleId,
	});
	const sale = saleQuery.data;
	const customer = sale?.customer;
	const tableData = useMemo(
		() =>
			saleQuery.data?.orderItemSku.map((item) => ({
				...item,
				total: applyDiscountV2(
					item.price.mul(item.quantity),
					item.discountAmount
						? {
								amount: item.discountAmount,
								type: item.discountType!,
							}
						: null,
				),
			})) ?? [],
		[saleQuery.data?.orderItemSku],
	);

	return (
		<div className="grid gap-y-8">
			<div className="grid grid-cols-[auto_1fr] gap-x-6 rounded-md bg-white p-6">
				<M.Avatar
					src={customer?.avatar}
					className="row-span-2 aspect-square h-12 w-auto rounded-full"
					color="indigo"
				>
					{customer?.firstName[0]}
					{customer?.lastName?.[1] ?? ""}
				</M.Avatar>
				<p className="font-medium">
					{customer?.firstName} {customer?.lastName ?? ""}
				</p>
				<div className="flex flex-wrap items-end gap-x-6 text-sm">
					<div className="flex items-center gap-x-2 text-slate-500">
						<MailOutline />
						<span>{customer?.email}</span>
					</div>
					<div className="flex items-center gap-x-2 text-slate-500">
						<CallOutline />
						<span>
							{customer?.areaCode} {customer?.phoneNumber}
						</span>
					</div>
				</div>
			</div>
			<S.div d="flex" w="full" justifyContent="flex-end">
				<PrintAllLabels
					itemSkus={
						saleQuery?.data?.orderItemSku?.map((o) => ({
							...o.itemSku,
							qty: o.quantity,
						})) ?? []
					}
					type="order"
				/>
			</S.div>
			<div className="max-h-[calc(100vh-300px)] overflow-scroll bg-white">
				<DisplayTable data={tableData} columns={columns} />
			</div>
			<div className="flex justify-end">
				<M.Accordion
					classNames={{
						control:
							"w-[36ch] max-w-full rounded bg-[#1ec997] font-medium text-white hover:bg-[#23d7a4]",
						panel: "mt-2",
						item: "border-none",
					}}
				>
					<M.Accordion.Item value="cost-breakdown">
						<M.Accordion.Control>
							<div className="flex justify-between font-medium">
								<p>Total</p>
								<p>${sale?.total.toFixed(2)}</p>
							</div>
						</M.Accordion.Control>
						<M.Accordion.Panel>
							<div className="grid gap-y-2">
								<div className="flex justify-between">
									<p>Subtotal</p>
									<p>${sale?.subTotal.toFixed(2)}</p>
								</div>
								<div className="flex justify-between">
									<p>Discount Applied</p>
									<p>
										{sale?.discountType === "AMOUNT"
											? `$${sale?.discountAmount?.toFixed(2)}`
											: sale?.discountType === "PERCENTAGE"
												? `${sale?.discountAmount?.toFixed(2)}%`
												: "N/A"}
									</p>
								</div>
								<div className="flex justify-between">
									<p>Tax</p>
									<p>
										{sale?.taxable ? `$${sale?.taxTotal.toFixed(2)}` : "N/A"}
									</p>
								</div>
								<div className="flex justify-between">
									<p>Shipping</p>
									<p>
										{sale?.orderShipping
											? `$${sale?.orderShipping?.shippingCost?.toFixed(2)}`
											: "N/A"}
									</p>
								</div>
								<M.Divider className="-mx-4 border-slate-200" />
								{sale?.orderPayment
									.filter((pm) => pm.paidIn.gt(0))
									.map((pm, i) => {
										const Icon =
											pm.paymentType !== "USER_CREDITS"
												? paymentMethodsMap[pm.paymentType].icon
												: UserCreditsPaymentMethodCustomIcon;
										return (
											<div key={i} className="flex justify-between">
												<div className="flex items-center gap-x-2">
													<div className="[&>svg]:w-12">
														<Icon />
													</div>
													<span>
														{pm.paymentType !== "USER_CREDITS"
															? paymentMethodsMap[pm.paymentType].text
															: "User Credits"}
													</span>
												</div>
												<p>${pm.paidIn.sub(pm.paidOut).toFixed(2)}</p>
											</div>
										);
									})}
								<M.Divider className="-mx-4 border-slate-200" />
								<p className="font-medium">Change</p>
								{sale?.orderPayment
									.filter((pm) => pm.paidOut.gt(0))
									.map((pm, i) => {
										const Icon =
											pm.paymentType !== "USER_CREDITS"
												? paymentMethodsMap[pm.paymentType].icon
												: UserCreditsPaymentMethodCustomIcon;
										return (
											<div key={i} className="flex justify-between">
												<div className="flex items-center gap-x-2">
													<div className="[&>svg]:w-12">
														<Icon />
													</div>
													<span>
														{pm.paymentType !== "USER_CREDITS"
															? paymentMethodsMap[pm.paymentType].text
															: "User Credits"}
													</span>
												</div>
												<p>${pm.paidIn.sub(pm.paidOut).toFixed(2)}</p>
											</div>
										);
									})}
							</div>
						</M.Accordion.Panel>
					</M.Accordion.Item>
				</M.Accordion>
			</div>
		</div>
	);
}

const columns = [
	{
		Header: "Image",
		accessor: "itemSku.defaultImage",
		type: "image",
	},
	{
		Header: "Title",
		accessor: "itemSku.title",
		Cell: ({ value, row: { original } }) => (
			<span className={cn(original.returned && "text-red-500")}>{value}</span>
		),
	},
	{
		Header: "SKU",
		accessor: "itemSku.sku",
		isNumeric: true,
		Cell: ({ value, row: { original } }) => (
			<span className={cn(original.returned && "text-red-500")}>{value}</span>
		),
	},
	{
		Header: "Location",
		accessor: "itemSku.itemSkuStock.0.storeLocation",
		Cell: ({ value, row: { original } }) => (
			<span className={cn(original.returned && "text-red-500")}>{value}</span>
		),
	},
	{
		Header: "PKG",
		accessor: "itemSku.presentationValue",
		Cell: ({ value, row: { original } }) => (
			<span className={cn(original.returned && "text-red-500")}>
				{value} {original.itemSku.presentationType}
			</span>
		),
	},
	{
		Header: "QTY",
		accessor: "quantity",
		isNumeric: true,
		Cell: ({ value, row: { original } }) => {
			return (
				<span className={cn(original.returned && "text-red-500")}>{value}</span>
			);
		},
	},
	{
		Header: "Discount",
		accessor: "discount.name",
		Cell: ({ row: { original } }) => {
			return original?.discountAmount ? (
				<>
					<styled.p
						fontSize="12px"
						className={cn(original.returned && "text-red-500")}
						textAlign="center"
					>
						{`${original?.discountType === "AMOUNT" ? "$" : ""}${
							original.discountAmount
						}${original?.discountType === "PERCENTAGE" ? "%" : ""}`}
					</styled.p>
					<styled.p
						fontSize="8px"
						className={cn(original.returned && "text-red-500")}
						textAlign="center"
					>
						{`${original?.discountReference}`}{" "}
					</styled.p>
					<styled.p fontSize="8px" color="red" textAlign="center">
						{original?.discountMode ? `(${original?.discountMode})` : ""}{" "}
					</styled.p>
				</>
			) : (
				"N/A"
			);
		},
	},
	{
		Header: "Subtotal",
		accessor: "price",
		styles: {
			width: "4ch",
		},
		Cell: ({ value, row: { original } }) => {
			return (
				<span className={cn(original.returned && "text-red-500")}>
					${value.toFixed(2)}
				</span>
			);
		},
		PrintCell: "money",
	},
	{
		Header: "Total",
		accessor: "total",
		styles: {
			width: "4ch",
		},
		Cell: ({ value, row: { original } }) => (
			<span className={cn(original.returned && "text-red-500")}>
				{original.returned && "-"}${value.toFixed(2)}
			</span>
		),
		PrintCell: "money",
	},
];
