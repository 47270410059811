import { ImageUpload } from "#/components-ng/ui"
import { useSetAtom } from "jotai/react"
import { imageSrcAtom } from "./state"

export function ImageField() {
	const setImageSrc = useSetAtom(imageSrcAtom)

	return (
		<ImageUpload
			directory="item-slots-guides"
			onS3UploadSuccess={(data) => setImageSrc(data.url)}
		/>
	)
}
