import { useAuth } from "#/context/AuthContext.js";
import { trpc } from "#/trpc.js";
import { reportUserError } from "#/util/index.js";
import {
  InventoryTransferForm,
  type valuesDefaultInventoryTransfer,
} from "./form/index.js";
import { resetItemsAtom } from "./form/state.js";
import { useSetAtom } from "jotai";
import React from "react";
import { useNavigate } from "react-router";

export const CreateInventoryTransfer = () => {
  const [{ auth }] = useAuth();
  const navigate = useNavigate();
  const resetItems = useSetAtom(resetItemsAtom);

  const { mutate, isLoading } = trpc.inventoryTransfer.create.useMutation({
    onError(error) {
      reportUserError({
        title: "Failed to create inventory transfer",
        message: error.message,
      });
    },
    onSuccess() {
      navigate("/inventory/inventory-transfer");
      resetItems();
    },
  });

  const handleSubmit = React.useCallback(
    (inventoryTransfer: valuesDefaultInventoryTransfer) => {
      if (!auth?.user?.id) {
        reportUserError({
          title: "Failed to create inventory transfer",
          message: "User id is undefined",
        });
        return;
      }

      if (!inventoryTransfer.filialFromId || !inventoryTransfer.filialToId) {
        reportUserError({
          title: "Failed to create inventory transfer",
          message: "You must select filials",
        });
        return;
      }

      if (!inventoryTransfer.reason) {
        reportUserError({
          title: "Failed to create inventory transfer",
          message: "You must select reason",
        });
        return;
      }

      if (inventoryTransfer.itemSkus.length === 0) {
        reportUserError({
          title: "Failed to create inventory transfer",
          message: "You must add at least one item",
        });
        return;
      }

      if (
        inventoryTransfer.status === "COMPLETE" &&
        inventoryTransfer.itemSkus.some((item) => item.quantity === 0)
      ) {
        reportUserError({
          title: "Failed to create inventory transfer",
          message: "You must add quantity for all items",
        });
        return;
      }

      mutate({
        filialFromId: Number(inventoryTransfer.filialFromId),
        filialToId: Number(inventoryTransfer.filialToId),
        status: inventoryTransfer.status,
        reason: inventoryTransfer.reason,
        note: inventoryTransfer.note,
        userId: auth.user.id,
        inventoryTransferItemSku: inventoryTransfer.itemSkus.map((item) => ({
          itemSkuId: item.id,
          quantity: item.quantity,
          previousQuantityFrom: item.stockFrom,
          previousQuantityTo: item.stockTo,
        })),
      });
    },
    [auth.user.id, mutate]
  );

  return (
    <InventoryTransferForm
      isLoading={isLoading}
      onSubmit={handleSubmit}
      defaultValues={undefined}
    />
  );
};
