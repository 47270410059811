import { makeController } from "#/components-ng/index.js"
import { z } from "zod"

export const upsertTimerSchema = z.object({
	title: z.string(),
	titleEs: z.string(),
	description: z.string().nullish(),
	descriptionEs: z.string().nullish(),
	subtitle: z.string().default("Sale ends in:"),
	subtitleEs: z.string().default("Sale termina en:"),
	active: z.boolean().default(false),
	dateRange: z.array(z.date()),
	withButton: z.boolean().default(false),
	buttonText: z.string().nullish(),
	buttonTextEs: z.string().nullish(),
	url: z.string().nullish(),
	color: z.string().nullish(),
})

export type FormValues = z.infer<typeof upsertTimerSchema>
export const C = makeController<FormValues>()
