import { DashboardModule } from "./Dashboard.js";
import { DashboardByEmployee } from "./DashboardByEmployee/index.js";
import { Dashboard } from "./GeneralDashboard/Dashboard.js";

export const dashboardRoutes = [
  {
    index: true,
    element: <DashboardModule />,
    breadcrumb: "Dashboard",
  },
  {
    path: "general-dashboard",
    element: <Dashboard />,
    breadcrumb: "General Dashboard",
  },
  {
    path: "dashboard-by-employee",
    element: <DashboardByEmployee />,
    breadcrumb: "Dashboard By Employee",
  }
];
