import type { RouterOutputs } from "#/trpc";
import { atom } from "jotai";

type Product = RouterOutputs["itemSku"]["search"][0]

interface ItemSku extends Product {
  quantity: number
  stockFrom: number
  stockTo: number
  diffStockFrom: number
  diffStockTo: number
}

// type ItemSku = {
//   id: number;
//   sku: number;
//   title: string;
//   quantity: number;
//   stockFrom: number;
//   stockTo: number;
//   diffStockFrom: number;
//   diffStockTo: number;
// };

export const stateAtom = atom({
  items: [] as ItemSku[],
});

export const fromFilialBrevisAtom = atom<string | null>(null);
export const toFilialBrevisAtom = atom<string | null>(null);

export const itemSkusAtom = atom(
  (get) => get(stateAtom).items,
  (get, set, items: ItemSku[]) => {
    set(stateAtom, { ...get(stateAtom), items });
  }
);

export const addItemSkuAtom = atom(null, (get, set, item: ItemSku) => {
  const items = get(itemSkusAtom);
  let found = false;
  const newItems = items.map((i) => {
    if (i.id === item.id) {
      found = true;
      return {
        ...i,
        quantity: i.quantity + 1,
        diffStockFrom: i.stockFrom - 1,
        diffStockTo: i.stockTo + 1,
      };
    } else {
      return i;
    }
  });
  if (!found) {
    newItems.push(item);
  }
  set(itemSkusAtom, newItems);
});

export const removeItemSkuAtom = atom(null, (get, set, index: number) => {
  const items = get(itemSkusAtom);
  set(
    itemSkusAtom,
    items.filter((_, i) => i !== index)
  );
});

type SetProductNewQtyPayload = {
  index: number;
  qty: number;
};

export const setItemSkuNewQtyAtom = atom(
  null,
  (get, set, payload: SetProductNewQtyPayload) => {
    const items = get(itemSkusAtom);
    set(
      itemSkusAtom,
      items.map((item, i) => {
        if (i === payload.index) {
          return {
            ...item,
            quantity: payload.qty,
            diffStockFrom: item.stockFrom - payload.qty,
            diffStockTo: item.stockTo + payload.qty,
          };
        } else {
          return item;
        }
      })
    );
  }
);

export const resetItemsAtom = atom(null, (get, set) => {
  set(itemSkusAtom, []);
  set(fromFilialBrevisAtom, null);
  set(toFilialBrevisAtom, null);
});

export const fromDiffTotalStockAtom = atom((get) => {
  const items = get(itemSkusAtom);
  return items.reduce((acc, item) => acc - item.quantity, 0);
});

export const toDiffTotalStockAtom = atom((get) => {
  const items = get(itemSkusAtom);
  return items.reduce((acc, item) => acc + item.quantity, 0);
});
