import { S, css } from '#/s'
import * as M from '@mantine/core'
import { useParams } from 'react-router-dom'
import { trpc } from '#/trpc'

type QuestionSummaryProps = {
	question: {
		id: number
		title: any
		answerType: 'TEXT' | 'MULTI_OPTION' | 'RATING'
		multiOptions: any
		formId: number
		required: boolean
	}
	answers: Record<
		string,
		{
			id: number
			questionId: number
			submissionId: number
			value: any
			count: number
		}
	>
}

function QuestionSummary({ question, answers }: QuestionSummaryProps) {
	const renderAnswerValue = (value: any, count: number) => {
		switch (question.answerType) {
			case 'RATING':
				return (
					<S.div display="flex" justifyContent="space-between" alignItems="center" w="100%">
						<M.Rating fractions={2} readOnly value={value} />
						<S.span color="gray.500">{count} answers</S.span>
					</S.div>
				)
			case 'MULTI_OPTION':
				return (
					<S.div display="flex" justifyContent="space-between" alignItems="center" w="100%" p="xs" rounded="sm">
						<S.span color="gray.900">{value}</S.span>
						<S.span color="gray.500">{count} answers</S.span>
					</S.div>
				)
			case 'TEXT':
			default:
				return (
					<S.div display="flex" justifyContent="space-between" alignItems="center" w="100%" p="xs" rounded="sm">
						<S.span color="gray.700">{value}</S.span>
						<S.span color="gray.500">{count} answers</S.span>
					</S.div>
				)
		}
	}

	return (
		<S.div
			p="4"
			bg="white"
			rounded="md"
			borderWidth="1px"
			borderColor="gray.200"
			borderStyle="solid"
			shadow="xs"
		>
			<S.div mb="4">
				<S.h3 color="gray.800" fw={500}>
					{question.title.en}
				</S.h3>
				<S.div color="gray.500" fontSize="sm">
					Total answers: {Object.entries(answers).length}
				</S.div>
			</S.div>

			<S.div
				display="flex"
				flexDirection="column"
				maxH="300px"
				overflowY="auto"
				pb="2"
			>
				{Object.values(answers).map((answer, index) => (
					<S.div
						key={index}
						borderLeftWidth="3px"
						borderLeftStyle="solid"
						borderLeftColor={index % 2 === 0 ? 'green.500' : 'blue.500'}
						pl="2"
						pr="4"
						bg={index % 2 === 0 ? 'green.50/50' : 'blue.50/50'}
					>
						{renderAnswerValue(answer.value, answer.count)}
					</S.div>
				))}
			</S.div>
		</S.div>
	)
}

export function FormSubmissionPage() {
	const { uuid } = useParams<{ uuid: string }>()
	const { data: submissions, isLoading } =
		trpc.v2_5.form.getSubmissions.useQuery({
			formUuid: uuid!,
		})

	if (isLoading) {
		return (
			<S.div display="flex" justifyContent="center" p="xl">
				<M.Loader />
			</S.div>
		)
	}

	if (!submissions || submissions.length === 0) {
		return (
			<S.div p="xl" textAlign="center">
				<S.h3>No submissions found</S.h3>
			</S.div>
		)
	}

	// Group answers by question
	const questionAnswers: Record<
		number,
		{
			question: (typeof submissions)[0]['form']['questions'][0]
			answers: Record<
				string,
				(typeof submissions)[0]['formAnswers'][0] & {
					count: number
				}
			>
		}
	> = {}

	for (const question of submissions[0].form.questions) {
		let existingQuestion = questionAnswers[question.id]
		if (existingQuestion == null) {
			existingQuestion = questionAnswers[question.id] = {
				question,
				answers: {},
			}
		}

		for (const submission of submissions) {
			const rawAnswers = submission.formAnswers
			
			// If the answer is an array, then we split that answer into
			// multiple answers.
			// Example:
			// If answer is `{ value: ["good", "bad"]}` then
			// we want to split this into `[{ value: "good" }, { value: "bad" }]`
			const normalizedAnswers = rawAnswers.flatMap((answer) => {
				if (Array.isArray(answer.value)) {
					return answer.value.map((value) => ({
						...answer,
						value,
					}))
				}
				return [answer]
			})

			for (const answer of normalizedAnswers) {
				const strValue = JSON.stringify(answer.value)
				if (answer.questionId === question.id) {
					if (existingQuestion.answers[strValue]) {
						existingQuestion.answers[strValue] = {
							...answer,
							count: existingQuestion.answers[strValue].count + 1,
						}
					} else {
						existingQuestion.answers[strValue] = {
							...answer,
							count: 1,
						}
					}
				}
			}
		}
	}

	console.log(questionAnswers)

	return (
		<S.div>
			<M.Tabs
				defaultValue="summary"
				classNames={{
					tabsList: css({
						bg: 'white',
						rounded: 'md',
					}),
					tab: css({
						py: '1rem',
						fw: 'medium',
						'&:first-child': {
							roundedBottomLeft: 'md',
						},
						'&:last-child': {
							roundedBottomRight: 'md',
						},
					}),
				}}
			>
				<M.Tabs.List grow>
					<M.Tabs.Tab value="summary">Summary</M.Tabs.Tab>
				</M.Tabs.List>
				<M.Tabs.Panel value="summary">
					<S.div mt="4">
						<S.div display="flex" flexDirection="column" gap="4">
							{Object.values(questionAnswers).map(
								({ question, answers }) => (
									<QuestionSummary
										key={question.id}
										question={question}
										answers={answers}
									/>
								),
							)}
						</S.div>
					</S.div>
				</M.Tabs.Panel>
			</M.Tabs>
		</S.div>
	)
}
