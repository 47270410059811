import { makeController } from "#/components-ng/index.js"
import { z } from "zod"

export const generateDashboardByEmployeeSchema = z.object({
  dateRange: z.array(z.date()),
	employeeId: z.string().nullish(),
})

export type FormValues = z.infer<typeof generateDashboardByEmployeeSchema>
export const C = makeController<FormValues>()
