import { css, S } from "#/s"
import { zodResolver } from "@hookform/resolvers/zod"
import { C, type FormValues, upsertTimerSchema } from "./types"
import { trpc } from "#/trpc"
import {
	Controller,
	type DefaultValues,
	FormProvider,
	type SubmitHandler,
} from "react-hook-form"
import { reportUserError, reportUserSuccess } from "#/util"
import { LoadingOverlay } from "@mantine/core"
import GTLoader from "#/components-ng/loader"
import dayjs from "dayjs"
import { DatePickerInput } from "@mantine/dates"
import CalendarIcon from "~icons/ion/calendar-clear-outline"
import { ColorInput, Checkbox } from "@mantine/core"
import { Container } from "#/css/jsx"
import { Button } from "@gt/ui"

export const TimerModule = () => {
	const ctx = trpc.useContext()
	const { data, isLoading } = trpc.v2_5.timer.getExistTimer.useQuery(
		undefined,
		{
			refetchOnWindowFocus: false,
		},
	)

	const { mutate, isLoading: isLoadingUpsert } =
		trpc.v2_5.timer.upsertTimer.useMutation({
			onError(err) {
				reportUserError({
					title: "Error",
					message: err.message,
				})
			},
			onSuccess() {
				reportUserSuccess({
					title: "Success",
					message: "Timer saved successfully",
				})
				ctx.v2_5.timer.invalidate()
			},
		})

	const handleSubmit = (values: FormValues) => {
		if (!values.dateRange[0] || !values.dateRange[1]) {
			reportUserError({
				title: "Error",
				message: "Please select a date range",
			})
			return
		}

		mutate({
			id: data?.id ?? null,
			title: values.title,
			titleEs: values.titleEs,
			description: values.description,
			descriptionEs: values.descriptionEs,
			subtitle: values.subtitle,
			subtitleEs: values.subtitleEs,
			active: values.active,
			startDate: values.dateRange[0]!,
			endDate: values.dateRange[1]!,
			withButton: values.withButton,
			buttonText: values.withButton ? values?.buttonText : null,
			buttonTextEs: values.withButton ? values?.buttonTextEs : null,
			url: values.withButton ? values?.url : null,
			color: values?.color ?? null,
		})
	}

	return isLoading ? (
		<LoadingOverlay
			visible={isLoading}
			loader={<GTLoader width={100} height={100} />}
		/>
	) : (
		<TimerForm
			defaultValues={{
				title: data?.title ?? "",
				titleEs: data?.titleEs ?? "",
				description: data?.description ?? "",
				descriptionEs: data?.descriptionEs ?? "",
				subtitle: data?.subtitle ?? "Sale ends in:",
				subtitleEs: data?.subtitleEs ?? "Sale termina en:",
				active: data?.active ?? false,
				dateRange: [
					data?.startDate ?? new Date(),
					data?.endDate ?? dayjs().add(7, "days").toDate(),
				],
				withButton: data?.withButton ?? false,
				buttonText: data?.buttonText ?? null,
				buttonTextEs: data?.buttonTextEs ?? null,
				url: data?.url ?? null,
				color: data?.color ?? null,
			}}
			isLoading={isLoadingUpsert}
			onSubmit={handleSubmit}
		/>
	)
}

interface TimerFormProps {
	defaultValues?: DefaultValues<FormValues>
	onSubmit: SubmitHandler<FormValues>
	isLoading: boolean
}

const TimerForm = (props: TimerFormProps) => {
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		new Date(),
		dayjs().add(7, "days").toDate(),
	])

	const form = C.useForm({
		resolver: zodResolver(upsertTimerSchema),
		shouldUnregister: false,
		defaultValues: {
			title: "",
			titleEs: "",
			description: "",
			descriptionEs: "",
			subtitle: "Sale ends in:",
			subtitleEs: "Sale termina en:",
			active: false,
			withButton: false,
			buttonText: null,
			buttonTextEs: null,
			url: null,
			color: null,
			dateRange: [dateRange[0]!, dateRange[1]!],
			...props.defaultValues,
		},
	})

	return (
		<Container
			bg="#fff"
			p="1rem"
			rounded="md"
			borderColor="#E0E0E0"
			borderWidth="1px"
			shadow="md"
			w="full"
			maxW="700px"
		>
			<S.div gap="1rem" d="flex" flexDir="column" w="full">
				<S.h2>Timer</S.h2>
				<S.hr />
				<FormProvider {...form}>
					<form
						onSubmit={(e) => {
							e.stopPropagation()
							form.handleSubmit(props.onSubmit)(e)
						}}
					>
						<S.div gap="1rem" d="flex" flexDir="column" w="full">
							<S.div
								d="grid"
								gap="1rem"
								gridTemplateColumns={{ base: 1, md: 2 }}
							>
								<C.InputField
									name="title"
									label="Title"
									required
									placeholder="Title"
								/>
								<C.InputField
									name="titleEs"
									label="Title (Spanish)"
									required
									placeholder="Titulo"
								/>
							</S.div>

							<S.div
								d="grid"
								gap="1rem"
								gridTemplateColumns={{ base: 1, md: 2 }}
							>
								<C.TextareaField
									name="description"
									label="Description"
									required
									placeholder="Title"
								/>
								<C.TextareaField
									name="descriptionEs"
									label="Description (Spanish)"
									required
									placeholder="Descripcion"
								/>
							</S.div>

							<S.div
								d="grid"
								gap="1rem"
								gridTemplateColumns={{ base: 1, md: 2 }}
							>
								<C.InputField
									name="subtitle"
									label="Subtitle"
									required
									placeholder="Subtitle"
								/>
								<C.InputField
									name="subtitleEs"
									label="Subtitle (Spanish)"
									required
									placeholder="Subtitulo"
								/>
							</S.div>

							<S.div
								gap="1rem"
								d="flex"
								flexDir={{ base: "column", md: "row" }}
							>
								<C.M
									name="dateRange"
									as={DatePickerInput}
									label="Date Range"
									type="range"
									onChange={(v) => {
										if (!v) return
										if (v[0] == null || v[1] == null) return
										setDateRange([v[0], v[1]])
									}}
									allowSingleDateInRange
									numberOfColumns={2}
									popoverProps={{
										withinPortal: true,
										zIndex: 9999,
									}}
									icon={<CalendarIcon color="#424242" />}
									className="w-full"
								/>
							</S.div>

							<S.div d="flex" gap="1rem" alignItems="center">
								<Controller
									control={form.control}
									name="color"
									render={(c) => {
										return (
											<>
												<ColorInput
													label="Accent color"
													onChange={(e) => c.field.onChange(e)}
													value={c.field.value ?? DEFAULT_ACCENT_COLOR}
													error={c.fieldState.error?.message}
													disabled={c.field.value == null}
												/>

												<Checkbox
													label="Custom accent color"
													checked={c.field.value != null}
													onChange={(e) => {
														if (!e.currentTarget.checked) {
															c.field.onChange(null)
														} else {
															c.field.onChange(DEFAULT_ACCENT_COLOR)
														}
													}}
												/>
											</>
										)
									}}
								/>
							</S.div>
							<S.div
								d="flex"
								gap="1rem"
								flexDir={{ base: "column", md: "row" }}
							>
								<C.SwitchField name="active" label="Active" />
								<C.SwitchField name="withButton" label="With Button" />
							</S.div>

							<S.div
								d="grid"
								gap="1rem"
								gridTemplateColumns={{ base: 1, md: 2 }}
							>
								<C.InputField
									name="buttonText"
									label="Button Text"
									placeholder="Button Text"
									required={form.watch("withButton")}
									disabled={!form.watch("withButton")}
								/>
								<C.InputField
									name="buttonTextEs"
									label="Button Text (Spanish)"
									placeholder="Texto del botón"
									required={form.watch("withButton")}
									disabled={!form.watch("withButton")}
								/>
								<C.InputField
									name="url"
									label="Redirect button to"
									placeholder="www.goldtreemiami.com"
									required={form.watch("withButton")}
									disabled={!form.watch("withButton")}
								/>
							</S.div>
							<Button
								type="submit"
								isLoading={props.isLoading}
								className={css({
									marginTop: "1.25rem",
									paddingX: "3rem",
								})}
							>
								Save
							</Button>
						</S.div>
					</form>
				</FormProvider>
			</S.div>
		</Container>
	)
}

const DEFAULT_ACCENT_COLOR = "#DE3B35"
