import { type ImagePreviewProps, ImagePreview } from './image-preview'
import {
  imageSrcAtom,
  itemSkuIdAtom,
  markersAtom,
  transformsAtom,
} from './state'
import { styled as s } from '#/css/jsx'
import { Provider, useAtom, useAtomValue, useSetAtom } from 'jotai/react'
import { Marker } from './marker'
import React from 'react'
import { nanoid } from 'nanoid'
import { ImageField } from './image-field'
import { SaveButton } from './save-button'
import { trpc } from '#/trpc'
import { useHydrateAtoms } from 'jotai/react/utils'

export interface ImageMarkerEditorProps extends ImagePreviewProps {
  itemSkuId: number
  readOnly?: boolean | null
  limitSlot?: number | null
  slotImages?: string[]
}

export function ImageMarkerEditor(props: ImageMarkerEditorProps) {
  return (
    <Provider>
      <ImageMarkerEditorInner {...props} />
    </Provider>
  )
}

function ImageMarkerEditorInner(props: ImageMarkerEditorProps) {
  useHydrateAtoms([[itemSkuIdAtom, props.itemSkuId]])

  const { data } = trpc.v2_5.itemSkuSlot.getMany.useQuery({
    itemSkuId: props.itemSkuId,
  })
  const [markers, setMarkers] = useAtom(markersAtom)
  const setImageSrc = useSetAtom(imageSrcAtom)

  useEffect(() => {
    if (data != null) {
      setImageSrc(data.image)
      if (props?.readOnly === true) {
        setMarkers(
          data.slots
            ?.slice(0, props.limitSlot ?? data.slots.length)
            .map((slot, i) =>
              atom({
                id: nanoid(5),
                x: slot.x,
                y: slot.y,
                radius: slot.radius,
                image: props.slotImages?.[i] ?? null,
              }),
            ),
        )
      } else {
        setMarkers(
          data.slots.map((slot) =>
            atom({
              id: nanoid(5),
              x: slot.x,
              y: slot.y,
              radius: slot.radius,
            }),
          ),
        )
      }
    }
  }, [data])

  return (
    <s.div display="grid" gapY="4">
      {!props?.readOnly && <ImageField />}
      <s.div pos="relative" bg="red.500/10" w="100%" h="100%">
        <ImagePreview {...props} />
        {!props?.readOnly && <MarkerHitTarget />}
        {markers.map((markerAtom, i) => (
          <Marker
            key={i}
            atom={markerAtom}
            index={i}
            readOnly={props?.readOnly}
            slotImage={props.slotImages?.[i] ?? ''}
          />
        ))}
      </s.div>
      {!props?.readOnly && <SaveButton />}
    </s.div>
  )
}

function MarkerHitTarget() {
  const setMarkers = useSetAtom(markersAtom)
  const transforms = useAtomValue(transformsAtom)

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const pos = transforms.vpToRatio({
      x: e.clientX,
      y: e.clientY,
    })

    setMarkers((prev) => [
      ...prev,
      atom({
        id: nanoid(5),
        x: pos.x,
        y: pos.y,
        radius: 0.1,
      }),
    ])
  }

  return (
    <s.div
      w="100%"
      h="100%"
      bg={process.env.NODE_ENV === 'development' ? 'red.500/20' : undefined}
      pos="absolute"
      left={0}
      top={0}
      onClick={handleClick}
    />
  )
}
